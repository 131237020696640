import { r, redraw } from '../../common/vdom/index.js';
import { once } from '../../common/utils/func.js';
import { globals } from '../../api/index.js';
import { Button, Alert } from '../../components/index.js';

const defaultGL = [
    {id: 1, key: "min_battery_charge", value: 15, description: null},
    {id: 2, key: "quiet_time", value: 5, description: null},
    {id: 3, key: "resting_time", value: 10, description: null},
    {id: 4, key: "instant_activity", value: 1, description: null},
    {id: 5, key: "min_activity", value: 1, description: null},
    {id: 6, key: "ble_no_time_max", value: 5, description: null},
    {id: 7, key: "min_temperature_dr", value: 25, description: null},
    {id: 8, key: "min_heart_rate_dr", value: 55, description: null},
    {id: 9, key: "min_instant_activity_dr", value: 1, description: null},
    {id: 10, key: "min_skin_temperature_dr", value: 25, description: null},
]

const cell = (v, t='td') => r(t, r('.pt-2.pb-2.pl-4.leading-4.items-center.flex.flex-row.font-normal', r('.h-8'), r('.flex-1', v)));

function diff(a, b) {
    const d = {};
    for (const { key, description, value } of a) {
        d[key] = { key, description, cur: value, ch: '' };
    }
    for (const { key, description, value } of b) {
        if (d[key]) d[key].ch = value;
        else d[key] = { key, description, cur: '', ch: value }
    }
    return Object.values(d);
}

const propname = p => p.description || p.key;

export default function Globals() {
    async function send(data) {
        await Promise.all(diff(cur, data).filter(g => g.cur != g.ch).map(g => globals.update(g.key, { value: Number(g.ch) })))
        await getGlobals();
    }
    const setDefautAlert = Alert(
        (res, t) => r('.flex.flex-col', { style: { width: '700px' } },
            r('', 'Вы уверены, что хотите сбросить настройки, до настроек по-умолчанию? Указанные настройки будут изменены'),
            r('table.border-separate', { style: { 'border-spacing': '0px 4px' } },
                r('thead',
                    cell('Наименование', 'th.text-left'),
                    cell('Значение', 'th'),
                    cell('Значение по-умолчанию', 'th'),
                ),
                r('tbody',
                    diff(cur, defaultGL).filter(g => g.cur != g.ch)
                    .sort((a, b) => (propname(a) || '').localeCompare(propname(b) || ''))
                    .map(g => r('tr.rounded.pr-4.bg-white',
                        cell(r('', { title: propname(g) }, propname(g))),
                        cell(g.cur),
                        cell(g.ch)
                    ))
                )
            ),
            r('.flex.flex-row', r('.flex-1'), Button('Отправить', async () => {
                await send(defaultGL);
                setDefautAlert.hide();
            }))
        )
    );

    const setAlert = Alert(
        (res, t) => r('.flex.flex-col', { style: { 'min-width': '700px' } },
            r('', 'Проверьте правильность изменения настроек'),
            r('table.border-separate', { style: { 'border-spacing': '0px 4px' } },
                r('thead',
                    cell('Наименование', 'th.text-left'),
                    cell('Значение', 'th'),
                    cell('Измененное значение', 'th'),
                ),
                r('tbody',
                    diff(cur, gl).filter(g => g.cur != g.ch)
                    .sort((a, b) => (propname(a) || '').localeCompare(propname(b) || ''))
                    .map(g => r('tr.rounded.pr-4.bg-white',
                        cell(r('', { title: propname(g) }, propname(g))),
                        cell(g.cur),
                        cell(g.ch)
                    ))
                )
            ),
            r('.flex.flex-row', r('.flex-1'), Button('Отправить', async () => {
                await send(gl);
                setAlert.hide();
            }))
        )
    );

    let gl = [], cur = [];
    async function getGlobals() {
        gl = await globals.get();
        cur = gl.map(i=>Object.assign({}, i));
        redraw();
    }
    const getInitialGlobals = once(getGlobals);
    function render() {
        getInitialGlobals();
        return r('.p-16.pb-48.bg-blue-50.w-full.select-none',
            setDefautAlert.render(),
            setAlert.render(),
            r('.text-xl.font-bold', { style: { 'margin-top': '-32px' } }, 'Настройки'),
            r('table.border-separate', { style: { 'border-spacing': '0px 4px' } },
                r('thead',
                    cell('Наименование', 'th.text-left'),
                    cell('Значение', 'th'),
                ),
                r('tbody',
                    gl.sort((a, b) => (propname(a) || '').localeCompare(propname(b) || ''))
                    .map(g => r('tr.rounded.pr-4.bg-white',
                        cell(r('', { title: propname(g) }, propname(g))),
                        cell(r('input.outline-none', { type: 'number', onInput(e) { g.value = Number(e.target.value); redraw(); }, value: g.value }))
                    ))
                )
            ),
            r('.mt-8'),
            r('.flex.flex-row',
                Button('Сохранить', _ => { setAlert.show() },
                    { disabled: !diff(gl, cur).filter(g => g.cur != g.ch).length }),
                r('.ml-2'),
                Button('Вернуть значения по умолчанию', _ => { setDefautAlert.show() },
                    { disabled: !diff(cur, defaultGL).filter(g => g.cur != g.ch).length })
            )
        );
    }
    return { render };
}
