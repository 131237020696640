import 'https://unpkg.com/mithril/mithril.js';

import { Panels } from './components.js';
import { throttle } from '../../common/utils/func.js';

const views = [];

export function addView(container, render) {
    views.push({ container, render });
    m.redraw();
}

const m_ = window.m;
window.m = (...args) => {
    const elem = m_(...args);
    if (elem.attrs) {
        elem.attrs.class = (elem.attrs.class || elem.attrs.className || '').replace(/\./g, ' ');
        delete elem.attrs.className;
    }
    return elem;
}

window.m.render = m_.render;

window.m.redraw = () => {
    if (!app) return;
    for (const { container, render } of views)
        m_.render(container, render());
    Panels.panelsContainer.redraw();
    m_.redraw();
}

function updateLoop() {
    Panels.panelsContainer.update();
    requestAnimationFrame(updateLoop);
}
updateLoop();

window.addEventListener('resize', throttle(500, m.redraw));
