export function formatBleID(v, { inverse=false }={}) {
    if (!v) return '';
    if (inverse) {
        const [a, b] = v.split(/\s+/);
        if (isNaN(+a) || isNaN(+b) || +a > 65535 || +b > 65535) throw new Error('');
        return [(+a).toString(16), (+b).toString(16)].map(v => String(v).padStart(4, '0')).join('');
    } else {
        v = v.slice(-8);
        const a = v.slice(0, 4), b = v.slice(-4);
        return [parseInt(a, 16), parseInt(b, 16)].map(v => String(v).padStart(4, '0')).join(' ');
    }
}

export function formatDate(v, time=true, ms=false) {
    v = new Date(v);
    return (
        ('' + v.getDate()).padStart(2, '0') + '-' + 
        ('' + (v.getMonth() + 1)).padStart(2, '0') + '-' +
        ('' + v.getFullYear()).padStart(4, '0') +
        (!time ? '' : ' ' + v.toLocaleTimeString('RU', { hourCycle: 'h23' })) + 
        (!ms ? '' : '.' + String(+v % 1000).padStart(3, '0'))
    );
};
// разделитель точки
export function formatDateV2(v, time=true, ms=false) {
    v = new Date(v);
    return (
        ('' + v.getDate()).padStart(2, '0') + '.' +
        ('' + (v.getMonth() + 1)).padStart(2, '0') + '.' +
        ('' + v.getFullYear()).padStart(4, '0') +
        (!time ? '' : ' ' + v.toLocaleTimeString('RU', { hourCycle: 'h23' })) +
        (!ms ? '' : '.' + String(+v % 1000).padStart(3, '0'))
    );
};

export function formatDateYYYYMMDD(v) {
    v = new Date(v);
    return (
        ('' + v.getFullYear()).padStart(4, '0') + '-' +
        ('' + (v.getMonth() + 1)).padStart(2, '0') + '-' +
        ('' + v.getDate()).padStart(2, '0')
    );
};

export const value = (v) => {
    const listeners = [];
    const wrapper = Object.assign(function(vv) {
        if (arguments.length != 0) {
            v = vv;
            for (const h of listeners) h(v);
        }
        return v;
    }, {
        listen(h) { listeners.push(h); return wrapper; }
    });
    return wrapper;
}
export const valueRef = (obj, k) => {
    const listeners = [];
    const wrapper = Object.assign(function(vv) {
        if (arguments.length != 0) {
            obj[k] = vv;
            for (const h of listeners) h(obj[k]);
        }
        return obj[k];
    }, {
        listen(h) { listeners.push(h); return wrapper; }
    });
    return wrapper;
}

export const replacingDotWithComma=(value)=>{
    return  value.toString().replace(".",",")
}