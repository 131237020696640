import {r, redraw} from '../../common/vdom/index.js';
import {once, throttle} from '../../common/utils/func.js';
import {employee, device, telemetry, employeesStatus, appointment, place, brigade} from '../../api/index.js';
import {Icon, Button, Alert, EmployeesTable} from '../../components/index.js';
import login, {getToken} from '../../api/login.js';

function EmployeesData() {
    let employees = [], devices = [];
    let employes_status = []
    let proffesion = []
    let zone = []
    const redraw1 = throttle(50, redraw);

    async function load() {

        if (!getToken()) return;
        employees = await employee.get();
        devices = await device.get();
        employes_status = await employeesStatus.get()
        proffesion = await appointment.get()
        zone = await place.get()
        await loadTelemetry();

        redraw1();
    }

    setInterval(load, 60000);

    async function loadTelemetry() {
        if (!getToken()) return;
        for (const d of await telemetry.get()) {
            const e = employees.find(e => e.id == d.employee_id);
            if (e) e.data = d;
        }
        redraw1();
    }

    setInterval(loadTelemetry, 30000);

    let loadedOnce = false;
    const init = function () {
        // инициализация происходит только если есть токен
        // до этого оно вызывалось вместе с страницей auth
        if (!getToken() || loadedOnce) return;
        loadedOnce = true;
        load();
    };

    const listening = {};

    function data() {
        const formatAlarm = (l = []) => {
            /*
            Перечень условий показа алармов:

            1) Аларм "данные с носимого устройства не поступают" - На главной в случае срабатывания выводится он один, без других алармов.
            2) Аларм  "Устройство снято с сотрудника" - на главной выводится в случае срабатывания один , без других алармов. (исключение аларм "данные с носимого устройства не поступают"
            3) Аларм "Данные о BLE метках с носимого устройства не поступают" -  На главной выводится в случае отсутствия 1-ого и 2-ого аларма.
            4) Аларм "Передано другому сотруднику" - на Главную выводится в случае отсутствия 1-ого и 2-ого аларма.
            5) Аларм "Длительное время покоя" (переименовать из "Отсутствие активности у сотрудника") - на главной выводится в случае отсутствия 1-ого и 2-ого алармов
            6) Аларм "Низкий уровень зараяда батареи" - на главную выводится в случает отсутствия 1-ого аларма.
            */

            const ignore = ['employee_doesnt_move_alarm', 'no_activity_alarm'];
            l = l.filter(d => ignore.indexOf(d.telemetry_name) == -1);

            const v1 = l.find(d => d.telemetry_name == 'data_is_not_coming_alarm');
            const v2 = l.find(d => d.telemetry_name == 'device_has_been_removed_alarm');
            const v3 = l.find(d => d.telemetry_name == -1/*'Данные о BLE метках с носимого устройства не поступают'*/); // TODO
            const v4 = l.find(d => d.telemetry_name == -1/*'Передано другому сотруднику'*/); // TODO
            const v5 = l.find(d => d.telemetry_name == -1/*'Длительное время покоя'*/); // TODO
            const v6 = l.find(d => d.telemetry_name == -1/*'Низкий уровень зараяда батареи'*/); // TODO

            if (v1) return [v1];

            if (v2) return (v1 ? [v1, v2] : [v2, v6]).filter(v => v);

            if (v3 || v4 || v5 || v6) return [v3, v4, v5, v6].filter(v => v);

            return l;
        }
        return employees
            .filter(e => !e.disabled)
            .map(e => ({...e, device: devices.find(d => d.employee_id === e.id)}))
            .map(e => ({...e, status: employes_status.find(d => d.employee_id === e.id)}))
            .map(e => ({...e, zone: zone.find(z => z.id === e.status?.zone_id)}))
            .map(e => ({...e, proffesion: proffesion.find(p => p.id === e.status?.profession_id)}))
            .map(e => ({...e, brigadier: employees.find(b => b.id === e.status?.brigadier_id)}))
            .map(e => {
                const alarm = formatAlarm(e.data?.data?.alarm || []).map(v => v.real_name);
                return {

                    id: e.id,
                    profession: e.proffesion?.name,
                    brigadier:e.brigadier && `${e.brigadier?.surname} ${e.brigadier?.name} ${e.brigadier?.patronymic}`,
                    location: e.zone?.name,
                    lastUpdate: e.data?.last_seen ? e.data?.last_seen + 'Z' : '',
                    name: `${e.surname} ${e.name} ${e.patronymic}`,
                    in_place: e.in_place,
                    tnumber: e.personnel_id,
                    deviceId: e.device?.name?.slice(-8) || '',
                    batteryCharge: e.status?.battery_capacity,
                    heartRate: e.status?.heart_rate,
                    skinTemperature: e.status?.skin_temperature,
                    alarm: (
                        !e.in_place ? ['Отсутствует на рабочем месте по данным СКУД'] :
                            !e.data?.last_update ? ["Данные с устройства не поступают"] :
                                alarm.length === 0 ? ['Нет нарушений'] :
                                    alarm
                    )
                };
            });
    }

    function flush() {
        employees = [];
        devices = [];
        loadedOnce = false;
    }

    return {init, load, data, flush};
}

export const employeesData = EmployeesData();

export default function ({openEmployee, addEmployee, editEmployee}) {
    const alert = Alert(
        (confirm, employee = {}) => r('.flex-col',
            r('', 'Удалить сотрудника?'),
            r('.text-sm.font-bold.mt-2', `${employee.surname} ${employee.name} ${employee.patronymic}`),
            r('.flex.flex-row.mt-8',
                Button('Подтвердить', () => confirm(true), {color: 'blue'}),
                r('.mr-4'),
                Button('Отменить', () => confirm(false), {color: 'red'})
            )
        )
    );
    const staff = EmployeesTable({
        openEmployee,
        editEmployee,
        async deleteEmployee(id) {
            const employeeToDelete = await employee.get(id);
            if (!(await alert.show(employeeToDelete))) return;

            await employee.delete(id);
            await employeesData.load();
            redraw();
        },
        mode: 0,
        data: employeesData.data
    });

    function params({reload}) {
        if (reload) employeesData.load();
    }

    function render() {
        employeesData.init();
        return r('.p-16.bg-blue-50.w-full.h-full.select-none',
            r('.flex.flex-row.mb-4.mt-4', {style: {marginTop: '-32px'}},
                r('.text-xl.font-bold', 'Персонал'),
                r('.ml-auto'),
                r('.flex.flex-row.items-center.cursor-pointer', {onClick: addEmployee},
                    Icon('plus', 'green-600', 6),
                    r('.text.text-green-600.uppercase.font-medium.ml-1', 'Добавить сотрудника')
                )
            ),
            r('.mt-16'),
            alert.render(),
            staff.render()
        );
    }

    return {params, render};
}
