import {r, redraw} from '../../common/vdom/index.js';
import {Alert, Button, Icon, Input, Table} from '../index.js';
import {getTelemetryReport, getTelemetryReportXLSX} from '../../api/reports.js';
import {formatDateV2, formatDateYYYYMMDD, replacingDotWithComma} from '../../utils.js';
import {employeesData} from '../../pages/employees/index.js';

export default function ReportTable() {
    let data = [];
    const filters = { count: 10, page: 0 }; //, begin_date: '2021-12-10T08:17:23.001Z', end_date: '2021-12-10T08:17:23.001Z' };

    const alert = Alert(
        (res, t) => r('.flex.flex-col', { style: { width: '300px' } },
            t === 'filters'
                ? [
                    r('', 'Необходимо Выбрать:'),
                    !filters.begin_date && r('', ' - Дата формирования "с"'),
                    !filters.end_date && r('', ' - Дата формирования "по"'),
                ]
                : r('', String(t))
        )
    );

    const on_off_filter = (r, f) => f === '' || (+r || 0) == f;

    const columnFilters = {};
    const table = Table({
        columnFilters,
        pagination: _ => filters.count,
        rows: () => data,
        columns: [
            'datetime', 'person_id', 'device_id', 'area', 'floor',
            'is_alarm_watch_taken_off', 'is_alarm_watch_handed', 'is_alarm_low_battery',
            'is_alarm_device_data_not_received', 'is_alarm_BLE_data_not_received', 'is_alarm_long_rest_state',
            'heart_rate', 'instant_activity', 'step', 'battery_capacity',
            'fullname', 'ble_id', 'temperature',
            'rssi', 'snr', 'dr'
        ],
        columnWidth: (column) => ({
            datetime: 26,
            person_id: 26,
            device_id: 26,
            area: 32,
            floor: 32,
            is_alarm_watch_taken_off: 32,
            is_alarm_watch_handed: 32,
            is_alarm_low_battery: 32,
            is_alarm_device_data_not_received: 32,
            is_alarm_BLE_data_not_received: 32,
            is_alarm_long_rest_state: 32,
            heart_rate: 32,
            instant_activity: 32,
            step: 32,
            battery_capacity: 32,
            fullname: 48,
            ble_id: 32,
            temperature: 32,
            rssi: 32,
            snr: 32,
            dr: 32,
        }[column]),
        columnAlign: (column) => 'center',
        columnSeparator: (column) => [
             'person_id', 'device_id', 'area', 'floor',
            'is_alarm_watch_taken_off', 'is_alarm_watch_handed', 'is_alarm_low_battery',
            'is_alarm_device_data_not_received', 'is_alarm_BLE_data_not_received', 'is_alarm_long_rest_state',
            'heart_rate', 'instant_activity', 'step', 'battery_capacity',
            'fullname', 'ble_id', 'temperature',
            'rssi', 'snr', 'dr'
        ].includes(column),
        columnName: (column) => ({
            datetime: 'Дата и время события',
            person_id: 'Табельный номер сотрудника',
            device_id: 'ID носимого устройства',
            area: 'Объект',
            floor: 'Этаж',
            is_alarm_watch_taken_off: 'Состояние носимого устройство "снято с сотрудника"',
            is_alarm_watch_handed: 'Состояние носимого устройства "Передано другому сотруднику"',
            is_alarm_low_battery: 'Состояние носимого устройства "низкий заряд батареи"',
            is_alarm_device_data_not_received: 'Состояние носимого устройства "Данные с носимого устройства не поступают"',
            is_alarm_BLE_data_not_received: 'Состояние носимого устройства "Данные с BLE меток не поступают"',
            is_alarm_long_rest_state: 'ALARM длительное состояние покоя',
            heart_rate: 'ЧСС',
            instant_activity: 'Текущая активность (количество движений)',
            step: 'Шаги',
            battery_capacity: 'Заряд батареи, %',
            fullname: 'ФИО сотрудника',
            ble_id: 'ID BLE метки',
            temperature: 'Температура, °С',
            rssi: 'RSSI',
            snr: 'Отношение сигнал/шум',
            dr: 'Скорость передачи данных'
        })[column],
        columnFilterInput: (column) => ({
            datetime: Table.columnFilterInputs.datetime(),
            person_id: Table.columnFilterInputs.text,
            device_id: Table.columnFilterInputs.text,
            area: Table.columnFilterInputs.text,
            floor: Table.columnFilterInputs.text,
            is_alarm_watch_taken_off: Table.columnFilterInputs.number,
            is_alarm_watch_handed: Table.columnFilterInputs.number,
            is_alarm_low_battery: Table.columnFilterInputs.number,
            is_alarm_device_data_not_received: Table.columnFilterInputs.number,
            is_alarm_BLE_data_not_received: Table.columnFilterInputs.number,
            is_alarm_long_rest_state: Table.columnFilterInputs.number,
            heart_rate: Table.columnFilterInputs.number,
            instant_activity: Table.columnFilterInputs.number,
            step: Table.columnFilterInputs.number,
            battery_capacity: Table.columnFilterInputs.number,
            fullname: Table.columnFilterInputs.text,
            ble_id: Table.columnFilterInputs.text,
            temperature: Table.columnFilterInputs.number,
            rssi: Table.columnFilterInputs.number,
            snr: Table.columnFilterInputs.number,
            dr: Table.columnFilterInputs.number,
        })[column],
        columnFilterFunction: (column) => ({
            datetime: Table.columnFilterFunctions.date,
            person_id: Table.columnFilterFunctions.text,
            device_id: Table.columnFilterFunctions.text,
            area: Table.columnFilterFunctions.text,
            floor: Table.columnFilterFunctions.text,
            is_alarm_watch_taken_off: on_off_filter,
            is_alarm_watch_handed: on_off_filter,
            is_alarm_low_battery: on_off_filter,
            is_alarm_device_data_not_received: on_off_filter,
            is_alarm_BLE_data_not_received: on_off_filter,
            is_alarm_long_rest_state: on_off_filter,

            heart_rate: Table.columnFilterFunctions.number,
            instant_activity: Table.columnFilterFunctions.number,
            step: Table.columnFilterFunctions.number,
            battery_capacity: Table.columnFilterFunctions.number,

            fullname: Table.columnFilterFunctions.text,
            ble_id: Table.columnFilterFunctions.text,
            temperature: Table.columnFilterFunctions.number,

            rssi: Table.columnFilterFunctions.number,
            snr: Table.columnFilterFunctions.number,
            dr: Table.columnFilterFunctions.number,
        })[column],
        cellText: (row, column) => ({
            datetime: formatDateV2(row.datetime),
            person_id: row.person_id,
            device_id: row.device_id && row.device_id.slice(-8),
            area: row.place?.length >= 2 && row.place[0].name,
            floor: row.place?.length >= 2 && row.place[row.place.length - 2].name,
            is_alarm_watch_taken_off: +row.is_alarm_watch_taken_off,
            is_alarm_watch_handed: +row.is_alarm_watch_handed,
            is_alarm_low_battery: +row.is_alarm_low_battery,
            is_alarm_device_data_not_received: +row.is_alarm_device_data_not_received,
            is_alarm_BLE_data_not_received: +row.is_alarm_BLE_data_not_received,
            is_alarm_long_rest_state: +row.is_alarm_long_rest_state,
            heart_rate: row.heart_rate,
            instant_activity: row.instant_activity,
            step: row.step,
            battery_capacity: replacingDotWithComma(row.battery_capacity),
            fullname: row.fullname,
            ble_id: row.ble_id,
            temperature: row.temperature,
            rssi: row.rssi,
            snr: replacingDotWithComma(row.snr),
            dr: row.dr
        })[column],
        cellSortValue: (row, column) => [
            'is_alarm_watch_taken_off', 'is_alarm_watch_handed',
            'is_alarm_low_battery', 'is_alarm_device_data_not_received',
            'is_alarm_BLE_data_not_received', 'is_alarm_long_rest_state'
        ].includes(column) ? +row[column] : row[column]
    })

    function Filter() {
        //console.log(filters.begin_date, filters.end_date);
        if (filters.begin_date){
            filters.begin_date = formatDateYYYYMMDD(new Date(Math.min(...[filters.begin_date].map(d => +new Date(d)))));
        }
        //console.log(filters.begin_date, filters.end_date);
        return r('.flex.flex-row.items-center.mt-4',
            r('.flex.flex-row.items-center.bg-gray-100.border-2.border-gray-300.rounded-full.relative',
                r('.mr-4.pl-4.leading-8', 'за'),
                r('input', {
                    type: 'date', value: filters.begin_date || '', onChange(e) {
                        filters.begin_date = e.target.value;
                        redraw();
                    }
                }),
                r('.flex.items-center.justify-center.pl-2.pr-2.ml-1.mr-1.leading-8.rounded-full.cursor-pointer.hover:bg-gray-300',
                    {
                        style: {width: '24px', height: '24px'}, onClick() {
                            filters.begin_date = undefined;
                            redraw();
                        }
                    },
                    Icon('close', 'black', 2),
                )
            ),
            r('.ml-8'),
            r('.flex.flex-row.items-center.bg-gray-100.border-2.border-gray-300.rounded-full.relative',
                r('.mr-4.pl-4', 'Сотрудник'),
                Input(undefined, {
                    value: v => v !== undefined ? (filters.employee = v) : filters.employee,
                    search: true,
                    border: false,
                    list: [{ value: null, label: 'Не выбран' }].concat(
                        employeesData.data()
                        .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
                        .map(v => ({ value: v.id, label: v.name }))
                    )
                }),
                r('.flex.items-center.justify-center.pl-2.pr-2.ml-1.mr-1.leading-8.rounded-full.cursor-pointer.hover:bg-gray-300',
                    { style: { width: '24px', height: '24px' }, onClick() { filters.employee = undefined; redraw(); } },
                    Icon('close', 'black', 2),
                )
            ),
            r('.ml-8'),
            r('.flex.flex-row.items-center.bg-gray-100.border-2.border-gray-300.rounded-full.relative',
                r('.mr-4.pl-4', 'Количество'),
                [10, 20, 50].map(count =>
                    r('.pl-2.pr-2.ml-1.leading-8.rounded-full.cursor-pointer' +
                      (filters.count == count ? '.bg-gray-300' : '.hover:bg-gray-300'),
                        { onClick: () => { filters.count = count; redraw(); } },
                        count
                    )
                )
            ),
            r('.ml-auto'),
            r('.flex.flex-row.text-md',
                'Результатов: ',
                r('.ml-1.text-green-600', table.v.rows.length)
            )
        )
    }

    function render() {
        employeesData.init();
        table.recalc();

        return r('.flex.flex-col',
            Filter(),
            r('.border-t.mt-8'),
            r('.overflow-hidden.overflow-x-auto',
                filters.loading ? r('.mt-4.flex.justify-center.text-center', 'Загрузка данных\nожидайте...')
                : table.render()
            ),
            r('.mt-8'),
            alert.render(),
            r('.flex.flex-row',
                table.pagination.render(),
                r('.flex-1'),
                Button('Сформировать отчет', _ => {
                    if (!filters.begin_date) alert.show('filters');
                    else {
                        filters.page = 0;
                        filters.loading = true;
                        redraw();
                        getTelemetryReport({
                            begin_date: filters.begin_date,
                            end_date: filters.begin_date,
                            employee_id: filters.employee
                        }).catch(alert.show)
                        .then(res => {
                            data = res || [];
                        }).finally(_ => {
                            filters.loading = false;
                            for (const k in columnFilters) delete columnFilters[k];
                            redraw();
                        })
                    }
                }, { disabled: filters.loading }),
                r('.ml-2'),
                Button('Скачать XLSX', _ => {
                    if (!filters.begin_date) alert.show('filters');
                    else {
                        getTelemetryReportXLSX({
                            begin_date: filters.begin_date,
                            end_date: filters.begin_date,
                            employee_id: filters.employee
                        }).catch(alert.show)
                    }
                }, { disabled: filters.loading })
            )
        )
    }
    return { render }
}
