import { r, redraw } from '../../common/vdom/index.js';

export default function(name, color='black', size=8, attrs={}) {
    if (typeof size == 'number') size = [size, size];
    return r(`.bg-${color}.w-${size[0]}.h-${size[1]}`, {
        ...attrs,
        style: {
            MaskImage: `url(/assets/icons/icon-${name}.svg)`,
            WebkitMaskImage: `url(/assets/icons/icon-${name}.svg)`,
            MaskSize: 'cover',
            WebkitMaskSize: 'cover',
            ...attrs.style
        },
    });
}
