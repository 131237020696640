import {r, redraw} from '../../common/vdom/index.js';
import {Alert, Button, Icon, Input, Table} from '../index.js';
import {getEmployeesReport, getEmployeesReportXLSX} from '../../api/reports.js';
import {formatDateYYYYMMDD, replacingDotWithComma} from '../../utils.js';
import {employeesData} from '../../pages/employees/index.js';

export default function ReportTable() {
    let data = [];
    const filters = {count: 10, page: 0};

    const alert = Alert(
        (res, t) => r('.flex.flex-col', {style: {width: '300px'}},
            t === 'filters'
                ? [
                    r('', 'Необходимо Выбрать:'),
                    !filters.begin_date && r('', ' - Дата формирования "с"'),
                    !filters.end_date && r('', ' - Дата формирования "по"'),
                ]
                : r('', String(t))
        )
    );

    const columnFilters = {};
    const table = Table({
        columnFilters,
        pagination: _ => filters.count,
        rows: () => data,
        columns: ['area', 'fio', 'personnel_id', 'position', 'brigadier_fio', 'brigadier_person_id', 'device_id',
            'pacs_enter_data', 'pacs_enter_time', 'pacs_exit_data', 'pacs_exit_time', 'total_time_spent_in_place', 'time_near_ble_zone', 'proportion_absence_near_ble_zone',
            'total_time_active', 'proportion_active_time_in_total_time', 'most_active_place', 'most_active_time_spent_at_place',
            'count_alarms_watch_taken_off', 'total_time_alarms_watch_taken_off',
            'count_alarms_watch_handed', 'total_time_alarms_watch_handed',
            'count_alarms_long_rest_state', 'total_time_alarms_long_rest_state',
            'count_alarms_device_data_not_received', 'total_time_alarms_device_data_not_received',
            'count_alarms_BLE_data_not_received', 'total_time_alarms_BLE_data_not_received'
        ],
        columnWidth: (column) => ({
            area: 32,
            fio: 48,
            personnel_id: 32,
            position: 32,
            brigadier_fio: 32,
            brigadier_person_id: 32,
            device_id: 48,
            pacs_enter_data: 32,
            pacs_enter_time: 32,
            pacs_exit_data: 32,
            pacs_exit_time: 32,
            total_time_spent_in_place: 32,

            time_near_ble_zone: 32,
            proportion_absence_near_ble_zone: 32,

            total_time_active: 32,
            proportion_active_time_in_total_time: 32,

            most_active_place: 32,
            most_active_time_spent_at_place: 32,

            count_alarms_watch_taken_off: 32,
            total_time_alarms_watch_taken_off: 32,
            count_alarms_watch_handed: 32,
            total_time_alarms_watch_handed: 32,
            count_alarms_long_rest_state: 32,
            total_time_alarms_long_rest_state: 32,
            count_alarms_device_data_not_received: 32,
            total_time_alarms_device_data_not_received: 32,
            count_alarms_BLE_data_not_received: 32,
            total_time_alarms_BLE_data_not_received: 32,
        }[column]),
        columnAlign: (column) => ['area', 'fio', 'position', 'brigadier_fio'].includes(column) ? 'left' : 'center',
        columnSeparator: (column) => ['fio', 'personnel_id', 'position', 'brigadier_fio', 'device_id', 'pacs_enter_time',
            'time_near_ble_zone', 'proportion_active_time_in_total_time', 'most_active_place',
            'most_active_time_spent_at_place', 'count_alarms_watch_taken_off', 'brigadier_person_id', 'pacs_enter_data',
            'pacs_exit_data', 'total_time_spent_in_place', 'proportion_absence_near_ble_zone',
            'total_time_alarms_BLE_data_not_received','count_alarms_BLE_data_not_received','total_time_alarms_device_data_not_received',
            'count_alarms_device_data_not_received','total_time_alarms_long_rest_state','count_alarms_long_rest_state',
            'total_time_alarms_watch_handed','total_time_alarms_watch_taken_off','count_alarms_watch_taken_off','pacs_exit_time','total_time_active',


        ].includes(column),
        columnName: (column) => ({
            area: 'Объект',
            fio: 'ФИО сотрудника',
            personnel_id: 'Табельный номер',
            position: 'Профессия',
            brigadier_fio: 'ФИО Бригадира',
            brigadier_person_id: 'Табельный номер бригадира',
            device_id: 'ID носимого устройства (часы)',
            pacs_enter_data: 'Дата входа (СКУД)',
            pacs_enter_time: 'Время входа (СКУД)',
            pacs_exit_data: 'Дата выхода (СКУД)',
            pacs_exit_time: 'Время выхода (СКУД)',
            total_time_spent_in_place: 'Итоговое время на объекте',
            time_near_ble_zone: 'Время нахождения в зоне BLE меток',
            proportion_absence_near_ble_zone: '% отсутствия сотрудника в зоне BLE меток',
            total_time_active: 'Общее время активности',
            proportion_active_time_in_total_time: '% времени активности относительно времени нахождения на объекте',
            most_active_place: 'Место (этаж), где выявлено наибольшее время присутствия',
            most_active_time_spent_at_place: 'Время нахождения на этаже с наибольшим временем нахождения/присутствия сотрудника',
            count_alarms_watch_taken_off: 'Количество ALARM "снято с сотрудника"',
            total_time_alarms_watch_taken_off: 'Общая продолжительность ALARM "снято с сотрудника"',
            count_alarms_watch_handed: 'Количество ALARM "Передано другому сотруднику',
            total_time_alarms_watch_handed: 'Общая продолжительность ALARM "Передано другому сотруднику"',
            count_alarms_long_rest_state: 'Количество ALARM "Длительное состояние покоя',
            total_time_alarms_long_rest_state: 'Общая продолжительность ALARM "Длительное состояние покоя"',
            count_alarms_device_data_not_received: 'Количество ALARM "Данные с носимого устройства не поступают"',
            total_time_alarms_device_data_not_received: 'Общая продолжительность ALARM "Данные с носимого устройства не поступают"',
            count_alarms_BLE_data_not_received: 'Данные о BLE метках с носимого устройства не поступают',
            total_time_alarms_BLE_data_not_received: 'Общая продолжительность ALARM "Данные о BLE метках с носимого устройства не поступают"',
        })[column],
        columnFilterInput: (column) => ({
            area: Table.columnFilterInputs.text,

            fio: Table.columnFilterInputs.text,
            personnel_id: Table.columnFilterInputs.text,
            position: Table.columnFilterInputs.text,

            brigadier_fio: Table.columnFilterInputs.text,
            brigadier_person_id: Table.columnFilterInputs.text,

            device_id: Table.columnFilterInputs.text,

            pacs_enter_data: Table.columnFilterInputs.date(),
            pacs_enter_time: Table.columnFilterInputs.time,

            pacs_exit_data: Table.columnFilterInputs.date(),
            pacs_exit_time: Table.columnFilterInputs.time,

            total_time_spent_in_place: Table.columnFilterInputs.time,

            time_near_ble_zone: Table.columnFilterInputs.time,
            proportion_absence_near_ble_zone: Table.columnFilterInputs.number,

            total_time_active: Table.columnFilterInputs.time,
            proportion_active_time_in_total_time: Table.columnFilterInputs.number,

            most_active_place: Table.columnFilterInputs.text,
            most_active_time_spent_at_place: Table.columnFilterInputs.time,

            count_alarms_watch_taken_off: Table.columnFilterInputs.number,
            total_time_alarms_watch_taken_off: Table.columnFilterInputs.time,
            count_alarms_watch_handed: Table.columnFilterInputs.number,
            total_time_alarms_watch_handed: Table.columnFilterInputs.time,
            count_alarms_long_rest_state: Table.columnFilterInputs.number,
            total_time_alarms_long_rest_state: Table.columnFilterInputs.time,
            count_alarms_device_data_not_received: Table.columnFilterInputs.number,
            total_time_alarms_device_data_not_received: Table.columnFilterInputs.time,
            count_alarms_BLE_data_not_received: Table.columnFilterInputs.number,
            total_time_alarms_BLE_data_not_received: Table.columnFilterInputs.time,
        })[column],
        columnFilterFunction: (column) => ({
            area: Table.columnFilterFunctions.text,

            fio: Table.columnFilterFunctions.text,
            personnel_id: Table.columnFilterFunctions.text,
            position: Table.columnFilterFunctions.text,

            brigadier_fio: Table.columnFilterFunctions.text,
            brigadier_person_id: Table.columnFilterFunctions.text,

            device_id: Table.columnFilterFunctions.text,

            pacs_enter_data: Table.columnFilterFunctions.date,
            pacs_enter_time: Table.columnFilterFunctions.time(true),
            pacs_exit_data: Table.columnFilterFunctions.date,
            pacs_exit_time: Table.columnFilterFunctions.time(true),

            total_time_spent_in_place: Table.columnFilterFunctions.time(),

            time_near_ble_zone: Table.columnFilterFunctions.time(),
            proportion_absence_near_ble_zone: Table.columnFilterFunctions.number,

            total_time_active: Table.columnFilterFunctions.time(),
            proportion_active_time_in_total_time: Table.columnFilterFunctions.number,

            most_active_place: Table.columnFilterFunctions.text,
            most_active_time_spent_at_place: Table.columnFilterFunctions.time(),

            count_alarms_watch_taken_off: Table.columnFilterFunctions.number,
            total_time_alarms_watch_taken_off: Table.columnFilterFunctions.time(),
            count_alarms_watch_handed: Table.columnFilterFunctions.number,
            total_time_alarms_watch_handed: Table.columnFilterFunctions.time(),
            count_alarms_long_rest_state: Table.columnFilterFunctions.number,
            total_time_alarms_long_rest_state: Table.columnFilterFunctions.time(),
            count_alarms_device_data_not_received: Table.columnFilterFunctions.number,
            total_time_alarms_device_data_not_received: Table.columnFilterFunctions.time(),
            count_alarms_BLE_data_not_received: Table.columnFilterFunctions.number,
            total_time_alarms_BLE_data_not_received: Table.columnFilterFunctions.time(),
        })[column],
        cellText: (row, column) => (
            {
                area: row.area,

                fio: row.fio,
                personnel_id: row.personnel_id,
                position: row.position,

                brigadier_fio: row.brigadier_fio,
                brigadier_person_id: row.brigadier_person_id,

                device_id: row.device_id,

                pacs_enter_data: row.pacs_enter_time ? new Date(row.pacs_enter_time).toLocaleDateString() : '',
                pacs_enter_time: row.pacs_enter_time ? new Date(row.pacs_enter_time).toLocaleTimeString() : '',

                pacs_exit_data: row.pacs_exit_time ? new Date(row.pacs_exit_time).toLocaleDateString() : '',
                pacs_exit_time: row.pacs_exit_time ? new Date(row.pacs_exit_time).toLocaleTimeString() : '',

                total_time_spent_in_place: row.total_time_spent_in_place || 0,

                time_near_ble_zone: row.time_near_ble_zone || 0,
                proportion_absence_near_ble_zone: replacingDotWithComma(row.proportion_absence_near_ble_zone) || 0,

                total_time_active: row.total_time_active || 0,
                proportion_active_time_in_total_time: replacingDotWithComma(row.proportion_active_time_in_total_time) || 0,

                most_active_place: row.most_active_place || 0,
                most_active_time_spent_at_place: row.most_active_time_spent_at_place || 0,

                count_alarms_watch_taken_off: row.count_alarms_watch_taken_off || 0,
                total_time_alarms_watch_taken_off: row.total_time_alarms_watch_taken_off || 0,
                count_alarms_watch_handed: row.count_alarms_watch_handed || 0,
                total_time_alarms_watch_handed: row.total_time_alarms_watch_handed || 0,
                count_alarms_long_rest_state: row.count_alarms_long_rest_state || 0,
                total_time_alarms_long_rest_state: row.total_time_alarms_long_rest_state || 0,
                count_alarms_device_data_not_received: row.count_alarms_device_data_not_received || 0,
                total_time_alarms_device_data_not_received: row.total_time_alarms_device_data_not_received || 0,
                count_alarms_BLE_data_not_received: row.count_alarms_BLE_data_not_received || 0,
                total_time_alarms_BLE_data_not_received: row.total_time_alarms_BLE_data_not_received || 0,
            })[column],
    })

    function Filter() {
        if (filters.begin_date && filters.end_date) {
            filters.begin_date = formatDateYYYYMMDD(new Date(Math.min(...[filters.begin_date, filters.end_date].map(d => +new Date(d)))), false);
            filters.end_date = formatDateYYYYMMDD(new Date(Math.max(...[filters.begin_date, filters.end_date].map(d => +new Date(d)))), false);
        }

        return r('.flex.flex-row.items-center.mt-4',
            r('.flex.flex-row.items-center.bg-gray-100.border-2.border-gray-300.rounded-full.relative',
                r('.mr-4.pl-4.leading-8', 'c'),
                r('input', {
                    min: formatDateYYYYMMDD(new Date(new Date(filters.end_date).setDate(new Date(filters.end_date).getDate() - 6))) || "",
                    max: formatDateYYYYMMDD(new Date(new Date(filters.end_date).setDate(new Date(filters.end_date).getDate()))) || "",
                    type: 'date', value: filters.begin_date || '', onChange(e) {
                        filters.begin_date = e.target.value;
                        redraw();
                    }
                }),
                r('.flex.items-center.justify-center.pl-2.pr-2.ml-1.mr-1.leading-8.rounded-full.cursor-pointer.hover:bg-gray-300',
                    {
                        style: {width: '24px', height: '24px'}, onClick() {
                            filters.begin_date = undefined;
                            redraw();
                        }
                    },
                    Icon('close', 'black', 2),
                )
            ),
            r('.ml-4'),
            r('.flex.flex-row.items-center.bg-gray-100.border-2.border-gray-300.rounded-full.relative',
                r('.mr-4.pl-4.leading-8', 'по'),
                r('input', {
                    max: formatDateYYYYMMDD(new Date(new Date(filters.begin_date).setDate(new Date(filters.begin_date).getDate() + 6))) || "",
                    min: formatDateYYYYMMDD(new Date(new Date(filters.begin_date).setDate(new Date(filters.begin_date).getDate()))) || "",
                    type: 'date', value: filters.end_date || '', onChange(e) {
                        filters.end_date = e.target.value;
                        redraw();
                    }
                }),
                r('.flex.items-center.justify-center.pl-2.pr-2.ml-1.mr-1.leading-8.rounded-full.cursor-pointer.hover:bg-gray-300',
                    {
                        style: {width: '24px', height: '24px'}, onClick() {
                            filters.end_date = undefined;
                            redraw();
                        }
                    },
                    Icon('close', 'black', 2),
                )
            ),
            r('.ml-4'),
            r('.flex.flex-row.items-center.bg-gray-100.border-2.border-gray-300.rounded-full.relative',
                r('.mr-4.pl-4', 'Сотрудник'),
                Input(undefined, {
                    value: v => v !== undefined ? (filters.employee = v) : filters.employee,
                    search: true,
                    border: false,
                    list: [{value: null, label: 'Не выбран'}].concat(
                        employeesData.data()
                            .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
                            .map(v => ({value: v.id, label: v.name}))
                    )
                }),
                r('.flex.items-center.justify-center.pl-2.pr-2.ml-1.mr-1.leading-8.rounded-full.cursor-pointer.hover:bg-gray-300',
                    {
                        style: {width: '24px', height: '24px'}, onClick() {
                            filters.employee = undefined;
                            redraw();
                        }
                    },
                    Icon('close', 'black', 2),
                )
            ),
            r('.ml-8'),
            r('.flex.flex-row.items-center.bg-gray-100.border-2.border-gray-300.rounded-full.relative',
                r('.mr-4.pl-4', 'Количество'),
                [10, 20, 50].map(count =>
                    r('.pl-2.pr-2.ml-1.leading-8.rounded-full.cursor-pointer' +
                        (filters.count == count ? '.bg-gray-300' : '.hover:bg-gray-300'),
                        {
                            onClick: () => {
                                filters.count = count;
                                redraw();
                            }
                        },
                        count
                    )
                )
            ),
            r('.ml-auto'),
            r('.flex.flex-row.text-md',
                'Результатов: ',
                r('.ml-1.text-green-600', table.v.rows.length)
            )
        )
    }

    function render() {
        employeesData.init();
        table.recalc();
        return r('.flex.flex-col',
            Filter(),
            r('.border-t.mt-8'),
            r('.overflow-hidden.overflow-x-auto',
                filters.loading ? r('.mt-4.flex.justify-center.text-center', 'Загрузка данных\nожидайте...')
                    : table.render()
            ),
            r('.mt-8'),
            alert.render(),
            r('.flex.flex-row',
                table.pagination.render(),
                r('.flex-1'),
                Button('Сформировать отчет', _ => {
                    if (!filters.begin_date || !filters.end_date) alert.show('filters');
                    else {
                        filters.page = 0;
                        filters.loading = true;
                        redraw();
                        getEmployeesReport({
                            begin_date: filters.begin_date,
                            end_date: filters.end_date,
                            employee_id: filters.employee
                        }).catch(alert.show)
                            .then(res => {
                                data = res || [];
                            }).finally(_ => {
                            filters.loading = false;
                            for (const k in columnFilters) delete columnFilters[k];
                            redraw();
                        })
                    }
                }, {disabled: filters.loading}),
                r('.ml-2'),
                Button('Скачать XLSX', _ => {
                    if (!filters.begin_date || !filters.end_date) alert.show('filters');
                    else {
                        getEmployeesReportXLSX({
                            begin_date: filters.begin_date,
                            end_date: filters.end_date,
                            employee_id: filters.employee
                        }).catch(alert.show)
                    }
                }, {disabled: filters.loading})
            )
        )
    }

    return {render}
}
