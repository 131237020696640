import { r } from '../../common/vdom/index.js';

import { LogsTable } from '../../components/index.js';
import { withAttrs } from '../../components/utils.js';

export default function() {
    const logsTable = LogsTable();
    function render() {
        return r('.p-16.pb-48.bg-blue-50.w-full.select-none',
            r('.text-xl.font-bold', { style: { 'margin-top': '-32px' } }, 'Журнал событий'),
            withAttrs({ style: { 'margin-top': '-42px' } }, logsTable.render()),
        );
    }
    return { render };
}
