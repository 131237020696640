import { r, redraw } from '../../common/vdom/index.js';

import * as api from '../../api/index.js';

import { addView } from '../../admin/vdom/index.js';
import TelemetryJournal from '../../admin/views/telemetry-journal.js';

export default function() {
    const telemetryJournal = TelemetryJournal();
    
    async function params({ id }) {
        const employee = (await api.employee.get() || []).find(d => d.id == id);
        telemetryJournal.params({
            personName: !employee ? '' : employee.surname + ' ' + employee.name + ' ' + employee.patronymic
        });
        redraw();
    }
    
    let dom;
    function render() {
        return r('.bg-blue-50.pl-4.pb-4.h-full.overflow-auto.w-full', {
            ref(dom1) {
                if (dom1 && !dom) addView(dom = dom1, telemetryJournal.render);
            }
        });
    }
    
    return { params, render };
}
