import { r, redraw } from '../../common/vdom/index.js';

export default function LeftMenu({ menuItems, getMenuItem, setMenuItem }) {
    function render() {
        function Logo() {
            const style = {
                backgroundImage: 'url(/assets/icons/logo-smart-team.svg)',
                backgroundSize: "contain", backgroundRepeat: 'no-repeat'
            };
            return r('.h-32.w-20.ml-56.mb-12.mt-16.flex-shrink-0', { style });
        }
        
        function BottomLogo() {
            const style = {
                backgroundImage: 'url(/assets/icons/logo-ctrl2go.svg)',
                backgroundSize: "contain", backgroundRepeat: 'no-repeat'
            };
            return r('.flex-shrink-0.h-16.mb-24.ml-52.mt-auto.w-20', { style });
        }
        
        function MenuItem({ icon, text, item, children }) {
            const selected = [item, ...(children || [])].indexOf(getMenuItem()) !== -1;
            function onClick() { setMenuItem(item); }
            const cls = (
                '.flex.relative.items-center.flex-row.text-white.text-base.cursor-pointer.text-xl' +
                '.pl-16.pt-4.pb-4.pr-16' + 
                '.border-t.border-b.border-white.border-opacity-10' + 
                (selected ? '.font-medium.bg-green-300.bg-opacity-10' : '.opacity-70')
            );
            return r(cls, { onClick, style: { marginBottom: '-1px' } },
                !selected ? '' :
                r('.absolute.left-0.top-0.h-full.border-l-4.border-green-400'),
                r('.w-10.h-10.mr-8.bg-green-600', {
                    style: {
                        MaskImage: `url(/assets/icons/icon-${icon}.svg)`,
                        WebkitMaskImage: `url(/assets/icons/icon-${icon}.svg)`,
                        MaskSize: 'cover',
                        WebkitMaskSize: 'cover',
                    }
                }),
                text
            )
        }
        
        return r('.flex.flex-col.flex-shrink-0.overflow-auto.sticky.top-0.w-96',
            { style: { background: '#222427', height: 'max(100%, 100vh)' } },
            Logo(),
            r('.flex.flex-col',
                menuItems().map(MenuItem)
            ),
            r('.mt-24'),
            BottomLogo()
        );
    }
    return { render };
}
