import { r, redraw } from '../../common/vdom/index.js';

export default function(text, onClick, arg={}) {
    const { color='blue', size='normal', disabled=undefined } = arg;
    const cls = (
        '.flex.flex-row.font-medium.items-center.text-white.cursor-pointer.rounded' +
        ({
            small: '.h-6.pl-2.pr-2',
            normal: '.h-10.pl-4.pr-4',
            big: '.h-12.pl-8.pr-8'
        })[size] + 
        ({
            blue: '.bg-blue-500.border-blue-800.border-b-2',
            red: '.bg-red-400.border-red-600.border-b-2',
        })[color] +
        (disabled ? '.cursor-not-allowed.opacity-50' : '')
    );
    if (disabled) onClick = ()=>0;
    return r(cls, { onClick, disabled }, text);
}
