import { r, redraw } from '../../common/vdom/index.js';
import { Panels } from '../../components/index.js';
import { withAttrs } from '../../components/utils.js';

let dropdownPanel;

let target;
export default function(content, dropdown) {
    dropdownPanel = dropdownPanel || Panels.Panel({
        maxHeight: 300,
        dropUp: false
    });
    return withAttrs({ 
        ref(dom) {
            if (dom == target) dropdownPanel.setContent(() => r('.flex.flex-col.bg-white.rounded-md.z-10',
                typeof dropdown == 'function' ? dropdown(dropdownPanel.close) : dropdown
            ));
        },
        onClick(e) {
            dropdownPanel.setContent(() => r('.flex.flex-col.bg-white.rounded-md.z-10',
                typeof dropdown == 'function' ? dropdown(dropdownPanel.close) : dropdown
            ));
            dropdownPanel.open(target = e.currentTarget);
        }
    }, content);
}
