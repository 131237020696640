export function pipe(arg, ...functions) {
    let res = arg;
    for (const fn of functions) res = fn(res);
    return res;
}

const async = async () => {};
export function isAsync(fn) {
    return fn.__proto__.constructor == async.__proto__.constructor;
}

export function isIterable(obj) {
    return obj != null && typeof obj[Symbol.iterator] === 'function';
}

export function callFn(f) {
    while (typeof f == 'function') f = f();
    return f;
}

export function throttle(delay, func) {
    let lock = false, ctx, args;
    
    function wrapper() {
        if (lock) { ctx = this; args = arguments; return; }
        
        lock = true;
        try { func.apply(this, arguments); }
        catch (e) {}
        finally {
            setTimeout(() => {
                lock = false;
                if (args) wrapper.apply(ctx, args);
                ctx = null; args = null;
            }, delay);
        }
    }

    return wrapper;
}

export function debounce(delay, func) {
    let lock = false;
    
    return function wrapper() {
        if (lock) return;
        lock = true;
        try { func.apply(this, arguments); }
        catch (e) {}
        finally { setTimeout(() => { lock = false; }, delay); }
    }
}

export function once(func) {
    let lock = false;
    
    return function wrapper() {
        if (lock) return;
        lock = true;
        func.apply(this, arguments)
    }
}

export function nonreentrant(func) {
    let lock = false;
    
    return function wrapper() {
        if (lock) return;
        lock = true;
        try { func.apply(this, arguments); }
        catch (e) {}
        finally { lock = false; }
    }
}

export function asyncQueue() {
    const queue = [];
    
    function push(f) { queue.push(f); }
    function clear() { queue.splice(0, queue.length); }
    
    let running;
    async function run() {
        running = true;
        let f;
        while ((f = queue.shift()) && running) await f();
    }
    
    async function stop() {
        running = false;
    }
    
    return { push, run, stop, clear };
};

export const withStatus = (status) => (promise) => {
    status.loading = true;
    status.data = null;
    status.error = null;
    
    return new Promise(async (resolve, reject) => {
        try {
            const res = await promise;
            status.loading = false;
            status.data = res;
            resolve(res);
        } catch (error) {
            status.loading = false;
            status.data = null;
            status.error = error;
            reject(error);
        }
    });
}

export const withUpdate = (update) => (promise) => {
    update();
    return new Promise(async (resolve, reject) => {
        try {
            resolve(await promise);
            update();
        } catch (error) {
            reject(error);
            update();
        }
    });
}

export const sleep = (ms) => new Promise(r => setTimeout(r, ms));
