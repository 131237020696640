import { API_HOST } from '../config.js';
import { getToken } from './login.js';

const errors = {
    bad_occupation_date: "Указана неправильная дата.",
    already_brigadier: "Выбранный сотрудник является бригадиром в другой бригаде.",
    brigadier_is_own_subordinate: "Бригадир не может быть включен в бригаду как сотрудник.",
    brigadier_is_occupied_right_now: async e => {
        const brigade_name = (await crud(`${API_HOST}/brigade`).get()).find(d => d.id == e.brigade_id)?.name;
        return `Сотрудник уже задействован в бригаде "${brigade_name}" (${e.brigade_id}).`;
    },
    employee_already_occupied: async e => {
        const brigade_name = (await crud(`${API_HOST}/brigade`).get()).find(d => d.id == e.brigade_id)?.name;
        return `Сотрудник включен бригаду "${brigade_name}" (${e.brigade_id}) в указанное время.`;
    },
    employee_is_occupied_as_brigadier_right_now: "Сотрудник уже включен в другую бригаду.",
    brigadier_is_not_exists: "Выбранный brigadier_id не найден в таблице Employee.",
    end_date_is_already_set: "Дата окончания уже указана для этой записи.",
    cannot_set_end_date_before_begin_date: "Дата окончания не может быть указана, пока не указана дата начала.",
    cannot_modify_brigadier_end_date: "Невозможно исключить бригадира из его бригады.",
    record_is_closed: "Статус выбранной записи: завершена.",
    cannot_add_employee_to_same_brigade: "Сотрудник уже является членом бригады в указанное время.",
    cannot_create_brigade_in_future: "Указанное время формирования бригады превышает допустимые значения.",
    cannot_modify_brigade_record_in_future: "Указанное время включения в бригаду превышает допустимые значения.",
    cannot_create_brigade_record_in_future: "Указанное время включения в бригаду превышает допустимые значения.",
    current_personnel_id_employee_already_exist: "Активный сотрудник с указанным табельным номером уже существует.",
    cannot_remove_admin_flag_from_self: "Вы не можете снять привелегии администратора с самого себя.",
    cannot_delete_yourself: "Вы не можете удалить себя.",
    cannot_delete_admin: "Пользователь не может быть удален, пока он является администратором.",
    password_required_to_be_ascii: "Пароль должен содержать только ASCII символы.",
    password_should_be_more_than_five_symbols: "Минимальная длина пароля — 5 символов.",
    password_required_to_be_a_string: "Введен некорректный пароль.",
    username_length_must_be_more_than_five: "Минимальная длина имени пользователя — 5 символов."
}

export function methodCall(url, method, format=res=>res, pformat=params=>params) {
    return (params={}) => {
        params = Object.fromEntries(Object.entries(pformat(params)).filter(kv => kv[1] !== undefined));
        return new Promise((resolve, reject) => {
            fetch(url, {
                method,
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                },
                ...(Object.keys(params) == 0 ? {} : { body: JSON.stringify(params) })
            })
            .then(async response => {
                let data;
                try { data = await response.json(); }
                catch (e) { data = {}; }
                if (!response.ok) {
                    let details = data.detail;
                    if (typeof details === 'object') {
                        const err = errors[details.code];
                        details = !err ? details.msg : typeof err == 'function' ? await err(details) : err;
                    }
                    reject(Object.assign(new Error(details), { response, data }));
                } else {
                    resolve(format(data, params));
                }
            }).catch(reject)
        });
    }
}

export function crud(prefix) {
    return {
        get(id='') {
            return new Promise((resolve, reject) => {
                methodCall(prefix + '/' + id, 'GET')()
                    .then(res => resolve(!id && res.detail ? [] : res))
                    .catch(() => resolve([]));
            });
        },
        create(body) {
            return methodCall(prefix + '/', 'POST')(body);
        },
        update(id, body) {
            return methodCall(prefix + '/' + id, 'PUT')(body);
        },
        delete(id) {
            return methodCall(prefix + '/' + id, 'DELETE')();
        }
    };
}
