import { r } from '../../common/vdom/index.js';
import { EmployeesReportTable, AlarmsReportTable, TelemetryReportTable ,StatusBleLabelsReportTable} from '../../components/index.js';

import EmployeesReportTableForm2 from "../../components/employees-report-table-form2";

export default function() {
    const employeesReportTable = EmployeesReportTable();
    const employeesReportTableForm2 = EmployeesReportTableForm2();
    const alarmsReportTable = AlarmsReportTable();
    const telemetryReportTable = TelemetryReportTable();
    const statusBleLabelsReportTable = StatusBleLabelsReportTable();
    function render() {
        return r('.p-16.pb-48.bg-blue-50.w-full.select-none',
            r('.text-xl.font-bold', { style: { 'margin-top': '-32px' } }, 'Отчет по сотрудникам. Формат 1.'),
            employeesReportTable.render(),
            r('.text-xl.font-bold', { style: { 'margin-top': '32px' } }, 'Отчет по сотрудникам. Формат 2.'),
            employeesReportTableForm2.render(),
            r('.text-xl.font-bold.mt-8', 'Отчет по алармам'),
            alarmsReportTable.render(),
            r('.text-xl.font-bold.mt-8', 'Отчет по телеметрии'),
            telemetryReportTable.render(),
        r('.text-xl.font-bold.mt-8', 'Отчет по статусу BLE меток'),
            statusBleLabelsReportTable.render(),
        );
    }
    return { render };
}
