import { r, redraw } from '../../common/vdom/index.js';
import { Icon, Alert, Button, Input, Table } from '../../components/index.js';
import { withAttrs } from '../../components/utils.js';
import * as api from '../../api/index.js';
import { value, valueRef } from '../../utils.js';

const formatDate = (date, locale='en-US') => new Date(date).toLocaleTimeString(locale, {
    year: 'numeric', month: 'long', day: 'numeric',
    hour: '2-digit', minute: '2-digit', second: '2-digit'
});
const UTCDate = date => new Date(formatDate(date)).toISOString();

export default function({ onFinish }) {
    let id, data;
    let employees = [], brigades = [], brigadeRecords = [], allBrigadeRecords = [];
    let edit = {};
    
    async function addEmployee(row) {
        if (!edit[row.id].occupation_begin_date || !edit[row.id].employee_id) return alerts.text.show(
            r('.flex.flex-col', { style: { width: '400px' } },
                r('.mb-2', 'Необходимо для заполнения:'),
                !edit[row.id].employee_id && r('', ' - Выбранный сотрудник'),
                !edit[row.id].occupation_begin_date && r('', ' - Дата включения в бригаду')
            )
        );
        
        try {
            Object.assign(row, await api.brigadeRecord.create(edit[row.id]));
            edit[row.id] = null;
            await load();
            redraw();
        } catch (err) {
            alerts.text.show(err?.response.status == '500' ? "Ошибка сохранения данных на сервере, свяжитесь с администратором." : err.message);
        }
    }
    
    async function removeEmployee(row) {
        if (!edit[row.id].occupation_end_date) return alerts.text.show(
            r('.flex.flex-col', { style: { width: '400px' } },
                r('.mb-2', 'Необходимо для заполнения:'),
                !edit[row.id].occupation_end_date && r('', ' - Дата исключения из бригады')
            )
        );
        try {
            Object.assign(row, await api.brigadeRecord.update(row.id, edit[row.id]));
            edit[row.id] = null;
            await load();
        } catch (e) {
            alerts.text.show(e.response.status == '500' ? "Ошибка сохранения данных на сервере, свяжитесь с администратором." : e.message);
        }
        redraw();
    }
    
    let sortDir = value();
    const table = Table({
        sortDir,
        rows: () => brigadeRecords,
        columns: ['employee_name', 'occupation_begin_date', 'occupation_end_date'],
        columnName: (column) => ({
            employee_name: 'Сотрудник',
            occupation_begin_date: 'Дата включения в бригаду',
            occupation_end_date: 'Дата исключения из бригады'
        })[column],
        columnWidth: (column) => ({
            employee_name: 96,
            occupation_begin_date: 96,
            occupation_end_date: 96
        })[column],
        columnAlign: (column) => 'left',
        cellText: (row, column) => ({
            employee_name: row.employee ? `${row.employee.surname} ${row.employee.name} ${row.employee.patronymic}` : r('.opacity-60', 'Не указано'),
            occupation_begin_date: row.occupation_begin_date ? formatDate(row.occupation_begin_date, 'ru-RU') : null,
            occupation_end_date: row.occupation_end_date ? formatDate(row.occupation_end_date, 'ru-RU') : null
        })[column] || row[column],
        cellRender: (row, column) => ({
            employee_name: v => (
                !String(row.id).startsWith('create') ? v :
                withAttrs({ style: { marginTop: '-46px' } }, Input('Выбранный сотрудник', {
                    search: true,
                    list: employees.map(e => ({ value: e.id, label: `${e.surname} ${e.name} ${e.patronymic}` })).sort((a, b) => a.label.localeCompare(b.label)),
                    value: (v=null) => {
                        if (v == null) return edit[row.id].employee_id;
                        edit[row.id].employee_id = v;
                        edit[row.id].employee = employees.find(e => e.id == v);
                    }
                }))
            ),
            occupation_begin_date: v => (
                !String(row.id).startsWith('create') ? v :
                r('.flex.flex-col',
                    Input('Дата включения в бригаду', {
                        type: 'datetime-local',
                        value: valueRef(edit[row.id], 'occupation_begin_date')
                    }),
                    r('.flex.flex-row.items-center.mt-4.mb-4',
                        r('.flex.flex-row.items-center.cursor-pointer',
                            { async onClick() {
                                brigadeRecords.splice(brigadeRecords.indexOf(row), 1);
                                redraw();
                            } },
                            r('.text.text-red-400.uppercase.font-bold', 'Отменить')
                        ),
                        r('.ml-8'),
                        r('.flex.flex-row.items-center.cursor-pointer',
                            { onClick: () => addEmployee(row) },
                            r('.text.text-blue-400.uppercase.font-bold', 'Сохранить')
                        )
                    )
                )
            ),
            occupation_end_date: v => (
                String(row.id).startsWith('create') ? '' :
                v ? v :
                row.employee_id === data.brigadier_id ? '' :
                edit[row.id]
                ? r('.flex.flex-col',
                    Input('Дата исключения из бригады', {
                        type: 'datetime-local',
                        value: valueRef(edit[row.id], 'occupation_end_date')
                    }),
                    r('.flex.flex-row.items-center.mt-4.mb-4',
                        r('.flex.flex-row.items-center.cursor-pointer',
                            { async onClick() {
                                edit[row.id] = null;
                                redraw();
                            } },
                            r('.text.text-red-400.uppercase.font-bold', 'Отменить')
                        ),
                        r('.ml-8'),
                        r('.flex.flex-row.items-center.cursor-pointer',
                            { onClick: () => removeEmployee(row) },
                            r('.text.text-blue-400.uppercase.font-bold', 'Исключить из бригады')
                        )
                    )
                )
                : r('.flex.flex-col',
                    r('.flex.flex-row.items-center.cursor-pointer',
                        { async onClick() {
                            edit[row.id] = { ...row, occupation_end_date: new Date() };
                            redraw();
                        } },
                        r('.text.text-red-400.uppercase.font-bold', 'Исключить из бригады')
                    )
                )
            ),
        })[column]
    });
    
    async function load() {
        employees = (await api.employee.get()).filter(e => !e.disabled);
        brigades = await api.brigade.get();
        allBrigadeRecords = await api.brigadeRecord.get();
        brigadeRecords = allBrigadeRecords.filter(d => d.brigade_id == id);
    }
    
    let deletion;
    async function params(p) {
        data = null;
        redraw();
        id = p.id;
        deletion = false;
        if (!id) onFinish();
        await load();
        data = await api.brigade.get(id);
    }
    
    const alerts = {
        text: Alert(
            (_, v) => typeof v == 'function' ? v(_) : v
        ),
        required: Alert(
            () => r('.flex.flex-col', { style: { width: '400px' } },
                r('.mb-2', 'Необходимо для заполнения:'),
                !data.end_date && r('', ' - Дата и время расформирования')
            )
        )
    }
    
    let changed_data = {};
    let applying;
    async function applyChanges() {
        if (applying) return;
        applying = true;
        try {
        await (async () => {
            Object.assign(data, changed_data);
            if (!data.end_date) return alerts.required.show();

            data.begin_date = UTCDate(data.begin_date);
            data.end_date = UTCDate(data.end_date);
            data = await api.brigade.update(id, data);
            await load();
            onFinish();
        })();
        }
        finally {
        applying = false;
        }
    }
    
    function render() {
        //console.log({ data, employees, brigades, brigadeRecords });
        if (!data) return;
        return r('.flex.flex-col.pl-16.pr-16.pt-8.pb-8.bg-blue-50.w-full',
            Object.values(alerts).map(alert => alert.render()),
            r('.text-md.font-medium.text-green-500', 'Бригады /'),
            r('.mt-12'),
            r('.text-2xl.font-bold.w-80.text-gray-700', 'Бригада ' + data.name),
            r('.mt-8'),
            r('.flex.flex-col', { style: { width: '600px' } },
                r('.flex.flex-col',
                    r('.text-md.font-bold', 'Дата и время формирования'),
                    r('.text-xl', formatDate(data.begin_date, 'ru-RU')),
                ),
                data.end_date && [
                    r('.mt-8'),
                    r('.flex.flex-col',
                        r('.text-md.font-bold', 'Дата и время расформирования'),
                        r('.text-xl', formatDate(data.end_date, 'ru-RU')),
                    )
                ],
                r('.mt-8'),
                r('.flex.flex-col',
                    r('.text-md.font-bold', 'Бригадир'),
                    r('.text-xl',
                        employees.filter(d => d.id == data.brigadier_id).map(d => `${d.surname} ${d.name} ${d.patronymic}`)[0]
                    ),
                ),
                r('.mt-8'),
                r('.flex.flex-row.items-center',
                    r('.text-md.font-bold', 'Сотрудники'),
                    r('.ml-8'),
                    !data.end_date ? r('.flex.flex-row.items-center.cursor-pointer',
                        { async onClick() {
                            sortDir(0);
                            const itemId = 'create' + Math.random();
                            brigadeRecords.unshift({
                                id: itemId,
                                brigade_id: id,
                                employee: null,
                                employee_id: null,
                                occupation_begin_date: new Date(),
                                occupation_end_date: null
                            });
                            edit[itemId] = brigadeRecords[0];
                            redraw();
                        } },
                        Icon('plus', 'green-600', 6),
                        r('.text.text-green-600.uppercase.font-medium.ml-1', 'Добавить сотрудника')
                    ) : ''
                ),
                r('.mt-4'),
                table.render(),
                !data.end_date && [
                    r('.mt-16'),
                    r('.sticky.bottom-0.pt-12.bg-blue-50',  { style: { width: '600px' } },
                        deletion
                        ? [
                            r('.mt-8'),
                            Input('Дата и время расформирования', {
                                required: true, type: 'datetime-local',
                                value: valueRef(changed_data, 'end_date')
                            }),
                            r('.mt-8'),
                            r('.flex.flex-row',
                                r('.border-gray-600.border-2.text-gray-600.p-6.text-center.cursor-pointer.text-md.font-bold.uppercase',
                                    { style: { width: '160px' }, onClick: () => { deletion = false; redraw(); } },
                                    'Отменить'
                                ),
                                r('.border-red-400.border-2.text-red-400.p-6.text-center.cursor-pointer.text-md.font-bold.uppercase.ml-4',
                                    { style: { flex: '1' }, onClick: applyChanges },
                                    'Расформировать бригаду'
                                )
                            )
                        ]
                        : r('.border-red-400.border-2.text-red-400.p-6.text-center.cursor-pointer.text-md.font-bold.uppercase',
                            { style: { width: '600px' }, onClick: () => { changed_data.end_date = new Date(); deletion = true; redraw(); } },
                            'Расформировать бригаду'
                        )
                    )
                ]
            )
        )
    }
    return { params, render };
}
