import './index.css';

import { r, redraw, setApp, appContainer } from './common/vdom/index.js';
import scaleApp, { setBaseWidth } from './common/utils/scaleApp.js';
import { throttle } from './common/utils/func.js';
import { AppMenu, Icon, Panels } from './components/index.js';

import Auth from './pages/auth/index.js';

import Main from './pages/main/index.js';
import Employees from './pages/employees/index.js';
import { employeesData } from './pages/employees/index.js';
import EditEmployee from './pages/edit-employee/index.js';
import EmployeeTelemetry from './pages/employee-telemetry/index.js';
import AssignDevices from './pages/assign-devices/index.js';
import Reports from './pages/reports/index.js';
import Brigades from './pages/brigades/index.js';
import AddBrigade from './pages/add-brigade/index.js';
import BrigadeInfo from './pages/brigade-info/index.js';
import Logs from './pages/logs/index.js';
import Globals from './pages/globals/index.js';
import Devices from './pages/devices/index.js';
import Users from './pages/users/index.js';

import { getToken, setToken } from './api/login.js';

function Layout({ user, logout }) {
    employeesData.flush();
    
    const sections = {
        'main': Main({
            openEmployee: (id) => user().admin && openSection('employees/telemetry', { id })
        }),
        'reports': Reports(),
        'assign-devices': AssignDevices({}),

        
        'employees': Employees({
            addEmployee: () => openSection('employees/add'),
            openEmployee: (id) => user().admin && openSection('employees/telemetry', { id }),
            editEmployee: (id) => openSection('employees/edit', { id })
        }),
        'employees/add': EditEmployee({
            mode: 'add',
            onFinish: () => openSection('employees', { reload: true })
        }),
        'employees/edit': EditEmployee({
            mode: 'edit',
            onFinish: () => openSection('employees', { reload: true })
        }),
        'employees/telemetry': EmployeeTelemetry({}),        
        
        
        'brigades': Brigades({
            addBrigade: () => openSection('brigades/add'),
            editBrigade: (id) => openSection('brigades/info', { id })
        }),
        'brigades/add': AddBrigade({
            onFinish: () => openSection('brigades')
        }),
        'brigades/info': BrigadeInfo({
            onFinish: () => openSection('brigades')
        }),
        'logs': Logs(),
        'globals': Globals(),
        'devices': Devices(),
        'users': Users({ user }),
    };
    const renderedSections = {};
    let section;
    
    const clearSlashes = path => path.toString().replace(/\/$/, '').replace(/^\//, '');

    window.onpopstate = function(event) {
        let section1 = '';
        section1 = clearSlashes(decodeURI(window.location.pathname + window.location.search));
        section1 = section1.replace(/\?(.*)$/, '');
        section1 = clearSlashes(section1);
        //console.log(decodeURI(window.location.pathname + window.location.search),{section1});
        if (sections[section1] === undefined ||
            (user() && !user().admin && sectionsAllowedForUser.indexOf(section1) == -1)
        ) return openSection('main');
        (async () => {
            await sections[section]?.onclose?.();
            section = section1;
            await sections[section]?.onopen?.();
            await sections[section]?.params?.(event.state ?? {});
            redraw();
        })();
        return false;
    }
    
    const sectionsAllowedForUser = ['main', 'reports', 'employees/telemetry'];
    async function openSection(section1, params={}) {
        section = user() && !user().admin && sectionsAllowedForUser.indexOf(section1) == -1 ? 'main' : section1;
        //console.log({section, section1});
        redraw();
        history.pushState(params, null, '/'+section1);
        window.onpopstate({ state: params });
    }
    window.onpopstate({});

    
    let leftmenu = AppMenu({
        menuItems: () => !user() ? [] : !user().admin ? [
            { icon: 'menu-main', text: 'Главная', item: 'main' },
            { icon: 'menu-report', text: 'Отчеты', item: 'reports' },
            //{ icon: 'menu-telemetry', text: 'Телеметрия', item: 'employees/telemetry' },
        ] : [
            { icon: 'menu-main', text: 'Главная', item: 'main' },
            { icon: 'menu-staff', text: 'Персонал', item: 'employees', children: ['employees/add', 'employees/edit'] },
            { icon: 'menu-report', text: 'Отчеты', item: 'reports' },
            { icon: 'menu-objects', text: 'Объекты', item: 'assign-devices' },
            { icon: 'menu-brigades', text: 'Бригады', item: 'brigades', children: ['brigades/add', 'brigades/info'] },
            //{ icon: 'menu-telemetry', text: 'Телеметрия', item: 'employees/telemetry' },
            { icon: 'menu-log', text: 'Журнал событий', item: 'logs' },
            { icon: 'menu-users', text: 'Пользователи', item: 'users' },
            { icon: 'menu-devices', text: 'Устройства', item: 'devices' },
            { icon: 'menu-globals', text: 'Настройки', item: 'globals' }
        ],
        getMenuItem: () => section,
        setMenuItem(item) { openSection(item, {}); redraw(); }
    });
    
    function render() {
        function Section([key, { render }]) {
            if (section == key) renderedSections[key] = render();
            const sectionElem = renderedSections[key];
            if (!sectionElem) return '';
            
            const className = (
                (sectionElem.props.className || '') +
                (section == key ? '' : ' hidden')
            );
            
            return React.cloneElement(sectionElem, {
                className: [...new Set(className.split(' '))].join(' ')
            }, sectionElem.props.children);
        }
        
        return r('.flex.flex-row.w-full.h-full.bg-blue-50',
            leftmenu.render(),
            r('.flex.flex-col.overflow-auto.w-full',
                r('.bg-white.z-50.h-14.shadow-sm.border-b.w-full.sticky.top-0.flex-shrink-0.flex.flex-row.items-center',
                    r('.ml-auto'),
                    /*
                    r('.border-4.flex-shrink-0.border-green-600.border-opacity-20.mr-6.rounded-full.overflow-hidden',
                        r('.h-8.w-8', {
                            style: { background: `url(/assets/ava4.png)`, backgroundSize: 'contain' }
                        })
                    ),
                    */
                    r('.text-sm.mr-8.w-48', user()?.full_name ? user()?.full_name : (user()?.admin ? 'Администратор' : 'Пользователь')),//`${user().name} ${user().patronymic} ${user().surname}`),
                    r('.cursor-pointer.pr-4.pl-4', { onClick: logout },
                        Icon('quit', 'gray-600.hover:bg-black', 8)
                    )
                ),
                Object.entries(sections).map(Section)
            )
        );
    }
    
    return { render };
}

function App() {
    let user;
    const relayout = _ => Layout({
        user: () => user,
        logout() {
            //console.log('logout');
            user = null;
            setToken('');
            redraw();
        }
    });

    let layout = relayout();
    const auth = Auth({
        onlogin(user1) {
            user = user1;
            layout = relayout();
            redraw();
        }
    });

    setBaseWidth(1920);
    function rescale() {
        scaleApp(appContainer);
        setTimeout(redraw, 200);
    }
    
    function render() {
        Panels.panelsContainer.redraw();
        return (
            !getToken() ? auth.render() :
            layout.render() 
        );
    }
    
    return { render, rescale };
}

const app = App();
setApp(app);

app.rescale();
window.addEventListener('resize', throttle(500, app.rescale));


