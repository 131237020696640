let app = { render: () => '' };
export const appContainer = document.getElementById('app');

export function setApp(app1) {
    app = app1;
    redraw();
}

export function redraw() {
    ReactDOM.render(app.render(), appContainer);
}

const reactElementType = React.createElement('div')['$$typeof'];
export function r(is, attrs, ...children) {
    if (typeof attrs != 'object' || Array.isArray(attrs) || attrs == null || attrs.$$typeof == reactElementType) {
        children.unshift(attrs);
        attrs = {};
    }
    
    let [tag, ...classes] = is.split('.');
    tag = tag || 'div';
    attrs.className = [...classes, attrs.class, attrs.className].filter(v => v).map(v => v.replace(/\./g, ' ')).join(' ');
    delete attrs.class;
    
    return React.createElement(tag, attrs, children.length ? [...children] : null);
}