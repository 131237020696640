export * as login from './login.js';
export * as telemetry from './telemetry.js';

import { API_HOST } from '../config.js';
import { crud, methodCall } from './utils.js';

export const device = crud(`${API_HOST}/device`);
export const deviceToken = id => methodCall(`${API_HOST}/device/${id}/token`, 'GET', res => res.token)();

export const employee = crud(`${API_HOST}/employee`);
export const employeesStatus = crud(`${API_HOST}/employees/status`);
export const organization = crud(`${API_HOST}/organization`);
export const mapping = crud(`${API_HOST}/mapping`);
export const place = crud(`${API_HOST}/place`);
export const department = crud(`${API_HOST}/department`);
export const brigade = crud(`${API_HOST}/brigade`);
export const brigadeRecord = crud(`${API_HOST}/brigade/records`);
export const user = crud(`${API_HOST}/user`);
export const appointment = crud(`${API_HOST}/appointment`);
export const globals = crud(`${API_HOST}/global`);
export const classifier = crud(`${API_HOST}/classifier`);