import { r, redraw } from '../../common/vdom/index.js';
import { valueRef, value, formatBleID } from '../../utils.js';
import { Icon, Alert, Button, Input, Table, Dropdown } from '../../components/index.js';
import { withAttrs } from '../../components/utils.js';
import * as api from '../../api/index.js';

export default function(arg={}) {
    let devices = [];
    const edit = {};
    
    const deviceTypes = [
        { value: 'ble', label: "BLE метка" },
        { value: 'watch', label: "Часы" }
    ];
    
    const sortDir = value(0);
    const table = Table({
        sortDir,
        rows: () => devices,
        columns: () => ['name', 'device_type', 'token_btn', 'edit_btn', 'delete_btn'],
        columnAlign: column => ({
            name: 'left',
            device_type: 'left',
            token_btn: 'center',
            edit_btn: 'center',
            delete_btn: 'center',
        })[column],
        columnName: column => ({
            name: 'Название устройства',
            device_type: 'Тип устройства',
            token_btn: '',
            edit_btn: '',
            delete_btn: '',
        })[column],
        columnFilterInput: column => ({
            name: Table.columnFilterInputs.text,
            device_type: Table.columnFilterInputs.multiselect(deviceTypes)
        })[column],
        columnFilterFunction: column => ({
            name: Table.columnFilterFunctions.text,
            device_type: Table.columnFilterFunctions.multiselect_or
        })[column],
        cellFilterValue: (row, column) => ({
            // Вообще лучше сделать какой-то общий преобразователь данных, который используется по умолчанию для поиска
            // Если нужен какой-то отдельный способ поиска то тут уже надо придумывать как строить такие фильтры
            // способные обрабатывать и предлагать какие-то полее умные решения
            name: row.name ? row.device_type == 'ble' ? formatBleID(row.name) : row.name : 'Не указано'
        })[column] || row[column],
        cellSortValue: (row, column) => ({
            name: formatBleID(row.name)
        })[column] || row[column],
        cellRender: (row, column) => ({
            name: _ => (
                row.device_type == 'ble'
                ? (
                    edit[row.id]
                    ? Input('', { value: valueRef(edit[row.id], 'name') })
                    : row.name ? formatBleID(row.name) : r('.opacity-60', 'Не указано')
                )
                : (
                    edit[row.id]
                    ? Input('', { value: valueRef(edit[row.id], 'name') })
                    : row.name || r('.opacity-60', 'Не указано')
                )
            ),
            device_type: _ => (
                edit[row.id]
                ? Input('', {
                    value: valueRef(edit[row.id], 'device_type'),
                    list: deviceTypes
                })
                : (
                    row.device_type
                    ? deviceTypes.find(v => v.value == row.device_type)?.label
                    : r('.opacity-60', 'Не указано')
                )
            ),
            token_btn: _ => (
                row.device_type == 'ble'
                ? ''
                : r('.flex.flex-row.items-center.font-bold.text-md.text-gray-600.cursor-pointer.uppercase',
                    { onClick: async () => {
                        alert.show(r('.whitespace-prewrap',
                            'Токен устройства ', r('.font-bold.inline', row.name), ': ',
                            await api.deviceToken(row.id) || r('.opacity-60', 'Не определен')
                        ));
                    } },
                    r('', 'Получить токен')
                )
            ),
            edit_btn: _ => (
                edit[row.id]
                ? [
                    r('.flex.flex-row.items-center.font-bold.text-md.text-red-600.cursor-pointer.uppercase',
                        { onClick: async () => {
                              if (String(row.id).startsWith('create')) devices.splice(devices.indexOf(row), 1);
                              edit[row.id] = null;
                              redraw();
                          }
                        },
                        r('', 'Отменить')
                    ),
                    r('.mr-4'),
                    r('.flex.flex-row.items-center.font-bold.text-md.text-blue-400.cursor-pointer.uppercase',
                        { onClick: async () => {
                              if (edit[row.id].device_type == 'ble') {
                                  try {
                                      edit[row.id].name = formatBleID(edit[row.id].name, { inverse: true });
                                  } catch (e) {
                                      alert.show('Введено неправильное название BLE-метки.');
                                      return;
                                  }
                              }
                              
                              try {
                                  if (String(row.id).startsWith('create')) {
                                      Object.assign(row, await api.device.create(edit[row.id]));
                                  } else {
                                      Object.assign(row, await api.device.update(row.id, edit[row.id]));
                                  }
                                  edit[row.id] = null;
                                  load();
                              } catch (e) {
                                  alert.show(e.response.status == '500' ? "Ошибка сохранения данных на сервере, свяжитесь с администратором." : e.message);
                              }
                              redraw();
                          }
                        },
                        r('', 'Сохранить')
                    )
                ]
                : r('.flex.flex-row.items-center.font-bold.text-md.text-gray-600.cursor-pointer.uppercase',
                    { onClick: () => {
                        edit[row.id] = { ...row };
                        if (edit[row.id].device_type == 'ble')
                            edit[row.id].name = formatBleID(edit[row.id].name);
                        redraw();
                    } },
                    Icon('edit', 'gray-600', 6),
                    r('', 'Редактировать')
                )
            ),
            delete_btn: _ => (
                String(row.id).startsWith('create') ? '' :
                r('.flex.flex-row.items-center.font-bold.text-md.text-red-400.cursor-pointer.uppercase',
                    { onClick: async () => {
                          if (!(await deleteAlert.show({ device: row }))) return;
                          await api.device.delete(row.id);
                          devices.splice(devices.indexOf(row), 1);
                          redraw();
                          load();
                      }
                    },
                    Icon('delete', 'red-400', 6),
                    r('', 'Удалить')
                )
            )
        })[column]
    });
    
    async function load() {
        devices = (await api.device.get()).reverse();
        redraw();
    }
    
    function onopen() {
        load();
    }
    
    const deleteAlert = Alert(
        (confirm, { device }={}) =>
            r('.flex.flex-col.whitespace-pre-wrap', { style: { width: '450px' } },
                r('.mt-4', `Удалить устройство `, r('.font-bold.inline', device?.name), ' ?'),
                r('.flex.flex-row.mt-8',
                    Button('Подтвердить', () => confirm(true), { color: 'blue' }),
                    r('.mr-4'),
                    Button('Отменить', () => confirm(false), { color: 'red' })
                )
            )
    );
    
    const alert = Alert(
        (confirm, text) => r('.flex.flex-col.whitespace-pre-wrap', { style: { width: '450px' } }, text)
    );
    
    function render() {
        return r('.flex.flex-col.p-16',
            alert.render(),
            deleteAlert.render(),
            r('.flex.flex-row.mb-4.mt-4', { style: { marginTop: '-32px' } },
                r('.text-xl.font-bold', 'Устройства'),
                r('.ml-8'),
                r('.flex.flex-row.items-center.cursor-pointer',
                    { async onClick() {
                        sortDir(0);
                        const id = 'create' + Math.random();
                        devices.unshift({ id, name: '', device_type: 'watch' });
                        edit[id] = devices[0];
                        redraw();
                    } },
                    Icon('plus', 'green-600', 6),
                    r('.text.text-green-600.uppercase.font-medium.ml-1', 'Добавить устройство')
                )
            ),
            r('.mt-2'),
            r('.border-t'),
            r('.mt-2'),
            table.render(),
        );
    }
    
    return { onopen, render };
}
