import { r, redraw } from '../../common/vdom/index.js';
import { Icon, Alert, Button, Input } from '../../components/index.js';
import * as api from '../../api/index.js';
import { valueRef } from '../../utils.js';

const formatDate = (date, locale='en-US') => new Date(date).toLocaleTimeString(locale, {
    year: 'numeric', month: 'long', day: 'numeric',
    hour: '2-digit', minute: '2-digit', second: '2-digit'
});
const UTCDate = date => new Date(formatDate(date)).toISOString();

export default function({ onFinish }) {
    let id, data = {};
    let employees = [], brigades = [];
    
    async function load() {
        employees = (await api.employee.get()).filter(e => !e.disabled);
        brigades = await api.brigade.get();
    }
    
    async function params(p) {
        await load();
        data = {
            id: 0,
            name: '',
            brigadier_id: '',
            start_date: '',
            end_date: '',
            employee_links: []
        };
    }
    
    const alerts = {
        brigadierTaken: Alert(
            (confirm, { oldDepartment, brigadier }={}) => r('.flex.flex-col', { style: { width: '400px' } },
                r('',
                    'Бригадир ',
                    r('.font-bold.inline', `${brigadier?.surname} ${brigadier?.name} ${brigadier?.patronymic}`),
                    ` уже привязан к бригаде `,
                    r('.font-bold.inline', oldDepartment?.name)
                ),
                r('.mt-8', 'Расформировать старую бригаду?'),
                r('.flex.flex-row.mt-8',
                    Button('Подтвердить', async () => {
                        try {
                            await api.brigade.update(oldDepartment.id, {
                                end_date: new Date(+data.begin_date - 1000)
                            });
                            confirm(true);
                        } catch(e) {
                            confirm(e);
                        }
                    }, { color: 'blue' }),
                    r('.mr-4'),
                    Button('Отменить', () => confirm(false), { color: 'red' })
                )
            )
        ),

        alert: Alert(
            (confirm, text) => r('.flex.flex-col.whitespace-pre-wrap', { style: { width: '450px' } }, text)
        ),
        
        required: Alert(
            () => r('.flex.flex-col', { style: { width: '400px' } },
                r('.mb-2', 'Необходимо для заполнения:'),
                !data.name && r('', ' - Название бригады'),
                !data.brigadier_id && r('', ' - Бригадир')
            )
        )
    }
    
    let applying;
    async function applyChanges() {
        if (applying) return;
        applying = true;
        await (async () => {
            try {
                if (!data.name || !data.brigadier_id) return alerts.required.show();
                if (!data.begin_date) data.begin_date = (new Date()).toISOString();

                data = await api.brigade.create(data);
                await load();
                onFinish();
            } catch(e) {
                alerts.alert.show(e.message);
            }
        })();
        applying = false;
    }
    
    function render() {
        //console.log({ data, employees, brigades });
        
        return r('.flex.flex-col.pl-16.pr-16.pt-8.pb-8.bg-blue-50.w-full',
            Object.values(alerts).map(alert => alert.render()),
            r('.text-md.font-medium.text-green-500', 'Бригады /'),
            r('.mt-12'),
            r('.text-2xl.font-bold.w-80.text-gray-700', 'Создание бригады'),
            r('.mt-8'),
            r('.flex.flex-col', { style: { width: '600px' } },
                Input('Название бригады', {
                    required: true,
                    value: valueRef(data, 'name')
                }),
                r('.mt-8'),
                Input('Дата и время формирования', {
                    type: 'datetime-local',
                    value: valueRef(data, 'begin_date')
                }),
                r('.mt-16'),
                r('.text-xl.font-bold.mb-2', 'Бригадир'),
                r('.grid.grid-cols-2.gap-8',
                    Input(' ', {
                        required: true, search: true,
                        list: employees.map(d => ({ label: `${d.surname} ${d.name} ${d.patronymic}`, value: d.id })).sort((a, b) => a.label.localeCompare(b.label)),
                        value: valueRef(data, 'brigadier_id')
                    })
                ),
                r('.mt-16'),
                r('.sticky.bottom-0.pt-12.bg-blue-50',  { style: { width: '1200px' } },
                    r('.bg-green-600.p-6.text-center.cursor-pointer.text-md.font-bold.uppercase.text-white',
                        { style: { width: '600px' }, onClick: applyChanges },
                        'Создать бригаду'
                    )
                )
            )
        )
    }
    return { params, render };
}
