import {r, redraw} from '../../common/vdom/index.js';
import {Icon, Input, Button, Alert, Pagination, Table} from '../index.js';
import {getAlarmsReport, getAlarmsReportXLSX} from '../../api/reports.js';
import {employeesData} from '../../pages/employees/index.js';
import {formatDateYYYYMMDD, valueRef} from '../../utils.js';

const localTime = (str) => {
    let [h, m, s] = str.split(':').map(v => Number(v));
    h = (h - Math.floor(new Date().getTimezoneOffset() / 60)) % 24;
    return [h, m, s].map(v => String(v).padStart(2, '0')).join(':');
};

const localDate = (datestr, timestr) => {
    let [d, m, y] = datestr.split('.').map(v => Number(v));
    const h1 = +timestr.split(':')[0], h2 = +localTime(timestr).split(':')[0];
    if (h2 < h1) d += 1;
    return [d, m, y].map(v => String(v).padStart(2, '0')).join('.');
};

export default function ReportTable() {
    let data = [];
    const filters = {count: 10};

    const alert = Alert(
        (res, t) => r('.flex.flex-col', {style: {width: '300px'}},
            t === 'filters'
                ? [
                    r('', 'Необходимо Выбрать:'),
                    !filters.begin_date && r('', ' - Дата формирования "с"'),
                    !filters.end_date && r('', ' - Дата формирования "по"'),
                    !filters.employee && r('', ' - Сотрудника'),
                ]
                : r('', String(t))
        )
    );

    const columnFilters = {};
    const table = Table({
        columnFilters,
        pagination: _ => filters.count,
        rows: () => data,
        columns: ['date', 'name', 'started_at', 'ended_at', 'duration'],
        columnWidth: (column) => column === 'name' ? 64 : 26,
        columnAlign: (column) => column === 'ended_at' ? 'center' : 'left',
        columnSeparator: (column) => ['name', 'started_at', 'ended_at', 'duration'].includes(column),
        columnName: (column) => ({
            date: 'Дата',
            name: 'Название аларма',
            started_at: 'Время начала',
            ended_at: 'Время конца',
            duration: 'Итоговое время'
        })[column],
        columnFilterInput: (column) => ({
            date: Table.columnFilterInputs.date(),
            name: Table.columnFilterInputs.text,
            started_at: Table.columnFilterInputs.time,
            ended_at: Table.columnFilterInputs.time,
            duration: Table.columnFilterInputs.time,
        })[column],
        columnFilterFunction: (column) => ({
            date: Table.columnFilterFunctions.date,
            name: Table.columnFilterFunctions.text,
            started_at: Table.columnFilterFunctions.time(true),
            ended_at: Table.columnFilterFunctions.time(true),
            duration: Table.columnFilterFunctions.time(),
        })[column],
        cellText: (row, column) => ({
            date: localDate(row.date, row.started_at),
            name: row.alarm_name,
            started_at: localTime(row.started_at),
            ended_at: localTime(row.ended_at),
            duration: row.duration
        })[column]
    })

    function Filter() {
        return r('.flex.flex-row.items-center.mt-4',
            r('.flex.flex-row.items-center.bg-gray-100.border-2.border-gray-300.rounded-full.relative',
                r('.mr-4.pl-4.leading-8', 'c'),
                r('input', {
                    type: 'date',
                    value: filters.begin_date ? formatDateYYYYMMDD(filters.begin_date) : '',
                    onChange(e) {
                        filters.begin_date = formatDateYYYYMMDD(e.target.value);
                        redraw();
                    }
                }),
                r('.flex.items-center.justify-center.pl-2.pr-2.ml-1.mr-1.leading-8.rounded-full.cursor-pointer.hover:bg-gray-300',
                    {
                        style: {width: '24px', height: '24px'}, onClick() {
                            filters.begin_date = undefined;
                            redraw();
                        }
                    },
                    Icon('close', 'black', 2),
                )
            ),
            r('.ml-4'),
            r('.flex.flex-row.items-center.bg-gray-100.border-2.border-gray-300.rounded-full.relative',
                r('.mr-4.pl-4.leading-8', 'по'),
                r('input', {
                    type: 'date',
                    value: filters.end_date ? formatDateYYYYMMDD(filters.end_date) : '',
                    onChange(e) {
                        filters.end_date = formatDateYYYYMMDD(e.target.value);
                        redraw();
                    }
                }),
                r('.flex.items-center.justify-center.pl-2.pr-2.ml-1.mr-1.leading-8.rounded-full.cursor-pointer.hover:bg-gray-300',
                    {
                        style: {width: '24px', height: '24px'}, onClick() {
                            filters.end_date = undefined;
                            redraw();
                        }
                    },
                    Icon('close', 'black', 2),
                )
            ),
            r('.ml-4'),
            r('.flex.flex-row.items-center.bg-gray-100.border-2.border-gray-300.rounded-full.relative',
                r('.mr-4.pl-4', 'Сотрудник'),
                Input(undefined, {
                    value: v => v !== undefined ? (filters.employee = v) : filters.employee,
                    search: true,
                    border: false,
                    list: [{value: null, label: 'Не выбран'}].concat(
                        employeesData.data()
                            .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
                            .map(v => ({value: v.id, label: v.name}))
                    )
                }),
                r('.flex.items-center.justify-center.pl-2.pr-2.ml-1.mr-1.leading-8.rounded-full.cursor-pointer.hover:bg-gray-300',
                    {
                        style: {width: '24px', height: '24px'}, onClick() {
                            filters.employee = undefined;
                            redraw();
                        }
                    },
                    Icon('close', 'black', 2),
                )
            ),
            r('.ml-8'),
            r('.flex.flex-row.items-center.bg-gray-100.border-2.border-gray-300.rounded-full.relative',
                r('.mr-4.pl-4', 'Количество'),
                [10, 20, 50].map(count =>
                    r('.pl-2.pr-2.ml-1.leading-8.rounded-full.cursor-pointer' +
                        (filters.count == count ? '.bg-gray-300' : '.hover:bg-gray-300'),
                        {
                            onClick: () => {
                                filters.count = count;
                                redraw();
                            }
                        },
                        count
                    )
                )
            ),
            r('.ml-auto'),
            r('.flex.flex-row.text-md',
                'Результатов: ',
                r('.ml-1.text-green-600', table.v.rows.length)
            )
        )
    }

    function render() {
        table.recalc();
        employeesData.init();

        return r('.flex.flex-col',
            Filter(),
            r('.border-t.mt-8'),
            r('.overflow-hidden.overflow-x-auto',
                filters.loading ? r('.mt-4.flex.justify-center.text-center', 'Загрузка данных\nожидайте...')
                    : table.render(),
            ),
            r('.mt-8'),
            alert.render(),
            r('.flex.flex-row',
                table.pagination.render(),
                r('.flex-1'),
                Button('Сформировать отчет', _ => {
                    if (!filters.begin_date || !filters.end_date || !filters.employee) alert.show('filters');
                    else {
                        filters.page = 0;
                        filters.loading = true;
                        redraw();
                        getAlarmsReport({
                            begin_date: filters.begin_date,
                            end_date: filters.end_date,
                            employee_id: filters.employee
                        }).catch(alert.show)
                            .then(res => {
                                data = res?.data || [];
                            }).finally(_ => {
                            filters.loading = false;
                            for (const k in columnFilters) delete columnFilters[k];
                            redraw();
                        })
                    }
                }, {disabled: filters.loading}),
                r('.ml-2'),
                Button('Скачать XLSX', _ => {
                    if (!filters.begin_date || !filters.end_date || !filters.employee) alert.show('filters');
                    else {
                        getAlarmsReportXLSX({
                            begin_date: filters.begin_date,
                            end_date: filters.end_date,
                            employee_id: filters.employee
                        }).catch(alert.show)
                    }
                }, {disabled: filters.loading})
            )
        )
    }

    return {render}
}
