import { throttle as throttle_fn } from '../../../common/utils/func.js';
import { withAttrs } from '../utils.js';

export default function Draggable(arg={}) {
    const {
        value = { get: () => 0, set: v => {} },
        scale = { get: () => 1, set: v => {} },
        onstart = () => {},
        onend = () => {},
        distance = 'x',
        throttle = 20,
        redraw = () => m.redraw(),
        container = dom => document.body
    } = arg;
    
    let mdown = false, mmoved = false, parent, valueAnchor = 0, positionAnchor = 0;
    
    function calcDistance(elem, event) {
        if (distance == 'x') return event.clientX - elem.getBoundingClientRect().x;
        if (distance == 'y') return event.clientY - elem.getBoundingClientRect().y;
    }
    
    let cancelParent;
    function setParent(parent1) {
        parent = parent1;
        function onmouseup() {
            if (!mdown) return;
            if (mmoved && mdown) onend();
            mdown = false;
            m.redraw();
        }
        function onmousemove(e) {
            if (!mdown) return;
            mmoved = true;
            const d = calcDistance(this, e) - positionAnchor;
            const pos = valueAnchor + d / scale.get();
            value.set(pos);
            redraw();
        }
        if (throttle) onmousemove = throttle_fn(throttle, onmousemove);
        document.body.addEventListener('mouseup', onmouseup);
        parent.addEventListener('mousemove', onmousemove);
        cancelParent = () => {
            document.body.removeEventListener('mouseup', onmouseup);
            parent.removeEventListener('mousemove', onmousemove);
        }
    }
    
    function onmousedown(e) {
        mdown = true;
        mmoved = false;
        onstart();
        valueAnchor = value.get();
        positionAnchor = calcDistance(parent, e);
        e.stopPropagation();
    }
    
    function oncreate(vnode) {
        setParent(container(vnode.dom));
    }
    function onupdate(vnode) {
        if (parent != container(vnode.dom)) {
            if (cancelParent) cancelParent();
            setParent(container(vnode.dom));
        }
    }

    function render(item) {
        return withAttrs({ oncreate, onupdate, onmousedown }, item);
    }
    
    return { render }
}
