import {API_HOST} from '../config.js';
import {getToken} from './login.js';

const UTCDate = date => new Date(new Date(date).toLocaleDateString('en-US')).toISOString();
const UTCDateFormat2 = date => new Date(date).toISOString()

function addDay(date) {
    date = new Date(date);
    date.setDate(date.getDate() + 1);
    return new Date(+date - 1000);
}

function addDayFormat2(date) {
    date = new Date(date);
    date.setDate(date.getDate() + 1);
    return new Date(+date);
}

export function getEmployeesReport({begin_date, end_date, employee_id}) {

    return new Promise((resolve, reject) => {
        fetch(`${API_HOST}/report/employees_report`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                begin_date: UTCDate(begin_date),
                end_date: UTCDate(addDay(end_date)),
                employee_id
            })
        }).then(response => {
            if (!response.ok) throw new Error('Ошибка получения данных. Получен статус кода ' + response.status);
            return response.json()
        }).then(resolve).catch(reject)
    });
}

export function getEmployeesReportXLSX({begin_date, end_date, employee_id}) {
    return new Promise((resolve, reject) => {
        fetch(`${API_HOST}/report/employees_report.xlsx`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                begin_date: UTCDate(begin_date),
                end_date: UTCDate(addDay(end_date)),
                employee_id
            })
        }).then(response => {

            if (!response.ok) throw new Error('Ошибка получения отчета. Получен статус кода ' + response.status);
            return response.blob()
        }).then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `Отчет по сотрудникам Формат 1 от ${new Date()}.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        }).then(resolve).catch(reject)
    });
}

export function getEmployeesReportFormat2({begin_date, end_date, employee_id}) {
    return new Promise((resolve, reject) => {
        fetch(`${API_HOST}/report/employees_report_v2`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                begin_date: UTCDateFormat2(begin_date),
                end_date: UTCDateFormat2(addDayFormat2(end_date)),
                employee_id
            })
        }).then(response => {
            if (!response.ok) throw new Error('Ошибка получения данных. Получен статус кода ' + response.status);
            return response.json()
        }).then(resolve).catch(reject)
    });
}

export function getEmployeesReportFormat2tXLSX({begin_date, end_date, employee_id}) {
    return new Promise((resolve, reject) => {
        fetch(`${API_HOST}/report/employees_report_v2.xlsx`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                begin_date: UTCDateFormat2(begin_date),
                end_date: UTCDateFormat2(addDayFormat2(end_date)),
                employee_id
            })
        }).then(response => {

            if (!response.ok) throw new Error('Ошибка получения отчета. Получен статус кода ' + response.status);
            return response.blob()
        }).then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `Отчет по сотрудникам Формат 2 от ${new Date()}.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        }).then(resolve).catch(reject)
    });
}

export function getAlarmsReport({begin_date, end_date, employee_id}) {
    return new Promise((resolve, reject) => {
        fetch(`${API_HOST}/report/alarms_report`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                begin_date: UTCDate(begin_date),
                end_date: UTCDate(addDay(end_date)),
                employee_id
            })
        }).then(response => {
            if (!response.ok) throw new Error('Ошибка получения данных. Получен статус кода ' + response.status);
            return response.json()
        }).then(resolve).catch(reject)
    });
}

export function getAlarmsReportXLSX({begin_date, end_date, employee_id}) {
    return new Promise((resolve, reject) => {
        fetch(`${API_HOST}/report/alarms_report.xlsx`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                begin_date: UTCDate(begin_date),
                end_date: UTCDate(addDay(end_date)),
                employee_id
            })
        }).then(response => {
            if (!response.ok) throw new Error('Ошибка получения отчета. Получен статус кода ' + response.status);
            return response.blob()
        }).then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `Отчет по алармам от ${new Date()}.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        }).then(resolve).catch(reject)
    });
}

export function getLogsReport({begin_date, end_date}) {
    return new Promise((resolve, reject) => {
        fetch(`${API_HOST}/report/logs`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                begin_date: UTCDate(begin_date),
                end_date: UTCDate(addDay(end_date)),
            })
        }).then(response => {
            if (!response.ok) throw new Error('Ошибка получения данных. Получен статус кода ' + response.status);
            return response.json()
        }).then(resolve).catch(reject)
    });
}

export function getLogsReportXLSX({begin_date, end_date}) {
    return new Promise((resolve, reject) => {
        fetch(`${API_HOST}/report/logs.xlsx`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                begin_date: UTCDate(begin_date),
                end_date: UTCDate(addDay(end_date)),
            })
        }).then(response => {
            if (!response.ok) throw new Error('Ошибка получения отчета. Получен статус кода ' + response.status);
            return response.blob()
        }).then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `Журнал событий от ${new Date()}.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        }).then(resolve).catch(reject)
    });
}

export function getTelemetryReport({begin_date, end_date, employee_id}) {
    return new Promise((resolve, reject) => {
        fetch(`${API_HOST}/telemetry_data/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                begin_datetime: UTCDate(begin_date),
                end_datetime: UTCDate(addDay(end_date)),
                employee_id
            })
        }).then(response => {
            if (!response.ok) throw new Error('Ошибка получения данных. Получен статус кода ' + response.status);
            return response.json()
        }).then(resolve).catch(reject)
    });
}

export function getTelemetryReportXLSX({begin_date, end_date, employee_id}) {
    return new Promise((resolve, reject) => {
        fetch(`${API_HOST}/report/telemetry_report.xlsx`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                begin_datetime: UTCDate(begin_date),
                end_datetime: UTCDate(addDay(end_date)),
                employee_id
            })
        }).then(response => {
            if (!response.ok) throw new Error('Ошибка получения отчета. Получен статус кода ' + response.status);
            return response.blob()
        }).then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `Журнал телеметрии от ${new Date()}.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        }).then(resolve).catch(reject)
    });
}

export function getStatusBleLabelsReport({ble_id}) {
    return new Promise((resolve, reject) => {
        fetch(`${API_HOST}/report/ble_log_report`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ble_id
            })
        }).then(response => {
            if (!response.ok) throw new Error('Ошибка получения данных. Получен статус кода ' + response.status);
            return response.json()
        }).then(resolve).catch(reject)
    });
}

export function getStatusBleLabelsReportXLSX({ble_id}) {
    return new Promise((resolve, reject) => {
        fetch(`${API_HOST}/report/ble_log_report.xlsx`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ble_id
            })
        }).then(response => {
            if (!response.ok) throw new Error('Ошибка получения отчета. Получен статус кода ' + response.status);
            return response.blob()
        }).then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `Журнал отчета по статусу BLE меток от ${new Date()}.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        }).then(resolve).catch(reject)
    });
}