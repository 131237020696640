import { r, redraw } from '../../common/vdom/index.js';
import apiLogin, { getToken, setToken, currentUser } from '../../api/login.js';

const value = v => function(vv) { return arguments.length == 0 ? v : v = vv; }
const valueRef = (obj, k) => function(vv) { return arguments.length == 0 ? obj[k] : obj[k] = vv; }

export default function AuthPage(arg={}) {
    const {
        onlogin = (data) => {}
    } = arg;
    
    const authTabs = ['Вход', /*'Регистрация'*/];
    let authTab = 0;
    
    let errors = { username: '', password: '', summary: '' };
    const loginData = { username: '', password: '' };
    const registerData = { tel: '', email: '', firstname: '', secondname: '', dob: '', password: '', passwordRepeat: '', acceptRules: false };
    
    const fmtErrorMsg = err => !err ? '' : 'Неправильный логин или пароль';
    
    if (getToken()) (async () => {
        let user;
        try { user = await currentUser(); }
        catch (e) { user = null; }
        //console.log({user});
        if (!user || !(user+'') || user.detail) { setToken(''); document.location.reload(); }
        else {
            // Для тех кто привязан по onToken сигналирование
            setToken(getToken());
            onlogin(user);
        }
    })();
    
    async function login(data=loginData) {
        const res = await apiLogin(data);
        if (res.detail) {
            if (Array.isArray(res.detail)) {
                res.detail.forEach(e => { errors[e.loc[1]] = e.msg; });
            } else {
                errors.summary = res.detail;
            }
            redraw();
        }
        if (res.access_token) {
            loginData.username = loginData.password = '';
            setToken(res.access_token);
            onlogin(await currentUser());
        }
    }
    
    function render() {
        function Auth() {
            function Logo() {
                const style = {
                    backgroundImage: 'url(/assets/icons/logo-smart-team-1.svg)',
                    backgroundSize: "contain", backgroundRepeat: 'no-repeat'
                };
                return r('.h-28.w-20.ml-auto.mb-10.flex-shrink-0', { style });
            }
            
            function Tabs() {
                return r('.flex.flex-row.justify-between',
                    authTabs.map((tab, i) => r('.text-md.cursor-pointer.uppercase.font-bold.select-none' + (authTab == i ? '' : '.text-gray-400'),
                        { onClick() { authTab = i; redraw(); } },
                        tab
                    ))
                );
            }
            
            function Input(label, { type, value, required, onKeyPress }) {
                const valid = () => value().includes('c');
                return r('.flex.flex-col' + (required ? '.text-black' : '.text-gray-400'),
                    r('.text-sm.select-none', label + (required && !value() ? ' *' : '')),
                    r('input.outline-none.leading-6.text-md' +
                      (required ? '.border-black.border-b-2' : '.border-gray-600.border-b') +
                      '',//(!value() ? '' : (valid() ? '.border-green-600' : '.border-red-600')),
                        {
                          type,
                          //placeholder: !value() ? label + (required ? ' *' : '') : '',
                          value: value() || '',
                          onKeyPress: onKeyPress,
                          onChange(e) {
                              value(e.target.value);
                              redraw();
                          }
                        }
                    )
                );
            }
            
            function Checkbox(text, value) {
                return r('.flex.flex-row.text-xs.select-none.cursor-pointer',
                    { onClick: () => { value(!value()); redraw(); } },
                    r('.w-6.h-6.border-2.border-gray-400.flex-shrink-0.mr-3', {
                        style: {
                            backgroundImage: value() ? `url("/assets/icons/icon-check.svg")` : '',
                            backgroundSize: '16px 16px',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center'
                        }
                    }),
                    text
                )
            }
            
            function ButtonSmall(text, onClick) {
                return r('.text-xs.w-min.whitespace-nowrap.ml-auto.mr-auto.border-b-2.border-gray-400.uppercase.font-bold.text-gray-400.cursor-pointer.select-none', { onClick }, text);
            }
            
            function ButtonBig(text, onClick) {
                return r('.text-md.w-full.bg-green-600.p-4.text-center.text-white.cursor-pointer.select-none', { onClick }, text);
            }
            
            return r('.h-full.p-16.overflow-auto', { style: { width: '420px' } },
                Logo(),
                Tabs(),
                r('.mt-4'),
                ...([
                [
                    Input('Имя пользователя', {
                        onKeyPress: (e) => {
                            errors.username = '';
                            errors.summary = '';
                            e.key == 'Enter' && login();
                        },
                        value: valueRef(loginData, 'username'),
                        required: true
                    }),
                    r('.text-lg .text-red-500', fmtErrorMsg(errors.username)),
                    r('.mt-4'),
                    Input('Пароль', {
                        onKeyPress: (e) => {
                            errors.password = '';
                            errors.summary = '';
                            e.key == 'Enter' && login();
                        },
                        value: valueRef(loginData, 'password'),
                        required: true,
                        type: 'password'
                    }),
                    r('.text-lg .text-red-500', fmtErrorMsg(errors.password)),
                    //r('.mt-8'),
                    //ButtonSmall('Восстановить пароль', () => {}),
                    r('.mt-8'),
                    r('.text-lg .text-red-500', fmtErrorMsg(errors.summary)),
                    r('.mt-4'),
                    ButtonBig('Войти', () => login()),
                    //r('.mt-8'),
                    //ButtonSmall('Зарегистрироваться', () => { authTab = 1; redraw(); })
                ],
                [
                    Input('Телефон', {
                        value: valueRef(registerData, 'tel'),
                        required: true
                    }),
                    r('.mt-4'),
                    Input('E-mail', {
                        value: valueRef(registerData, 'email'),
                        required: true
                    }),
                    r('.mt-4'),
                    Input('Фамилия', {
                        value: valueRef(registerData, 'secondname'),
                        required: true
                    }),
                    r('.mt-4'),
                    Input('Имя', {
                        value: valueRef(registerData, 'firstname'),
                        required: true
                    }),
                    r('.mt-4'),
                    Input('Дата рождения', {
                        value: valueRef(registerData, 'dob'),
                        required: false,
                        type: 'date'
                    }),
                    
                    r('.mt-8'),
                    Input('Пароль', {
                        value: valueRef(registerData, 'password'),
                        required: true,
                        type: 'password'
                    }),
                    r('.mt-4'),
                    Input('Повторите пароль', {
                        value: valueRef(registerData, 'passwordRepeat'),
                        required: true,
                        type: 'password'
                    }),
                    r('.mt-8'),
                    Checkbox('Я принимаю условия конфиденциальности', valueRef(registerData, 'acceptRules')),
                    r('.mt-8'),
                    ButtonBig('Зарегистрироваться', () => {}),
                    r('.mt-8'),
                    ButtonSmall('У меня есть аккаунт', () => { authTab = 0; redraw(); })
                ]
                ][authTab])
            )
        }
        
        function Bg() {
            const style = {
                backgroundImage: 'url(/assets/bg1.png)',
                backgroundSize: "cover", backgroundRepeat: 'no-repeat'
            };
            return r('.h-full.flex-1.bg-gray-200', { style });
        }
        
        return r('.flex.flex-row.h-full',
            Auth(),
            Bg()
        )
    }
    return { render };
}
