import { API_HOST } from '../config.js';

function eventSource() {
    const handlers = new Set();
    return {
        emit(...args) {
            handlers.forEach(f => f(...args));
        },
        bind(f) {
            handlers.add(f)
        }
    }
}

const tokenEvent = eventSource();
export const onToken = tokenEvent.bind;
export function setToken(v) { sessionStorage.token = v; tokenEvent.emit(); }
export function getToken() { return sessionStorage.token; }
export function currentUser() {
    return new Promise((resolve, reject) => {
        fetch(`${API_HOST}/user/me/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                "Content-Type": "application/json",
            }
        }).then(res => res.json()).then(resolve).catch(reject)
    });
}

export default function({ username, password }) {
    return new Promise((resolve, reject) => {
        fetch(`${API_HOST}/login/`, {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ username, password }),
        }).then(res => res.json()).then(resolve).catch(reject)
    });
}
