import { Labelled } from '../components.js';

export default function Input(arg={}) {
    let {
        get = () => '',
        set = v => {},
        attrs = {}
    } = arg;  
    
    return m('input.height1.b1-lighter.l2.c1.p1.border-c1.border-w1.rad1', {
        ...attrs,
        value: get(),
        oninput() {
            set(this.value);
            m.redraw();
        }
    });
}

Input.DateRange = function(arg={}) {
    const {
        type = 'date',
        get = () => [new Date(), new Date()],
        set = ([d1, d2]) => {},
        attrs = {}
    } = arg;  
    
    const dt = (i) => get()?.[i] || new Date();
    return m('.col.p2.b1.sh2',
        Labelled('Начало', Input({
            get: () => dt(0),
            set: (v) => set([v, dt(1)]),
            attrs: { type, placeholder: "from" }
        })),
        m('.mt2'),
        Labelled('Конец', Input({
            get: () => dt(1),
            set: (v) => set([dt(0), v]),
            attrs: { type, placeholder: "from" }
        }))
    )
}
