import browserDetect from './browserDetect.js';

let baseWidth = window.innerWidth;

function scaleFactor() { return Math.min(1, window.innerWidth / baseWidth); }

const getBoundingClientRect = Element.prototype.getBoundingClientRect;
Element.prototype.getBoundingClientRect = function() {
    const r = getBoundingClientRect.call(this, arguments), d = scaleFactor();
    if (browserDetect().isFirefox) {
        //
    } else {
        r.x *= d;
        r.y *= d;
        r.width *= d;
        r.height *= d;
    }
    return r;
}

export function setBaseWidth(baseWidth1) { baseWidth = baseWidth1; }

export default function scaleApp(container) {
    const d = scaleFactor();
    Object.assign(container.style, {
        posititon: 'relative',
        width: `calc(max(${100 / d}vw, 100%))`,
        height: `calc(${100 / d}vh)`,
        zoom: `${d * 100}%`,
        MozTransform: `scale(${d})`,
        MozTransformOrigin: '0 0'
    });
}
