import { r, redraw } from '../../common/vdom/index.js';
import { once } from '../../common/utils/func.js';
import { Icon, Alert, Button, Panels, Table } from '../../components/index.js';
import { withAttrs } from '../../components/utils.js';
import { valueRef, value } from '../../utils.js';
import * as api from '../../api/index.js';

const formatDate = (date, locale='ru-RU') => new Date(date).toLocaleTimeString(locale, {
    year: 'numeric', month: 'long', day: 'numeric',
    hour: '2-digit', minute: '2-digit', second: '2-digit'
});

export default function({ addBrigade, editBrigade }) {
    const filters = { type: 'Все', count: 10, page: 0 };
    
    let brigades = [];
    const table = Table({
        pagination: () => filters.count,
        rows: () => brigades,
        sortColumn: value('end_date'),
        sortDir: value(-1),
        columns: ['id', 'name', 'brigadier.name', 'brigadier.personnel_id', 'begin_date', 'end_date'],
        columnWidth: (column) => column == 'id' ? 24 : 64,
        columnAlign: (column) => 'left',
        columnSeparator: (column) => ['brigadier.name', 'begin_date'].includes(column),
        columnName: (column) => ({
            id: 'ID',
            name: 'Название бригады',
            'brigadier.name': 'ФИО бригадира',
            'brigadier.personnel_id': 'Табельный номер бригадира',
            begin_date: 'Дата формирования бригады',
            end_date: 'Дата расформирования бригады'
        })[column],
        columnFilterInput: (column) => ({
            id: Table.columnFilterInputs.number,
            name: Table.columnFilterInputs.text,
            'brigadier.name': Table.columnFilterInputs.text,
            'brigadier.personnel_id': Table.columnFilterInputs.text,
            begin_date: Table.columnFilterInputs.text,
            end_date: Table.columnFilterInputs.text
        })[column],
        columnFilterFunction: (column) => ({
            id: Table.columnFilterFunctions.number,
            name: Table.columnFilterFunctions.text,
            'brigadier.name': Table.columnFilterFunctions.text,
            'brigadier.personnel_id': Table.columnFilterFunctions.text,
            begin_date: Table.columnFilterFunctions.text,
            end_date: Table.columnFilterFunctions.text
        })[column],
        cellSortValue: (row, column) =>
            column == 'id' ? row.id :
            (column === 'begin_date' || column === 'end_date') ? (new Date(row[column])).getTime() || Infinity
            : row[column],
        cellText: (row, column) => ({
            id: row.id,
            name: row.name,
            'brigadier.name': (row.brigadier && `${row.brigadier.surname} ${row.brigadier.name} ${row.brigadier.patronymic}`) || 'Не выбрано',
            'brigadier.personnel_id': row.brigadier?.personnel_id,
            begin_date: row.begin_date && formatDate(row.begin_date),
            end_date: row.end_date && formatDate(row.end_date)
        })[column],
        cellRender: (row, column) => ({
            name: v => r('.cursor-pointer', { onClick: () => editBrigade(row.id) }, v)
        })[column] || (v => v),
        additionalFilter: d => (
            filters.type == 'Все' || 
            (filters.type == 'Активные' && d.end_date == null)
        )
    });
    
    async function onopen() {
        brigades = await api.brigade.get();
    }
    
    function render() {
        //console.log({ brigades });
        return r('.p-16',
            r('.flex.flex-row.items-center', { style: { marginTop: '-32px' } },
                r('.text-xl.font-bold', 'Бригады'),
                r('.ml-8'),
                r('.flex.flex-row.items-center.bg-gray-100.border-2.border-gray-300.rounded-full.relative',
                    r('.mr-4.pl-4', 'Выбор'),
                    ['Все', 'Активные'].map(type =>
                        r('.pl-2.pr-2.ml-1.leading-8.rounded-full.cursor-pointer' +
                          (filters.type == type ? '.bg-gray-300' : '.hover:bg-gray-300'),
                            { onClick: () => { filters.type = type; redraw(); } },
                            type
                        )
                    )
                ),
                r('.ml-8'),
                r('.flex.flex-row.items-center.bg-gray-100.border-2.border-gray-300.rounded-full.relative',
                    r('.mr-4.pl-4', 'Количество'),
                    [10, 20, 50].map(count =>
                        r('.pl-2.pr-2.ml-1.leading-8.rounded-full.cursor-pointer' +
                          (filters.count == count ? '.bg-gray-300' : '.hover:bg-gray-300'),
                            { onClick: () => { filters.count = count; redraw(); } },
                            count
                        )
                    )
                ),
                r('.ml-auto'),
                r('.flex.flex-row.items-center.cursor-pointer', { onClick: () => addBrigade() },
                    Icon('plus', 'green-600', 6),
                    r('.text.text-green-600.uppercase.font-medium.ml-1', 'Добавить бригаду')
                )
            ),
            r('.mt-8'),
            table.render(),
            r('.mt-8'),
            table.pagination.render()
        );
    }
    
    return { onopen, render };
}
