import * as Panels from './Panels.js';
import { ActionHandler, mergeAttrs } from '../utils.js';
import Icon from './Icon.js';
import Input from './Input.js';
import Labelled from './Labelled.js';

const sharedPanel = Panels.Panel({
    content: () => '',
    focusChild_onopen: true
});

let searchVal = '';

export default function Select(arg={}) {
    const {
        attrs = {},
        list = [],
        multiple = false,
        search = false,
        formatOption = i => list[i],
        defaultText = 'Не выбрано',
        
        get = () => 0,
        set = i => {},
        
        // if multiple = true
        select = (i) => {},
        deselect = (i) => {},
        isSelected = i => false,
        
        disablePanel = false 
    } = arg;
    
    function Option(label, i) {
        const selected = multiple ? isSelected(i) : get() == i;
        
        function onclick() {
            if (multiple) {
                (selected ? deselect : select)(i);
            } else {
                set(i);
                sharedPanel.close();
            }
            m.redraw();
        }
        
        const icon = Icon('check', {
            class: '.c2.l3.pl1.pr1',
            style: `visibility: ${multiple && isSelected(i) ? 'visible' : 'hidden'};`
        });
        
        const cls = (
            '.row.baseline.nowrap.rigid.l2.ptr.b1-lighter.hvr-b1-darker.c1.nosel.pt0.pb0.pl2.pr1' +
            (selected ? '.b1-darker' : '')
        );
        return m(cls, {
            ...(selected ? { 'data-focusable': true } : {}),
            ...ActionHandler(onclick)
        },
            formatOption(i),
            icon
        );
    }
    
    function PanelContent() {
        function filter({ label }) {
            return searchVal.length == 0 || (label+'').toLowerCase().includes(searchVal.toLowerCase());
        }
        
        return m('.col.rad2.noscroll.b1-lighter', { style: 'min-width: 200px; max-width: 60vw;' },
            !search ? '' :
            m('.row.pl2.pb1.pt1.pr1.mr3',
                Labelled('Search', Input({
                    get() { return searchVal; },
                    set(v) { searchVal = v; m.redraw(); }
                }), { attrs: { class: '.widthf' }})
            ),
            list.length == 0 ? m('.p1.c1.dim2', 'No options') :
            list
              .map((label, i) => ({ label, i }))
              .filter(filter)
              .map(({ label, i }) => Option(label, i))
        );
    }

    function Label() {
        if (multiple) {
            const vals = list.filter((v, i) => isSelected(i));
            return (
                vals.length == 0 ? defaultText :
                vals.length == 1 ? vals[0] :
                `${vals.length} selected`
            );
        } else {
            const i = get(), v = list[i];
            return (i >= 0 && i < list.length) ? v : defaultText;
        }
    }
    
    function onclick(e) {
        searchVal = '';
        //const r = this.getBoundingClientRect();
        sharedPanel.setContent(() => PanelContent());
        m.redraw();
    }

    function onupdate(vnode) {
        if (sharedPanel.state.anchor == vnode.dom) {
            sharedPanel.setContent(() => PanelContent());
        }
    }

    return (disablePanel ? v => v : sharedPanel.anchorElement)(
        m('.height1.b1-lighter.y-center.row.l2.border-c1.border-w1.p1.c1.nosel.rad1.t-nowrap.t-noov.ptr',
            mergeAttrs(attrs, { style: `max-width: 250px;`, onclick, onupdate }),
            m('.t-noov.f1', Label()),
            Icon('sort-down', { class: '.mla.pl1' }),
        )
    );
}
