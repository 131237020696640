import { r, redraw } from '../../common/vdom/index.js';
import { Icon, Alert, Button, Input } from '../../components/index.js';
import { employee, organization, device, appointment, classifier } from '../../api/index.js';
import { valueRef } from '../../utils.js';

const getData = {
    add: () => ({
        id: 0,
        name: '',
        surname: '',
        patronymic: '',
        phone_number: '',
        attrs: {allowed_height: 50, quite_time: 10, ble_no_time_max: 5, min_battery_charge: 15},
        personnel_id: '',
        tb_customer_id: ''
    }),
    
    edit: async (id) => ({
        ...getData.add(),
        ...(await employee.get(id))
    })
};

export default function(arg={}) {
    const { mode = 'edit', onFinish } = arg;
    
    let id;
    let data, loading;
    let employees = [];
    let devices = [], device_id;
    let appointments, classifiers;
    async function loadData() {
        const classifiersById = {};
        classifiers = (await classifier.get());
        classifiers.forEach(c => classifiersById[c.id] = c);

        appointments = (await appointment.get());
        appointments = appointments.sort((a, b) => a.parent_id - b.parent_id);

        const groups = {};
        const appointmentsById = {};
        appointments.forEach(a => {
            groups[a.classifier_id] ??= {};
            groups[a.classifier_id][a.parent_id] ??= [];
            groups[a.classifier_id][a.parent_id].push(a);

            appointmentsById[a.id] = a;
            if (a.parent_id == null) a.indent = 0;
            else a.indent = appointmentsById[a.parent_id].indent + 1;
        });

        appointments = [];
        function childrenOf(cls_id, parentId) {
            return (groups[cls_id][parentId] || []).sort((a, b) => a.name.localeCompare(b.name)).forEach(v => {
                const label = v?.name + (v?.appointment_code ? ' (' + v?.appointment_code + ')' : '');
                const list_label = (v.indent > 0 ? '—'.repeat(v.indent) + ' ' : '') + label;
                appointments.push({ value: v.id, list_label, label });
                childrenOf(cls_id, v.id);
            });
        }
        for (const classifier_id in groups) {
            appointments.push({ list_label: classifiersById[classifier_id].name });
            childrenOf(classifier_id, null);
        }
        
        devices = (await device.get()).filter(v => v.device_type != 'ble');
        employees = await employee.get();
        loading = true;
        device_id = devices.find(d => d.employee_id && d.employee_id == id)?.id;
        data = await getData[mode](id);
        data.id = id;
        loading = false;
        redraw();
    }
    
    function params(p) {
        id = p.id;
        data = null;
        device_id = null;
    }
    
    const requiredAlert = Alert(
        () => r('.flex.flex-col', { style: { width: '300px' } },
            r('', 'Необходимо для заполнения:'),
            !data.name && r('', ' - Имя'),
            !data.surname && r('', ' - Фамилия'),
            !data.patronymic && r('', ' - Отчество'),
            !data.personnel_id && r('', ' - Табельный номер'),
            !device_id && r('', ' - Устройство')
        )
    );
    
    const deviceTakenAlert = Alert(
        (confirm, oldDevice, oldEmployee, newEmployee) => r('',
            r('.flex.flex-col.whitespace-pre-wrap', { style: { width: '450px' } },
                r('',
                    'Устройство ',
                    r('.font-bold.inline', (oldDevice?.name || '').slice(-8)), ' привязано к сотруднику ',
                    r('.font-bold.inline', `${oldEmployee?.surname} ${oldEmployee?.name} ${oldEmployee?.patronymic}`),
                ),
                r('.mt-4', `Привязать к этому сотруднику?`),
                r('.font-bold', `${newEmployee?.surname} ${newEmployee?.name} ${newEmployee?.patronymic}`),
                r('.flex.flex-row.mt-8',
                    Button('Подтвердить', () => confirm(true), { color: 'blue' }),
                    r('.mr-4'),
                    Button('Отменить', () => confirm(false), { color: 'red' })
                )
            )
        )
    );
    
    const personnelIdTakenAlert = Alert(
        (_, personnel_id) => r('',
            r('.flex.flex-col.whitespace-pre-wrap', { style: { width: '400px' } },
                `Табельный номер ${personnel_id} уже присвоен другому сотруднику`
            )
        )
    );

    const alert = Alert(
        (confirm, employee={}) => r('.flex-col',
            r('', 'Удалить сотрудника?'),
            r('.text-sm.font-bold.mt-2', `${employee.surname} ${employee.name} ${employee.patronymic}`),
            r('.flex.flex-row.mt-8',
                Button('Подтвердить', () => confirm(true), { color: 'blue' }),
                r('.mr-4'),
                Button('Отменить', () => confirm(false), { color: 'red' })
            )
        )
    );
    
    let applying;
    async function applyChanges(action='save') {
        // Если мы удаляем пользователя тогда не надо ничего проверять
        if (mode == 'edit' && action == 'delete') {
            const employeeToDelete = await employee.get(id);
            if (!(await alert.show(employeeToDelete))) return;
            
            await employee.delete(id);
            return onFinish();
        }

        if (!data.name || !data.patronymic || !data.surname)
            return requiredAlert.show();
        if (employees.find(e => e.id != id && e.personnel_id == data.personnel_id))
            return personnelIdTakenAlert.show(data.personnel_id);
        
        const newDevice = devices.find(d => d.id == device_id);
        const newEmployee = data;
        
        const oldDevice = devices.find(d => d.employee_id == id);
        const oldEmployee = employees.find(e => e.id == newDevice?.employee_id);
        
        //console.log({ oldEmployee, newEmployee });
        if (oldEmployee && oldEmployee?.id != newEmployee?.id) {
            const confirm = await deviceTakenAlert.show(newDevice, oldEmployee, newEmployee);
            deviceTakenAlert.hide();
            if (!confirm) return;
        }

        if (applying) return;
        applying = true;
        
        if (mode == 'add') {
            const newEmployee = await employee.create(data);
            if (newDevice) await device.update(newDevice.id, { ...newDevice, employee_id: newEmployee.id});
        }
        if (mode == 'edit' && id) {
            await employee.update(id, data);
            if (oldDevice) await device.update(oldDevice.id, { ...oldDevice, employee_id: null });
            if (newDevice) await device.update(newDevice.id, { ...newDevice, employee_id: id });
        }
        
        applying = false;
        onFinish();
    }
    
    function render() {
        if (!data && !loading) { loadData(); return; }
        function Photo() {
            return r('.flex.flex-col',
                /*r('.w-36.h-36.rounded-full.border-2.border-green-600',
                    {
                        style: {
                            backgroundImage: `url(/assets/ava3.png)`,
                            backgroundSize: 'cover'
                        }
                    },
                ),*/
                r('.flex.flex-row.items-center.justify-center.w-36.h-36.rounded-full.border-2.border-dotted.border-green-600.bg-white',
                    Icon('camera', 'gray-600', [6, 5]),
                ),
                ({
                add: [
                    r('.mt-4'),
                    r('.flex.flex-row.justify-center',
                        Icon('camera', 'green-500', [6, 5]),
                        r('.text-md.font-medium.text-green-500.ml-2', 'Загрузить'),
                    )
                ],
                edit: [
                    r('.mt-4'),
                    r('.flex.flex-row.justify-center.items-center',
                        Icon('camera', 'green-500', 6),
                        r('.text-xs.text-green-500.ml-2.uppercase.font-bold', 'Изменить'),
                    ),
                    r('.mt-2'),
                    r('.flex.flex-row.justify-center.items-center',
                        Icon('delete', 'red-400', 6),
                        r('.text-xs.text-red-400.ml-2.uppercase.font-bold', 'Удалить'),
                    )
                ]
                })[mode]
            )
        }
        
        function Form() {
            return r('.flex.flex-col.text-gray-900.w-full',
                r('.text-2xl.font-bold.mb-4', 'Общие данные'),
                r('.grid.grid-cols-2.gap-8.mt-6',
                    Input('Фамилия', {
                        required: true,
                        value: valueRef(data, 'surname')
                    }),
                    Input('Имя', {
                        required: true,
                        value: valueRef(data, 'name')
                    })
                ),
                r('.grid.grid-cols-2.gap-8.mt-6',
                    Input('Отчество', {
                        required: true,
                        value: valueRef(data, 'patronymic')
                    }),
                    Input('Телефон', {
                        value: valueRef(data, 'phone_number')
                    })
                ),
                // r('.grid.grid-cols-4.gap-8.mt-6',
                //     Input('Семейное положение', {
                //         value: valueRef(data, '_0'),
                //         attrs: { className: 'col-span-2' }
                //     }),
                //     Input('Пол', {
                //         value: valueRef(data, '_1'),
                //         list: [
                //             { value: 'Мужской' },
                //             { value: 'Женский' },
                //         ]
                //     }),
                //     Input('Дата рождения', {
                //         value: valueRef(data, '_2')
                //     })
                // ),
                
                r('.mt-16'),
                r('.text-2xl.font-bold', 'Место работы'),
                // r('.grid.grid-cols-1.gap-8.mt-6',
                //     Input('Название организации', {
                //         value: valueRef(data, '_3')
                //     })
                // ),
                // r('.grid.grid-cols-1.gap-8.mt-6',
                //     Input('Полный адрес организации', {
                //         value: valueRef(data, '_4')
                //     })
                // ),
                r('.grid.grid-cols-2.gap-8.mt-6',
                    Input('Табельный номер', {
                        value: valueRef(data, 'personnel_id')
                    }),
                    Input('Специальность', {
                        required: true,
                        value: valueRef(data, 'appointment_id'),
                        search: true,
                        list: appointments
                    })
                ),
                r('.mt-16'),
                r('.text-2xl.font-bold', 'Допуск'),
                r('.grid.grid-cols-1.gap-8.mt-6',
                    Input('Устройство', {
                        value: v => v !== undefined ? (device_id = v) : device_id,
                        search: true,
                        list: [{ value: null, label: 'Нет Устройства' }].concat(
                            devices.sort((a, b) => (a.name || '').localeCompare(b.name || ''))
                            .map(v => ({ value: v.id, label: v?.name?.slice(-8) }))
                        )
                    })
                ),
                r('.grid.grid-cols-1.gap-8.mt-6',
                    Input('Высотность', {
                        value: valueRef(data.attrs, 'allowed_height')
                    })
                ),
                // r('.mt-16'),
                // r('.text-2xl.font-bold', 'Место жительства'),
                // r('.grid.grid-cols-1.gap-8.mt-6',
                //     Input('Фактический адрес проживания', {
                //         value: valueRef(data, '_7')
                //     })
                // ),
                // r('.mt-16'),
                // r('.text-2xl.font-bold', 'Документ'),
                // r('.grid.grid-cols-4.gap-8.mt-6',
                //     Input('Паспорт гражданина РФ', {
                //         value: valueRef(data, '_8'),
                //         attrs: { className: 'col-span-2' }
                //     }),
                //     Input('Серия', {
                //         value: valueRef(data, '_9')
                //     }),
                //     Input('Номер', {
                //         value: valueRef(data, '_10')
                //     })
                // ),
                // r('.grid.grid-cols-4.gap-8.mt-6',
                //     Input('Выдан', {
                //         value: valueRef(data, '_11'),
                //         attrs: { className: 'col-span-3' }
                //     }),
                //     Input('Код подразделения', {
                //         value: valueRef(data, '_12')
                //     })
                // ),
                
                r('.mt-64'),
                r('.sticky.bottom-0.pt-12.bg-blue-50',
                    r('.bg-green-600.p-6.text-center.cursor-pointer.text-md.font-bold.uppercase.text-white', { onClick: applyChanges },
                        ({ edit: 'Сохранить изменения', add: 'Добавить сотрудника' })[mode]
                    )
                )
            )
        }
    
        return r('.flex.flex-col.pl-16.pr-16.pt-8.pb-8.bg-blue-50.w-full',
            requiredAlert.render(),
            deviceTakenAlert.render(),
            personnelIdTakenAlert.render(),
            alert.render(),
            r('.text-md.font-medium.text-green-500', 'Персонал /'),
            r('.mt-12'),
            r('.flex.flex-row',
                r('.text-2xl.font-bold.w-80.text-gray-700',
                    ({ edit: 'Редактирование профиля', add: 'Добавление сотрудника' })[mode]
                ),
                r('.ml-auto'),
                mode == 'add' ? '' : r('.flex.flex-row.justify-center.items-center',
                    Icon('delete', 'gray-500', 6),
                    r('.text-xs.text-gray-500.ml-2.uppercase.font-bold.cursor-pointer',
                        { onClick: () => applyChanges('delete') },
                        'Удалить сотрудника'
                    ),
                ),
            ),
            r('.mt-8'),
            r('.flex.flex-row',
                //Photo(),
                //r('.mr-96'),
                Form()
            )
        )
    }
    return { params, render };
}
