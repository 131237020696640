import { throttle, once, callFn } from '../../common/utils/func.js';
import * as rpc from '../../common/utils/rpc-client.js';
import { withAttrs } from '../vdom/utils.js';
import { Dropdown, Input, Table, Icon } from '../vdom/components.js';
import { telemetry, employee, device } from '../../api/index.js';
import { formatDate } from '../../utils.js';

function DataListener({ data }) {
    let employees = [], devices = [];
    
    const cmp = {};
    const str = obj => [
        'ts', 'bleId', 'heartRate', 'skinTemperature', 'step',
        'batteryCapacity', /*'fport', */'rssi', 'snr', 'dr', 'alarm'
    ].map(key => [key, obj[key]].join(':')).join('::');
    
    async function load() {
        employees = [], devices = [];
        
        employees = await employee.get();
        devices = await device.get();
        
        function update() {
            const rows = [];
            for (const device of devices) {
                for (const item of telemetry.getAll(device.id)) {
                    const employee = employees.find(v => v.id == device.employee_id);
                    if (!employee) continue;

                    const str1 = str({
                        ...item,
                        alarm: Object.keys(item.alarm).map(v => v.real_name).sort()
                    });
                    if (cmp[device.id] == str1) { /*console.log('skip', item);*/ continue; }
                    cmp[device.id] = str1;
                    
                    const alarms = (item.alarm.length > 0 ? item.alarm : ['']);
                    for (const alarm of alarms) rows.push({
                        ...item,
                        ts: formatDate(item.ts, true, true),
                        deviceId: device?.name?.slice(-8),
                        personnelId: employee.personnel_id,
                        bleId: item.bleId,
                        personName: employee.surname + ' ' + employee.name + ' ' + employee.patronymic,
                        alarm: alarm.real_name
                    });
                }
            }
            
            data(rows);
        }
        
        for (const device of devices) telemetry.listen(device.id, update);
    }
    
    const init = once(load);
    
    return { init };
}

const filterDateRange = {
    filter: (r, f=[]) => new Date(r) >= new Date(f[0] || r) && new Date(r) <= new Date(f[1] || r),
    input: ({ get, set }) => Dropdown(
        Input({
            get: () => (get()?.[0] && get()?.[1]) && get().map(dt =>
                new Date(dt).toLocaleDateString('EN', { year: 'numeric', month: 'numeric', day: 'numeric' })
            ).join(' - '),
            attrs: { readonly: true, placeholder: 'Date range', class: '.t-center' }
        }),
        Input.DateRange({ type: "datetime-local", get, set })
    )
};

export default function(arg={}) {
    const {
        title = 'Журнал телеметрии'
    } = arg;
    
    let rows = [];
    const dataListener = DataListener({
        data(rows1) { rows = rows1; m.redraw(); }
    });
    
    let personName;
    const filter = {};
    const table = Table({
        filter,
        cols: [
            'ts', 'personName', 'personnelId', 'deviceId',
            'bleId', 'heartRate', 'skinTemperature', 'step',
            'batteryCapacity', /*'fport', */'rssi', 'snr', 'dr', 'alarm'
        ],
        colNames: [
            'Дата получения пакета', 'ФИО сотрудника', 'Табельный номер сотрудника', 'ID устройства',
            'ID BLE метки', 'Пульс', 'Температура', 'Шаги',
            'Заряд батареи %', /*'канал передачи данных', */'RSSI', 'Отношение сигнал/шум', 'Скорость передачи данных', 'Аларм'
        ],
        colWidths: [250, 175, 125, 100, 120, 75, 120, 50, 100, 100, 75, 100, 100, 300],
        filterFunction: [
            null,//filterDateRange.filter,
            null, null, null, null,
            () => true, () => true, () => true, () => true, () => true, () => true, () => true,
            null
        ],
        filterInput: [
            () => m(''),//filterDateRange.input,
            null, null, null, null,
            () => m(''), () => m(''), () => m(''), () => m(''), () => m(''), () => m(''), () => m(''),
            null
        ],
        rows: () => rows.filter(r => r.personName = personName),
        cell({ row, col }) {
            return callFn(row[col]);
        }
    });
    
    function params(p) {
        personName = p.personName;
        m.redraw();
    }
    
    function render() {
        dataListener.init();
        return table.render();
    }
    
    return { title, params, render };
}
