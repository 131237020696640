import {r, redraw} from '../../common/vdom/index.js';
import {Alert, Button, Icon, Input, Table} from '../index.js';
import {getEmployeesReportFormat2, getEmployeesReportFormat2tXLSX} from '../../api/reports.js';
import {formatDateYYYYMMDD, replacingDotWithComma} from '../../utils.js';
import {employeesData} from '../../pages/employees/index.js';

export default function EmployeesReportTableForm2() {
    let data = [];
    const filters = {count: 10, page: 0};

    const alert = Alert(
        (res, t) => r('.flex.flex-col', {style: {width: '300px'}},
            t === 'filters'
                ? [
                    r('', 'Необходимо Выбрать:'),
                    !filters.begin_date && r('', ' - Дата формирования "с"'),
                    !filters.end_date && r('', ' - Дата формирования "по"'),
                ]
                : r('', String(t))
        )
    );

    const columnFilters = {};
    const table = Table({
        columnFilters,
        pagination: _ => filters.count,
        rows: () => data,
        columns: [
            "current_date",
            'area',
            'fio',
            'personnel_id',
            'position',
            'brigadier_fio',
            'total_time_skud',
            'work_time',
            'total_work_time',
            "total_time_work_zone",
            'activity_work_zone',
            'total_time_kitchen',
            'total_time_warehouse',
            'total_time_outside'

        ],
        columnWidth: (column) => ({
            current_date:32,
            area: 32,
            fio: 48,
            personnel_id: 32,
            position: 48,
            brigadier_fio: 48,
            total_time_skud: 32,
            work_time: 32,
            total_work_time : 32,
            total_time_work_zone: 32,
            activity_work_zone: 32,
            total_time_kitchen: 32,
            total_time_warehouse: 32,
            total_time_outside: 32,
        }[column]),
        columnAlign: (column) => ['current_date','area', 'fio', 'position', 'brigadier_fio'].includes(column) ? 'left' : 'center',
        columnSeparator: (column) => [
            'area',
            'fio',
            'personnel_id',
            'position',
            'brigadier_fio',
            'total_time_skud',
            'work_time',
            'total_work_time ',
            "total_time_work_zone",
            'activity_work_zone',
            'total_time_kitchen',
            'total_time_warehouse',
            'total_time_outside',
        ].includes(column),
        columnName: (column) => ({
            current_date:'Дата',
            area: 'Объект',
            fio: 'ФИО сотрудника',
            personnel_id: 'Табельный номер',
            position: 'Профессия',
            brigadier_fio: 'ФИО Бригадира',
            total_time_skud: 'Время нахождения на объекте по данным СКУД',
            work_time:'Рабочий график',
            total_work_time :'Рабочее время',
            total_time_work_zone: 'Время в рабочей зоне',
            activity_work_zone: '% Активности в рабочей зоне',
            total_time_kitchen: 'Время в обеденной зоне',
            total_time_warehouse: 'Время получения оборудования на складе',
            total_time_outside: 'Время вне объекта',

        })[column],
        columnFilterInput: (column) => ({
            current_date:Table.columnFilterInputs.text,
            area: Table.columnFilterInputs.text,
            fio: Table.columnFilterInputs.text,
            personnel_id: Table.columnFilterInputs.text,
            position: Table.columnFilterInputs.text,
            brigadier_fio: Table.columnFilterInputs.text,
            total_time_skud: Table.columnFilterInputs.time,
            work_time: Table.columnFilterInputs.text,
            total_work_time: Table.columnFilterInputs.text,
            total_time_work_zone: Table.columnFilterInputs.time,
            activity_work_zone: Table.columnFilterInputs.text,
            total_time_kitchen: Table.columnFilterInputs.time,
            total_time_warehouse: Table.columnFilterInputs.time,
            total_time_outside: Table.columnFilterInputs.time,
        })[column],
        columnFilterFunction: (column) => ({
            current_date:Table.columnFilterFunctions.text,
            area: Table.columnFilterFunctions.text,
            fio: Table.columnFilterFunctions.text,
            personnel_id: Table.columnFilterFunctions.text,
            position: Table.columnFilterFunctions.text,
            brigadier_fio: Table.columnFilterFunctions.text,
            total_time_skud: Table.columnFilterFunctions.time(),
            work_time: Table.columnFilterFunctions.text,
            total_work_time: Table.columnFilterFunctions.text,
            total_time_work_zone: Table.columnFilterFunctions.time(),
            activity_work_zone: Table.columnFilterFunctions.text,
            total_time_kitchen: Table.columnFilterFunctions.time(),
            total_time_warehouse: Table.columnFilterFunctions.time(),
            total_time_outside: Table.columnFilterFunctions.time(),

        })[column],
        cellText: (row, column) => ({
            current_date:row.current_date,
            area: row.area,
            fio: row.fio,
            personnel_id: row.personnel_id,
            position: row.position,
            brigadier_fio: row.brigadier_fio,
            total_time_skud: row.total_time_skud || 0,
            work_time: row.work_time,
            total_work_time: row.total_work_time,
            total_time_work_zone: row.total_time_work_zone,
            activity_work_zone: `${replacingDotWithComma(row.activity_work_zone)}%`,
            total_time_kitchen: row.total_time_kitchen,
            total_time_warehouse: row.total_time_warehouse,
            total_time_outside: row.total_time_outside,

        })[column],
    })

    function Filter() {

        if (filters.begin_date && filters.end_date) {
            filters.begin_date = formatDateYYYYMMDD(new Date(Math.min(...[filters.begin_date, filters.end_date].map(d => +new Date(d)))), false);
            filters.end_date = formatDateYYYYMMDD(new Date(Math.max(...[filters.begin_date, filters.end_date].map(d => +new Date(d)))), false);
        }

        return r('.flex.flex-row.items-center.mt-4',
            r('.flex.flex-row.items-center.bg-gray-100.border-2.border-gray-300.rounded-full.relative',
                r('.mr-4.pl-4.leading-8', 'c'),
                r('input', {
                    min: formatDateYYYYMMDD(new Date(new Date(filters.end_date).setDate(new Date(filters.end_date).getDate() - 6))) || "",
                    max: formatDateYYYYMMDD(new Date(new Date(filters.end_date).setDate(new Date(filters.end_date).getDate()))) || "",
                    type: 'date', value: filters.begin_date || '', onChange(e) {
                        filters.begin_date = e.target.value;
                        redraw();
                    }
                }),
                r('.flex.items-center.justify-center.pl-2.pr-2.ml-1.mr-1.leading-8.rounded-full.cursor-pointer.hover:bg-gray-300',
                    {
                        style: {width: '24px', height: '24px'}, onClick() {
                            filters.begin_date = undefined;
                            redraw();
                        }
                    },
                    Icon('close', 'black', 2),
                )
            ),
            r('.ml-4'),
            r('.flex.flex-row.items-center.bg-gray-100.border-2.border-gray-300.rounded-full.relative',
                r('.mr-4.pl-4.leading-8', 'по'),
                r('input', {
                    max: formatDateYYYYMMDD(new Date(new Date(filters.begin_date).setDate(new Date(filters.begin_date).getDate() + 6))) || "",
                    min: formatDateYYYYMMDD(new Date(new Date(filters.begin_date).setDate(new Date(filters.begin_date).getDate()))) || "",
                    type: 'date', value: filters.end_date || '', onChange(e) {
                        filters.end_date = e.target.value;
                        redraw();
                    }
                }),
                r('.flex.items-center.justify-center.pl-2.pr-2.ml-1.mr-1.leading-8.rounded-full.cursor-pointer.hover:bg-gray-300',
                    {
                        style: {width: '24px', height: '24px'}, onClick() {
                            filters.end_date = undefined;
                            redraw();
                        }
                    },
                    Icon('close', 'black', 2),
                )
            ),
            r('.ml-4'),
            r('.flex.flex-row.items-center.bg-gray-100.border-2.border-gray-300.rounded-full.relative',
                r('.mr-4.pl-4', 'Сотрудник'),
                Input(undefined, {
                    value: v => v !== undefined ? (filters.employee = v) : filters.employee,
                    search: true,
                    border: false,
                    list: [{value: null, label: 'Не выбран'}].concat(
                        employeesData.data()
                            .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
                            .map(v => ({value: v.id, label: v.name}))
                    )
                }),
                r('.flex.items-center.justify-center.pl-2.pr-2.ml-1.mr-1.leading-8.rounded-full.cursor-pointer.hover:bg-gray-300',
                    {
                        style: {width: '24px', height: '24px'}, onClick() {
                            filters.employee = undefined;
                            redraw();
                        }
                    },
                    Icon('close', 'black', 2),
                )
            ),
            r('.ml-8'),
            r('.flex.flex-row.items-center.bg-gray-100.border-2.border-gray-300.rounded-full.relative',
                r('.mr-4.pl-4', 'Количество'),
                [10, 20, 50].map(count =>
                    r('.pl-2.pr-2.ml-1.leading-8.rounded-full.cursor-pointer' +
                        (filters.count == count ? '.bg-gray-300' : '.hover:bg-gray-300'),
                        {
                            onClick: () => {
                                filters.count = count;
                                redraw();
                            }
                        },
                        count
                    )
                )
            ),
            r('.ml-auto'),
            r('.flex.flex-row.text-md',
                'Результатов: ',
                r('.ml-1.text-green-600', table.v.rows.length)
            )
        )
    }

    function render() {
        employeesData.init();
        table.recalc();

        return r('.flex.flex-col',
            Filter(),
            r('.border-t.mt-8'),
            r('.overflow-hidden.overflow-x-auto',
                filters.loading ? r('.mt-4.flex.justify-center.text-center', 'Загрузка данных\nожидайте...')
                    : table.render()
            ),
            r('.mt-8'),
            alert.render(),
            r('.flex.flex-row',
                table.pagination.render(),
                r('.flex-1'),
                Button('Сформировать отчет', _ => {
                    if (!filters.begin_date || !filters.end_date) alert.show('filters');
                    else {
                        filters.page = 0;
                        filters.loading = true;
                        redraw();
                        getEmployeesReportFormat2({
                            begin_date: filters.begin_date,
                            end_date: filters.end_date,
                            employee_id: filters.employee
                        }).catch(alert.show)
                            .then(res => {
                                data = res || [];

                            }).finally(_ => {
                            filters.loading = false;
                            for (const k in columnFilters) delete columnFilters[k];
                            redraw();
                        })
                    }
                }, {disabled: filters.loading}),
                r('.ml-2'),
                Button('Скачать XLSX', _ => {
                    if (!filters.begin_date || !filters.end_date) alert.show('filters');
                    else {
                        getEmployeesReportFormat2tXLSX({
                            begin_date: filters.begin_date,
                            end_date: filters.end_date,
                            employee_id: filters.employee
                        }).catch(alert.show)
                    }
                }, {disabled: filters.loading})
            )
        )
    }

    return {render}
}
