import { pipe } from '../../common/utils/func.js';

export function ActionHandler(action) {
    const attrs = { tabIndex: 0 };
    if (action) {
        attrs.onclick = action;
        attrs.onkeydown = function(e) {
            if (e.key == "Enter" || e.key == "Space") action.call(this, e);
        }
    }
    return attrs;
}

export function style2string(obj) {
    return Object.entries(obj).map(kv => kv.join(':')).join(';');
}

export function wrapHandlerList(handler) {
    return function() {
        if (Array.isArray(handler)) {
            for (const h of handler) h.apply(this, arguments);
        } else {
            handler.apply(this, arguments);
        }
    };
}
export function wrapHandlers(attrs) {
    const handler_ents = Object.entries(attrs).filter(([key]) => key.startsWith('on'));
    for (const [key, val] of handler_ents) attrs[key] = wrapHandlerList(val);
    return attrs;
}

const mergeAttrs_ops = {
    attr: (a, b) => a,
    className: (a, b) => (a || '') + ' ' + (b || ''),
    'undefined': (a, b) => b !== undefined ? b : a,
    style: (a, b) => {
        a = typeof a == 'object' ? style2string(a) : a;
        b = typeof b == 'object' ? style2string(b) : b;
        return a + ';' + b;
    },
    eventlist: (a, b) => {
        a = Array.isArray(a) ? a : [a];
        b = Array.isArray(b) ? b : [b];
        return [...a, ...b].filter(v => typeof v == 'function');
    }
}
function mergeAttrs_fn(attrs1, attrs2) {
    if (!attrs1 || Object.keys(attrs1).length == 0) return attrs2;
    if (!attrs2 || Object.keys(attrs2).length == 0) return attrs1;
    
    const attrs = {};
    const keys = new Set([...Object.keys(attrs1), ...Object.keys(attrs2)]);
    
    for (let key of keys) {
        const v1 = attrs1[key], v2 = attrs2[key];
        const t1 = typeof v1, t2 = typeof v2;
        
        let t;
        
        if (key == 'class') key = 'className';
        
        if (t1 == 'undefined' || t2 == 'undefined') t = 'undefined';
        else if (key.startsWith('on')) t = 'eventlist';
        else if (key == 'style' || key == 'className') t = key;
        else if (t1 != t2) {
            console.warn('types mismatch', key, attrs1, attrs2);
            continue;
        }
        else t = 'attr';
        
        attrs[key] = mergeAttrs_ops[t](v1, v2);
    }
    attrs.class = (attrs.class || attrs.className || '').replace(/\./g, ' ');
    return attrs;
}
export function mergeAttrs(...attrs_list) {
    return wrapHandlers(
        pipe(
            {},
            ...attrs_list.map(attrs1 => attrs2 => mergeAttrs_fn(attrs1, attrs2))
        )
    );
}

export function withAttrs(attrs, elem) {
    while (typeof elem == 'function') elem = elem();
    elem.attrs = mergeAttrs(elem.attrs, attrs);
    return elem;
}
