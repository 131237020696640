import { r, redraw } from '../../common/vdom/index.js';
import { callFn } from '../../common/utils/func.js';
import { value, valueRef, formatDate } from '../../utils.js';
import { Icon, Input, Dropdown, Pagination } from '../index.js';

const arr = r => Array.isArray(r) ? r : [r];
const strhas = (a, b) => String(a).toLowerCase().indexOf(String(b).toLowerCase()) != -1;

const localTime = (str) => {
    if (typeof str !== 'string') str = '00:00:00';
    let [h, m, s] = str.split(':').map(v => Number(v));
    h = (h - Math.floor(new Date().getTimezoneOffset() / 60)) % 24;
    return [h, m, s].map(v => String(v).padStart(2, '0')).join(':');
};

function time2int(str) {
    if (typeof str !== 'string') return +str;
    const [h, m, s] = str.split(':')
    return 60*h + +m;
}

Table.columnFilterFunctions = {
    number: (r, f) => Table.columnFilterFunctions.text(r, f),
    date: (r, f) => (!f[0] || !f[1]) || new Date(r) >= new Date(f[0]) && new Date(r) <= new Date(f[1]),
    time: (local_time=false) => (r, f) => {
        let res = true;
        if (r.length > 8) r = r.slice(11,19);
        if (local_time) r = localTime(r);
        if (f[0] && f[0] != '00:00') res &&= time2int(r) >= time2int(f[0]);
        if (f[1] && f[1] != '00:00') res &&= time2int(r) <= time2int(f[1]);
        return res;
    },
    text: (r='', f) => !f || f.split(',').some(f => strhas(r, f)),
    multiselect_and: (r, f) => [...f].every(f => arr(r).some(r => r == f)),
    multiselect_or: (r, f) => [...f].length == 0 || [...f].some(f => arr(r).some(r => r == f))
};

Table.columnFilterInputs = {
    date: (min, max, type='date') => (value) => Dropdown(
        () => Input('', {
            value: () => !value() ? '' : value().map(v => formatDate(v, false)).join(' - '),
            border: false, readonly: true,
            attrs: { className: ".bg-white.text-center.pl-2.shadow" }
        }),
        Input.DateRange({
            value,
            type,
            attrs: { className: '.bg-white' },
            min: min,
            max: max
        })
    ),
    datetime: (min, max) => Table.columnFilterInputs.date(min, max, 'datetime-local'),
    time: (value) => Dropdown(
        () => Input('', {
            value: () => !value() ? '' : value().join(' - '),
            border: false, readonly: true,
            attrs: { className: ".bg-white.text-center.pl-2.shadow" }
        }),
        Input.TimeRange({
            value,
            attrs: { className: '.bg-white' },
        })
    ),
    multiselect: (list) => (value) => Dropdown(
        () => Input('', {
            value: () => {
                if (!value()) value(new Set());
                return [...value()].map(v => list.find(x => x.value == v)?.label || v).join(', ');
            },
            border: false, readonly: true,
            attrs: { className: ".bg-white.text-center.pl-2.shadow" }
        }),
        () => r('.flex.flex-col',
            list
            .map(v => typeof v == 'object' ? { value: v.value, label: v.label } : { value: v, label: v })
            .map(v =>
                r('.text-md.pl-2.pr-2.pt-1.pb-1.cursor-pointer.select-none.w-56' +
                  (value().has(v.value) ? '.bg-blue-50' : '.hover:bg-gray-100'),
                  { onClick() {
                      value()[value().has(v.value) ? 'delete' : 'add'](v.value);
                      value(value());
                      redraw();
                  } },
                  (value().has(v.value) ? ' ✓ ' : ' - ') + v.label
                )
            )
        )
    ),
    text: (value) => Input('', {
        value, border: false,
        attrs: { className: ".bg-white.text-center.pl-2.shadow" }
    }),
    number: (value) => Input('', {
        value,
        border: false, type: 'number',
        attrs: { className: ".bg-white.text-center.pl-2.shadow.bg-red" }
    })
};

function Cell(arg={}) {
    const { value='', width=48, color='gray-800', align='left', type='string', separator=false, header=false, onClick } = arg;
    const cls = (
        `.pt-2.pb-2.pl-4.leading-4.items-center.flex.flex-row.w-${width}.text-${color}` +
        ({ string: `.text-sm.font-normal`, number: `.text-lg.font-medium` })[type] +
        ['', '.cursor-pointer.select-none'][+header]
    );
    const itemCls = (
        '.w-full.flex.flex-row.relative.items-center' + ' tableHeader '+
        ({ left: '.justify-start.text-left',
           right: '.justify-end.text-right.pr-4',
           center: '.justify-center.text-center'
        })[align]
    );
    return r(['td', 'th'][+header], { onClick },
        r(cls,
            !separator ? '' : r('.border-l.h-8.pl-6', { style: { marginRight: '-8px' } }),
            r('.h-8'),
            r(itemCls, value)
        )
    );
}
export default function Table(arg={}) {
    const _columnFilters = {};
    const _columnFilterInput = (column) => (value) => '';
    const {
        rows = () => [],
        columns = () => [],
        cellText = (row, column) => row[column],
        cellSortValue = (row, column) => row[column],
        cellFilterValue = (row, column) => row[column],
        cellColor = (row, column) => 'gray-800',
        cellRender = (row, column) => text => text,
        sortColumn = value(0),
        sortDir = value(1),
        columnWidth = (column) => 48,
        columnAlign = (column) => 'center',
        columnSeparator = (column) => false,
        columnName = column => column,
        columnFilters = _columnFilters,
        columnFilterInput = _columnFilterInput,
        columnFilterFunction = (column) => (value, filter) => 1,
        additionalFilter = () => 1,
        onSort = (sortColumn, sortDir) => {},
        onColumnFilters = (columnFilters) => {},
        pagination = null
    } = arg;
    
    sortColumn.listen(() => onSort(sortColumn(), sortDir()));
    sortDir.listen(() => onSort(sortColumn(), sortDir()));

    const v = {
        rows: callFn(rows),
        columns: callFn(columns),
        page: 0,
        count: 10
    };

    let _pagination
    if (pagination) {
        _pagination = Pagination({
            page: valueRef(v, 'page'),
            count: typeof pagination == 'function' ? pagination : valueRef(v, 'count'),
            rows: () => v.rows
        });
    }

    function recalc() {
        v.columns = callFn(columns);
        v.rows = callFn(rows)
            .filter(row => (
                Object.keys(columnFilters).every(column =>
                    (columnFilterFunction(column) || (v => 1))(
                        cellFilterValue(row, column) ?? cellText(row, column),
                        columnFilters[column]
                    )
                )
            ))
            .filter(additionalFilter)
            .sort((a, b) => {
                if (!sortColumn() || !sortDir()) return 1;
                a = cellSortValue(a, sortColumn());
                b = cellSortValue(b, sortColumn());
                if (!isNaN(a) && !isNaN(b)) return (a - b) * sortDir();
                if (!a) return sortDir();
                if (!b) return -sortDir();
                a = typeof a == 'object' ? JSON.stringify(a) : String(a);
                b = typeof b == 'object' ? JSON.stringify(b) : String(b);
                return a.localeCompare(b) * sortDir();
            });
    }
    
    function render() {
        recalc();
        return r('table.border-separate', { style: { 'border-spacing': '0px 4px' } },
            (columnFilterInput != _columnFilterInput) && r('thead',
                v.columns
                .map(column => [
                    column,
                    valueRef(columnFilters, column).listen(() => onColumnFilters(columnFilters))
                ])
                .map(([column, value]) =>
                    Cell({
                        value: [
                            (columnFilterInput(column) || (v => ''))(value),
                            (value() instanceof Set ? value().size > 0 : value()) &&
                            r('.absolute.right-0.cursor-pointer.bg-white.p-2.h-8.pt-3.w-6',
                                { onClick() {
                                    if (value() instanceof Set) { value().clear(); value(value()); }
                                    else value('');
                                    redraw();
                                } },
                                Icon('close', 'gray-600', 3)
                            )
                        ],
                        width: columnWidth(column),
                        separator: columnSeparator(column),
                        align: columnAlign(column)
                    })
                )
            ),
            r('thead',
                v.columns.map((column) => 
                    Cell({
                        header: true,
                        onClick: () => {
                            sortDir(
                                sortColumn() != column ? 1 : ({ '-1': 0, 0: 1, 1: -1 })[sortDir()]
                            );
                            sortColumn(column);
                            redraw();
                        },
                        value: [
                            columnName(column),
                            (icon => icon && Icon(icon, 'gray-800', 4, { className: '.ml-2.flex-shrink-0' }))(
                                sortColumn() == column && sortDir() == -1 ? 'arrow-up' :
                                sortColumn() == column && sortDir() == 1 ? 'arrow-down' : ''
                            )
                        ],
                        width: columnWidth(column),
                        separator: columnSeparator(column),
                        align: columnAlign(column)
                    })
                )
            ),
            (pagination ? v.rows.slice(..._pagination.slice()) : v.rows).map(row =>
                r('tr.rounded.pr-4.bg-white',
                    v.columns.map((column) => 
                        Cell({
                            value: (cellRender(row, column) || (v => v))(cellText(row, column)),
                            color: cellColor(row, column),
                            width: columnWidth(column),
                            separator: columnSeparator(column),
                            align: columnAlign(column)
                        })
                    )
                )
            )
        );
    }
    
    return { render, recalc, pagination: _pagination, v };
}
