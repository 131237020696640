import { r, redraw } from '../../common/vdom/index.js';
import { EmployeesTable } from '../../components/index.js';
import { employeesData } from '../employees/index.js';

export default function({ openEmployee }) {
    const table = EmployeesTable({
        openEmployee,
        mode: 1,
        data: employeesData.data
    });
    
    function render() {
        employeesData.init();
        return r('.p-16.bg-blue-50.w-full.h-full.select-none',
            table.render()
        );
    }
    return { render };
}
