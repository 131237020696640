import { closest, padLinear } from '../../common/utils/chart.js';
import { throttle } from '../../common/utils/func.js';
import { r } from '../../common/vdom/index.js';

const formatDate = d3.timeFormat("%H:%M:%S");

const CSS_CLASSNAME = 'heartbeat-chart';

let ids = 0;
function identifyElem(elem) {
    let id = elem.dataset['chartid'];
    return id ? id : elem.dataset['chartid'] = ++ids;
}

function initState(elem) {
    const state = states[identifyElem(elem)] = {};
    state.elem = elem;
    state.data = [];
    
    state.scaleX = null;
    state.scaleY = null;
    state.nodes = {};
    state.padding = {top: 15, right: 15, bottom: 35, left: 35};
    return state;
}

function initNodes(elem, state) {
    state.scaleX = d3.scaleTime();

    state.scaleY = d3.scaleLinear();
      
    state.valuesLine = d3.line()
      .defined(d => !isNaN(d.value))
      .x(d => state.scaleX(d.date))
      .y(d => state.scaleY(d.value));



    const container = d3.select(elem);
    container.classed(CSS_CLASSNAME, true);
    container.style('position', 'relative');
    
    const n = state.nodes;
    n.svg = container.append('svg');
    n.defs = n.svg.append("defs");
    
    n.clipPath = n.defs.append("clipPath")
      .attr("id", "clip")
      .append("rect");
    
    n.bg = n.svg.append('rect')
      .attr('fill', '#fefeff')
      .attr("clip-path", "url(#clip)");
    
    n.axisX = n.svg.append("g").classed('axis', true).classed('axis-bottom', true);
    n.axisYLeft = n.svg.append("g").classed('axis', true).classed('axis-left', true);
    n.axisYRight = n.svg.append("g").classed('axis', true).classed('axis-right', true);
    
    n.gridX = n.svg.append("g").classed('grid', true);
    n.gridY = n.svg.append("g").classed('grid', true);
      
    n.linePath = n.svg.append("path")
      .attr("class", "path")
      .attr("fill", "none")
      .attr("clip-path", "url(#clip)")
      .attr("stroke", "#41a077")
      .attr("stroke-width", 1.5)
      
    n.tooltipBox = container.append('div')
      .style('position', 'absolute')
      .style('transition', 'all 0.1s')
      .style('top', '0').style('left', '0')
      .style('box-shadow', '0 1px 3px 0 rgb(65 169 134 / 34%), 0 1px 2px 0 rgb(65 169 134 / 23%)')
      .style('pointer-events', 'none');
    n.tooltipLine = n.svg.append('line');
    n.tooltipDot = n.svg.append("circle").attr("clip-path", "url(#clip)");
    
    state.tooltipEvent = null;
    n.svg.on('mousemove', throttle(100, (e) => {
        if (n.tooltipBox.node().dataset.visible != 'true') return;
        state.tooltipEvent = e;
        redrawNodes(elem, state);
    }));
    n.svg.on('mouseenter', () => n.tooltipBox.node().dataset.visible = true);
    n.svg.on('mouseleave', () => {
        n.tooltipBox.style('display', 'none');
        n.tooltipLine.style('display', 'none');
        n.tooltipDot.style('display', 'none');
        n.tooltipBox.node().dataset.visible = false;
        state.tooltipEvent = null;
    });
}

function redrawNodes(elem, state) {
    const { width, height } = elem.getBoundingClientRect();
    const { padding, data } = state;
    
    state.scaleX
      .domain(d3.extent(data, d => d.date))
      .range([padding.left, width - padding.right])
      
    state.scaleY
      .domain(padLinear(d3.extent(data, d => d.value), 0.8)).nice()
      .range([height - padding.bottom, padding.top])

    const n = state.nodes;
    
    n.svg.attr('width', width).attr('height', height);
    
    n.gridX
      .attr("transform", `translate(0,${padding.top})`)
      .call(
        d3.axisBottom(state.scaleX)
          .ticks(5)
          .tickSize(height - padding.bottom - padding.top + 8)
          .tickSizeOuter(0)
          .tickFormat(d => '')
      );
    
    n.gridY
      .attr("transform", `translate(${padding.left - 8},0)`)
      .call(
        d3.axisRight(state.scaleY)
          .ticks(5)
          .tickSize(width - padding.left - padding.right + 16)
          .tickSizeOuter(0)
          .tickPadding(0)
          .tickFormat(d => '')
      )
      
    n.axisX
      .attr("transform", `translate(0,${height - padding.bottom})`)
      .call(
        d3.axisBottom(state.scaleX)
          .ticks(5)
          .tickSizeOuter(0)
          .tickFormat(d => state.scaleX(d) > padding.left * 1.5 ? formatDate(d) : '')
      );
    
    n.axisYLeft
      .attr("transform", `translate(${padding.left},0)`)
      .call(
        d3.axisLeft(state.scaleY)
          .ticks(5)
          .tickSize(15)
          .tickSizeOuter(0)
          .tickPadding(0)
          .tickFormat(d => +d)
      )
    
    n.linePath
      .datum(data)
      .attr("d", state.valuesLine);
      
    n.clipPath
      .attr("x", padding.left).attr("y", padding.top)
      .attr("width", Math.max(0, width - padding.left - padding.right))
      .attr("height", Math.max(0, height - padding.top - padding.bottom));
    
    n.bg
      .attr("width", Math.max(0, width - padding.right))
      .attr("height", Math.max(0, height));
    
    redrawTooltip();
    function redrawTooltip() {
        if (!state.tooltipEvent) return;
        const ex = state.tooltipEvent.clientX - elem.getBoundingClientRect().x;
        const ey = state.tooltipEvent.clientY - elem.getBoundingClientRect().y;
        const i = closest(state.data.map(d => +d.date), +state.scaleX.invert(ex));
        
        const { date, value } = state.data[i];
        const x = state.scaleX(date), y = state.scaleY(value);
        const height = elem.getBoundingClientRect().height - state.padding.bottom;
        n.tooltipBox
          .style('display', 'flex')
          .style('transform', `translate(${x + 8}px, ${Math.min(y, height) - 20}px)`);
        
        n.tooltipLine
          .style('display', 'initial')
          .style("stroke", "black")
          .attr("x1", x).attr("y1", state.padding.top)
          .attr("x2", x).attr("y2", height);
          
        n.tooltipDot
          .style('display', 'initial')
          .style("stroke", "gray")
          .style("fill", "black")
          .attr("r", 4).attr("cx", x).attr("cy", y);
        
        const content = r('.pl-2.pr-2.pt-1.pb-1.bg-white.flex.flex-col',
            r('.text-black.text-xl.font-bold', (+value).toFixed(1)),
            r('.text-gray-600.text-sm', formatDate(new Date(date))),
        );
        ReactDOM.render(content, n.tooltipBox.node());
    }
}

const states = {};
export default async function HeartbeatChart(elem, data) {
    if (elem == null) return;
    await new Promise(r => setTimeout(r, 100));
    const id = identifyElem(elem);
    let state = states[id];
    if (!state) {
        state = initState(elem);
        initNodes(elem, state);
    }
    state.data = data;
    redrawNodes(elem, state);
}
