import { Labelled, Select, Input, Icon, Draggable } from '../components.js';
import { withAttrs } from '../utils.js';
import { callFn } from '../../../common/utils/func.js';

function filterFunctionDefault(rowValue='', filterValue='') {
    return !filterValue || String(rowValue).toLowerCase().indexOf(String(filterValue).toLowerCase()) != -1;
}

function filterInputDefault({ get, set }) {
    return Input({
        get: () => get(),
        set: v => { set(v); m.redraw(); }
    });
}

export default function(arg={}) {
    const {
        attrs = {},
        rows = [],
        rowsLimit = null,
        cols = [],
        colNames = {},
        colWidths = {},
        cell = ({ row, col }) => '',
        colAligns = {},
        filterInput = {},
        filterFunction = {},
        filter = null
    } = arg;
    
    let { sortColumn, sortDir=0 } = arg;
    
    const colWidths1 = {};
    const colWidthsPrev = {};
    
    const touchedColumn = {};
    let resizingColumn;
    const resizeColumn = Draggable({
        distance: 'x',
        throttle: 20,
        value: {
            get: () => colWidths1[resizingColumn] || 0,
            set: v => colWidths1[resizingColumn] = Math.max(65, v)
        },
        container: dom => document.body
    });
    
    let dom;
    function updateCols(vnode) {
        if (!dom) {
            dom = vnode.dom;
            setTimeout(m.redraw, 20);
        }
        const colWidths_ = callFn(colWidths);
        for (let i = 0; i < colWidths_.length; i++) {
            const elem1 = dom.children[i], elem2 = dom.nextSibling?.children?.[i];
            if (touchedColumn[i]) return;
            if (colWidthsPrev[i] != colWidths_[i]) {
                colWidthsPrev[i] = colWidths_[i];
                colWidths1[i] = colWidths_[i] || Math.max(
                    elem1 ? elem1.getBoundingClientRect().width : 65,
                    elem2 ? elem2.getBoundingClientRect().width : 65
                );
            }
        }
    }
    
    function currentRows({ cols, filterFunction, limit }) {
        return [...callFn(rows)]
          .sort((a, b) => {
              if (!sortColumn || !sortDir) return 1;
              a = cell({ row: a, col: sortColumn }) || '';
              b = cell({ row: b, col: sortColumn }) || '';
              a = (a.tag ? a.text : a) || '';
              b = (b.tag ? b.text : b) || '';
              const l = Math.max(a.length, b.length);
              a = String(a).padStart(l, '0');
              b = String(b).padStart(l, '0');
              return a.localeCompare(b) * sortDir;
          })
          .filter(row =>
                cols.every((col, ci) =>
                    !filter || 
                    (filterFunction[ci] || filterFunctionDefault)(row[col], filter[col])
                )
          )
          .slice(...(limit ? [0, limit] : []));
    }
    
    function render() {
        if (dom) updateCols();
        const v = {};
        v.colWidths = callFn(colWidths);
        v.limit = callFn(rowsLimit);
        v.cols = callFn(cols);
        v.colNames = callFn(colNames);
        v.filterFunction = callFn(filterFunction);
        v.filterInput = callFn(filterInput);
        v.rows = currentRows(v);
        //console.log({v});
        return m('.t-mono.col.rel.b1.rad2.border-w1.border-c1.widthc.heightc.lheight1', attrs,
            ((acc=0) => v.cols.map((col, ci) =>
                withAttrs({
                    onmousedown() {
                        touchedColumn[resizingColumn = ci] = true;
                    }
                }, resizeColumn.render(
                    m('.heightf.pl1.pr1.z2.nosel.abs',
                        { style: `cursor: col-resize; transform: translateX(-7.5px); margin-left: ${acc += colWidths1[ci] || 0}px;` },
                        m('.line-v.heightf')
                    )
                ))
            ))(),
            !filter ? '' :
            m('.row.stc.top.b1.z2',
                v.cols.map((col, ci) =>
                    m('.row.y-center.rel', { style: `width: ${colWidths1[ci]}px;` },
                        withAttrs(
                            { class: 't-center c1 f1', placeholder: 'Фильтр', style: `flex: 1; height: 24px;` },
                            (v.filterInput[ci] || filterInputDefault)({
                                get: () => filter[col],
                                set: v => { filter[col] = v; m.redraw(); }
                            })
                        ),
                        Icon('close', {
                            style: `display: ${filter[col] ? 'flex' : 'none'};`,
                            class: '.p1.ptr.abs.right.z1',
                            onclick: () => { filter[col] = ''; m.redraw(); }
                        })
                    )
                )
            ),
            m('.col',
                m('.row.stc', {style: 'top: 24px;', onupdate: updateCols, oncreate: updateCols},
                    v.cols.map((col, ci) =>
                        m('.w2.b1-darker.row.x-center.y-center.rigid.pl1.pr1.pb0.pt0.t-wrap.b1.line-h.stc.ptr.nosel',
                            { style: `width: ${colWidths1[ci]}px; text-align: ${colAligns[ci] || 'center'};`,
                              onclick() {
                                  sortDir = sortColumn != col ? 1 : ({ '-1': 0, 0: 1, 1: -1 })[sortDir];
                                  sortColumn = col;
                                  m.redraw();
                              }
                            },
                            v.colNames?.[ci] || col,
                            sortColumn == col && sortDir == -1 ? Icon('arrow-up') :
                            sortColumn == col && sortDir == 1 ? Icon('arrow-down') : ''
                        )
                    )
                ),
                v.rows.map(row =>
                    m('.row.hvr-b1-darker',
                        v.cols.map((col, ci) =>
                            m('.p0.t-wrap.row.x-center.y-center',
                                { style: `border-bottom: 1px solid #dfdfdf; cursor: default; width: ${colWidths1[ci]}px; text-align: ${colAligns[ci] || 'center'};` },
                                cell({ row, col }) == undefined ? m('.pre', ' ') :
                                cell({ row, col })
                            )
                        )
                    )
                )
            )
        );
    }
    
    return {
        render,
        rows: (arg={}) => currentRows({
            limit: arg.limit,
            cols: callFn(cols),
            filterFunction: callFn(filterFunction)
        })
    };
}
