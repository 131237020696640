import { r, redraw } from '../../common/vdom/index.js';
import { valueRef, value } from '../../utils.js';
import { Icon, Alert, Button, Input, Table, Dropdown } from '../../components/index.js';
import { withAttrs } from '../../components/utils.js';
import * as api from '../../api/index.js';

export default function(arg={
    user: () => ({})
}) {
    const { user } = arg;

    let users = [];
    const edit = {};
    
    const adminValues = [
        { value: true, label: 'Да' },
        { value: false, label: 'Нет' }
    ];
    
    const sortDir = value(0);
    const table = Table({
        sortDir,
        rows: () => users,
        columns: () => ['full_name', 'username', 'password', 'admin', 'edit_btn', 'delete_btn'],
        columnAlign: column => ({
            full_name: 'left',
            username: 'left',
            admin: 'left',
            password: 'left',
            edit_btn: 'left',
            delete_btn: 'left',
        })[column],
        columnName: column => ({
            full_name: 'ФИО',
            username: 'Имя пользователя',
            admin: 'Администриатор',
            password: 'Пароль',
            edit_btn: '',
            delete_btn: ''
        })[column],
        columnFilterInput: column => ({
            full_name: Table.columnFilterInputs.text,
            username: Table.columnFilterInputs.text,
            admin: Table.columnFilterInputs.multiselect(adminValues)
        })[column],
        columnFilterFunction: column => ({
            full_name: Table.columnFilterFunctions.text,
            username: Table.columnFilterFunctions.text,
            admin: Table.columnFilterFunctions.multiselect_or
        })[column],
        cellRender: (row, column) => ({
            full_name: _ => (
                edit[row.id]
                ? Input('', { value: valueRef(edit[row.id], 'full_name') })
                : row.full_name || r('.opacity-60', 'Не указано')
            ),
            username: _ => (
                edit[row.id]
                ? Input('', { value: valueRef(edit[row.id], 'username') })
                : row.username || r('.opacity-60', 'Не указано')
            ),
            password: _ => (
                edit[row.id]
                ? Input('', { value: valueRef(edit[row.id], 'password') })
                : row.password || r('.opacity-60', 'Не указано')
            ),
            admin: _ => (
                edit[row.id]
                ? Input('', {
                    value: valueRef(edit[row.id], 'admin'),
                    list: adminValues
                })
                : adminValues.find(v => v.value == row.admin)?.label
            ),
            edit_btn: _ => (
                edit[row.id]
                ? [
                    r('.flex.flex-row.items-center.font-bold.text-md.text-red-600.cursor-pointer.uppercase',
                        { onClick: async () => {
                              if (String(row.id).startsWith('create')) users.splice(users.indexOf(row), 1);
                              edit[row.id] = null;
                              redraw();
                          }
                        },
                        r('', 'Отменить')
                    ),
                    r('.mr-4'),
                    r('.flex.flex-row.items-center.font-bold.text-md.text-blue-400.cursor-pointer.uppercase',
                        { onClick: async () => {
                              try {
                                  if (String(row.id).startsWith('create')) {
                                      Object.assign(row, await api.user.create(edit[row.id]));
                                  } else {
                                      Object.assign(row, await api.user.update(row.id, edit[row.id]));
                                  }
                                  edit[row.id] = null;
                              } catch (e) {
                                  alert.show(e?.response?.status == '500' ? "Ошибка сохранения данных на сервере, свяжитесь с администратором." : e.message);
                              }
                              redraw();
                          }
                        },
                        r('', 'Сохранить')
                    )
                ]
                : r('.flex.flex-row.items-center.font-bold.text-md.text-gray-600.cursor-pointer.uppercase',
                    { onClick: () => { edit[row.id] = { ...row }; redraw(); } },
                    Icon('edit', 'gray-600', 6),
                    r('', 'Редактировать')
                )
            ),
            delete_btn: _ => (
                ( user().id == row.id || row.admin || String(row.id).startsWith('create') ) ? '' :
                r('.flex.flex-row.items-center.font-bold.text-md.text-red-400.cursor-pointer.uppercase',
                    { onClick: async () => {
                          if (!(await deleteAlert.show({ user: row }))) return;
                          await api.user.delete(row.id);
                          users.splice(users.indexOf(row), 1);
                          redraw();
                      }
                    },
                    Icon('delete', 'red-400', 6),
                    r('', 'Удалить')
                )
            )
        })[column]
    });
    
    async function load() {
        users = (await api.user.get()).reverse();
        redraw();
    }
    
    function onopen() {
        load();
    }
    
    const deleteAlert = Alert(
        (confirm, { user }={}) =>
            r('.flex.flex-col.whitespace-pre-wrap', { style: { width: '450px' } },
                r('.mt-4', `Удалить пользователя `, r('.font-bold.inline', user?.username), ' ?'),
                r('.flex.flex-row.mt-8',
                    Button('Подтвердить', () => confirm(true), { color: 'blue' }),
                    r('.mr-4'),
                    Button('Отменить', () => confirm(false), { color: 'red' })
                )
            )
    );
    
    const alert = Alert(
        (confirm, text) => r('.flex.flex-col.whitespace-pre-wrap', { style: { width: '450px' } }, text)
    );
    
    function render() {
        return r('.flex.flex-col.p-16',
            alert.render(),
            deleteAlert.render(),
            r('.flex.flex-row.mb-4.mt-4', { style: { marginTop: '-32px' } },
                r('.text-xl.font-bold', 'Пользователи'),
                r('.ml-8'),
                r('.flex.flex-row.items-center.cursor-pointer',
                    { onClick() {
                        const id = 'create' + Math.random();
                        sortDir(0);
                        users.unshift({ id, password: '', username: '', admin: false });
                        edit[id] = users[0];
                        redraw();
                    } },
                    Icon('plus', 'green-600', 6),
                    r('.text.text-green-600.uppercase.font-medium.ml-1', 'Добавить пользователя')
                )
            ),
            r('.mt-2'),
            r('.border-t'),
            r('.mt-2'),
            table.render(),
        );
    }
    
    return { onopen, render };
}
