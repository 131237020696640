import { Panels } from '../components.js';
import { ActionHandler, withAttrs } from '../utils.js';

const sharedPanel = Panels.Panel({
    content: () => '',
    focusChild_onopen: true
});

export default function Dropdown(element, dropdown, arg={}) {
    const { align='center' } = arg;
    function open() {
        sharedPanel.state.align = align;
        sharedPanel.setContent(
            withAttrs({ style: `width: ${this.getBoundingClientRect().width};` }, dropdown)
        );
    }
    
    function onupdate({ dom }) {
        if (sharedPanel.state.anchor != dom) return;
        open.call(dom);
    }
    
    return sharedPanel.anchorElement(
        withAttrs({ ...ActionHandler(open), onupdate }, element)
    );
}
