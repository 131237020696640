import { API_HOST } from '../config.js';
import { getToken, onToken } from './login.js';
import { mapping } from './index.js';

let mappings = [];
setTimeout(_ => {
    onToken(async () => {
        mappings = await mapping.get();
    });
}, 0);

export function get() {
    return new Promise((resolve, reject) => {
        return fetch(`${API_HOST}/telemetry_data/get_all/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                "Content-Type": "application/json",
            }
        }).then(response => {
            if (!response.ok) throw new Error('Ошибка получения данных. Получен статус кода ' + response.status);
            return response.json();
        }).then(data => {
            for (const d of data) {
                d.data.alarm = Object.entries(d.data)
                    .filter(kv => kv[0].endsWith('_alarm') && kv[1] == 1)
                    .map(kv => mappings.find(m => m.telemetry_name == kv[0]) || { telemetry_name: kv[0], real_name: kv[0] })
            }
            resolve(data);
        }).catch(() => resolve(null));
    });
}
