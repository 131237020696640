import { r, redraw } from '../../common/vdom/index.js';
import { Icon, Alert, Button, Panels, Input, Dropdown } from '../../components/index.js';
import { withAttrs } from '../../components/utils.js';
import { formatBleID } from '../../utils.js';
import * as api from '../../api/index.js';

export default function(arg={}) {
    let places = [], devices = [], organizations = [], departments = [];
    const opened = {}, edit = {}, create = {}, remove = {}, focus = {};
    
    async function init() {
        places = await api.place.get();
        devices = (await api.device.get()).filter(d => d.device_type == 'ble');
        departments = await api.department.get();
        places.filter(d => d.parent_id == null).forEach(d => opened[d.id] = true);
        redraw();
    }
    
    let saving;
    async function save() {
        if (saving) return;
        saving = true;
        try {
        
        const places1 = [];
        for (let place of places) {
            if (remove[place.id]) {
                await api.place.delete(place.id);
                delete remove[place.id];
                continue;
            }
            
            if (create[place.id]) {
                place = await api.place.create(place);
                delete create[place.id];
            } else if (edit[place.id]) {
                place = await api.place.update(place.id, place);
                delete edit[place.id];
            }
            
            places1.push(place);
        }
        places = places1;
        redraw();
        
        }
        catch (e) { console.error(e); }
        finally { saving = false; }
    }
    
    function onopen() {
        init();
    }
    
    let panelPlace;
    const actionsPanel = Panels.Panel({
        content: () => r('.pt-2.pb-2.bg-white.rounded-md.shadow-xs',
            r('.text-xs.pl-4.pr-4.text-gray-600.uppercase', 'Создать'),
            r('.text-md.pl-4.pr-4.hover:bg-gray-100.cursor-pointer.select-none',
                { onClick: () => {
                    const id = Math.random();
                    places.push({ id, name: 'Элемент', parent_id: panelPlace.parent_id, /*organization_id: null,*/ department_id: null });
                    create[id] = edit[id] = true;
                    actionsPanel.close();
                    redraw();
                  }
                },
                'Элемент'
            ),
            r('.text-md.pl-4.pr-4.hover:bg-gray-100.cursor-pointer.select-none',
                { onClick: () => {
                    const id = Math.random();
                    places.push({ id, name: '', parent_id: panelPlace.id, /*organization_id: null,*/ department_id: null });
                    create[id] = edit[id] = true;
                    opened[panelPlace.id] = true;
                    actionsPanel.close();
                    redraw();
                  }
                },
                'Подчиненный элемент'
            ),
            r('.mt-1.mb-1.border-t-2'),
            r('.text-md.pl-4.pr-4.hover:bg-gray-100.cursor-pointer.select-none',
                { onClick: () => {
                    edit[panelPlace.id] = true;
                    focus[panelPlace.id] = true;
                    actionsPanel.close();
                    redraw();
                  }
                },
                'Изменить'
            ),
            r('.mt-1.mb-1.border-t-2'),
            r('.text-md.pl-4.pr-4.hover:bg-gray-100.cursor-pointer.select-none',
                { onClick: async () => {
                    actionsPanel.close();
                    if (!(await deleteAlert.show({ place: panelPlace }))) return;
                    remove[panelPlace.id] = true;
                    save();
                  }
                },
                'Удалить'
            ),
        )
    });
    
    const deviceTakenAlert = Alert(
        (confirm, { device, oldPlace, place }={}) =>
            r('.flex.flex-col.whitespace-pre-wrap', { style: { width: '450px' } },
                r('',
                    'Устройство ', r('.font-bold.inline', (formatBleID(device?.name) || '').slice(-8)), ' привязано к объекту ',
                    r('.font-bold.inline', oldPlace?.name)
                ),
                r('.mt-4', `Привязать к объекту `, r('.font-bold.inline', place?.name), ' ?'),
                r('.flex.flex-row.mt-8',
                    Button('Подтвердить', () => confirm(true), { color: 'blue' }),
                    r('.mr-4'),
                    Button('Отменить', () => confirm(false), { color: 'red' })
                )
            )
    );
    
    const deleteAlert = Alert(
        (confirm, { place }={}) =>
            r('.flex.flex-col.whitespace-pre-wrap', { style: { width: '450px' } },
                r('.mt-4', `Удалить объект `, r('.font-bold.inline', place?.name), ' ?'),
                r('.flex.flex-row.mt-8',
                    Button('Подтвердить', () => confirm(true), { color: 'blue' }),
                    r('.mr-4'),
                    Button('Отменить', () => confirm(false), { color: 'red' })
                )
            )
    );
    
    let dropdownSearch = '';
    function render() {
        //console.log({ places, create, remove, devices, /*organizations,*/ departments });
        function Tree(parent, level=0) {
            const list = places.filter(place => place.parent_id == parent && !remove[place.id]);
            if (list.length == 0) return r('.text-xs.leading-6.pl-4.pr-4.text-gray-600.uppercase',
                { style: { paddingLeft: 8 + level * 24 + 'px' } },
                'Нет элементов'
            );
            
            const deviceName = (place) => ({
                get: () => {
                    let name = devices.find(x => x.id == place.device_id)?.name;
                    return name ? formatBleID(name) : 'Не выбрано';
                },
                set: async (v) => {
                    const device = devices.find(x => x.name == v);
                    const oldPlace = places.find(x => x.id != place.id && x.device_id == device.id);
                    //console.log({ device, place, oldPlace });
                    if (device && oldPlace) {
                        if (await deviceTakenAlert.show({ device, place, oldPlace })) {
                            edit[oldPlace.id] = true;
                            oldPlace.device_id = null;
                        } else { return; }
                    }
                    place.device_id = device.id;
                    redraw();
                }
            });
            
            /*const organizationName = (place) => ({
                get: () => organizations.find(x => x.id == place.organization_id)?.name || 'Не выбрано',
                set: (v) => {
                    place.organization_id = organizations.find(x => x.name == v)?.id;
                    redraw();
                }
            });*/
            const linkToPlace = (place) => ({
                get: () => places.find(x => x.id == place.organization_id)?.name || 'Не выбрано',
                set: (v) => {
                    place.organization_id = places.find(x => x.name == v)?.id;
                    redraw();
                }
            });

            const departmentName = (place) => ({
                get: () => departments.find(x => x.id == place.department_id)?.name || 'Не выбрано',
                set: (v) => {
                    place.department_id = departments.find(x => x.name == v)?.id;
                    redraw();
                }
            });
            
            return list.map(place => [
                edit[place.id]
                ? r('.grid.grid-cols-12.gap-4.w-full.mt-2.mb-2.pt-1.pb-1',
                    r('.flex.flex-row.col-span-3', { style: { paddingLeft: 8 + level * 24 + 'px' } },
                        r('', { style: { marginLeft: '-8px', transform: 'translateX(6px)' } },
                            Icon('edit', 'gray-600', 6)
                        ),
                        r('.ml-2'),
                        r('input.h-6.w-full.bg-white.pl-2.pr-2.border', {
                            value: place.name,
                            placeholder: 'Название элемента',
                            ref: dom => {
                                if (!dom || !focus[place.id]) return;
                                focus[place.id] = false;
                                dom.focus();
                            },
                            onChange: e => { place.name = e.target.value; redraw(); }
                        }),
                    ),
                    r('.col-span-2.h-min',
                        Dropdown(
                            r('.bg-white.leading-4.pt-1.pb-1.w-full.pl-2.pr-2.border.break-normal', deviceName(place).get()),
                            close => r('.flex.flex-col',
                                r('input.ml-4.mr-4.mb-2.border-b-2.border-gray-400', {
                                    placeholder: "Поиск",
                                    value: dropdownSearch,
                                    onInput(e) { dropdownSearch = e.target.value; redraw(); }
                                }),
                                devices
                                  .filter(d => formatBleID(d.name).toLowerCase().indexOf(dropdownSearch.toLowerCase()) != -1)
                                  .map(d => r('.bg-white.text-md.hover:bg-gray-100.cursor-pointer.select-none.pl-4.pr-4',
                                      { onClick: () => (close(), deviceName(place).set(d.name)) },
                                      formatBleID(d.name)
                                  ))
                            )
                        )
                    ),
                    /*r('.col-span-2.h-min',
                        Dropdown(
                            r('.bg-white.leading-4.pt-1.pb-1.w-full.pl-2.pr-2.border.break-normal', organizationName(place).get()),
                            organizations.map(d => r('.bg-white.text-md.hover:bg-gray-100.cursor-pointer.select-none.pl-4.pr-4',
                                { onClick: () => organizationName(place).set(d.name) },
                                d.name
                            ))
                        )
                    ),*/

                        r('.col-span-2.h-min',
                        Dropdown(
                            r('.bg-white.leading-4.pt-1.pb-1.w-full.pl-2.pr-2.border.break-normal', linkToPlace(place).get()),
                            places.map(d => r('.bg-white.text-md.hover:bg-gray-100.cursor-pointer.select-none.pl-4.pr-4',
                                { onClick: () => linkToPlace(place).set(d.name) },
                                d.name
                            ))
                        )
                    ),

                    r('.col-span-2.h-min',
                        Dropdown(
                            r('.bg-white.leading-4.pt-1.pb-1.w-full.pl-2.pr-2.border.break-normal', departmentName(place).get()),
                            close => r('.flex.flex-col',
                                r('input.ml-4.mr-4.mb-2.border-b-2.border-gray-400', {
                                    placeholder: "Поиск",
                                    value: dropdownSearch,
                                    onInput(e) { dropdownSearch = e.target.value; redraw(); }
                                }),
                                departments
                                  .filter(d => d.name.toLowerCase().indexOf(dropdownSearch.toLowerCase()) != -1)
                                  .map(d => r('.bg-white.text-md.hover:bg-gray-100.cursor-pointer.select-none.pl-4.pr-4',
                                      { onClick: () => (close(), departmentName(place).set(d.name)) },
                                      d.name
                                  ))
                            )
                        )
                    ),
                    withAttrs({ className: '.w-24' }, Button('Сохранить', save, { size: 'small' }))
                )
                : r('.grid.grid-cols-12.gap-4.w-full.mt-1.pt-1.pb-1.hover:bg-gray-50.bg-white.rounded-md.cursor-pointer',
                    { onClick: () => { opened[place.id] = !opened[place.id]; redraw(); },
                      onContextMenu: e => {
                          panelPlace = place;
                          actionsPanel.open({ x: e.clientX, y: e.clientY });
                          e.preventDefault();
                      }
                    },
                    r('.leading-6.col-span-3.break-normal', { style: { paddingLeft: 8 + level * 24 + 'px' } },
                        r('.mr-2.inline', opened[place.id] ? '▲' : '▼'),
                        place.name
                    ),
                    r('.leading-4.pt-1.pb-1.col-span-2.break-normal', deviceName(place).get()),
                    //r('.leading-4.pt-1.pb-1.col-span-2.break-normal', organizationName(place).get()),
                    r('.leading-4.pt-1.pb-1.col-span-2.break-normal', linkToPlace(place).get()),
                    r('.leading-4.pt-1.pb-1.col-span-2.break-normal', departmentName(place).get())
                ),
                r('.flex.flex-col.mb-4' + (opened[place.id] ? '' : '.hidden'),
                    Tree(place.id, level + 1)
                )
            ]);
        }
        return r('.flex.flex-col.p-16',
            deviceTakenAlert.render(),
            deleteAlert.render(),
            r('.text-xl.font-bold.mt-6.mb-6', { style: { marginTop: '-32px' } }, 'Привязка устройств к объектам'),
            r('.flex.flex-col', { style: { width: '1400px' } },
                r('.grid.grid-cols-12.gap-4.w-full.pt-1.pb-1',
                    r('.text-md.font-bold.col-span-3', 'Название элемента'),
                    r('.text-md.font-bold.col-span-2', 'BLE метки'),
                    //r('.text-md.font-bold.col-span-2', 'Организация'),
                    r('.text-md.font-bold.col-span-2', 'Подразделение'),
                    r('.text-md.font-bold.col-span-2', 'Привязка')
                ),
                Tree(null)
            )
        );
    }
    
    return { onopen, render };
}
