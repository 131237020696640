import {r, redraw} from '../../common/vdom/index.js';
import {Button, Table, Dropdown, Icon} from '../index.js';
import {formatDate, valueRef} from '../../utils.js';
import XLSX from 'xlsx';
import login from "../../api/login";

function exportToXLSX({rows, mode}) {
    rows = rows();
    const wb = XLSX.utils.book_new()
    const headers = ['ФИО сотрудника','Профессия', 'ID устройства', 'Табельный номер','ФИО бригадира', 'Заряд батареи', 'Текущий ЧСС'].concat(
        mode == 1 ? ['Местоположение','Нарушения в работе', 'Последнее время связи'] : []
    );


    const ws_data = [
        headers,
        ...rows.map(
            i => [i.name,i.profession, i.deviceId, i.tnumber,i.brigadier, i.batteryCharge, i.heartRate].concat(
                mode == 1 ? [i.location,i.alarm?.join(', ') || 'Нет нарушений', i.lastUpdate] : []
            ).map(String)
        )
    ];
    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    ws["!cols"] = headers.map((_, i) => ({
        // непечатные символы занимают 0.75 размера
        wch: Math.max(...ws_data.map(j => (j[i]?.length || 0) + 2 - [...(j[i] || '')].filter(i => i === ' ' || i === ',').length * 0.75))
    }));
    XLSX.utils.book_append_sheet(wb, ws, '');
    XLSX.writeFile(wb, 'Cотрудники.xlsx')
}

function Filter({filters, rows, mode, count}) {
    function filter(row) {

        return (
            (filters.charge <= +(row.batteryCharge || 0)) &&
            (mode == 0 || (mode == 1 && row.deviceId))
        )
    }

    function render() {
        const _count = count();
        return r('.flex.flex-row.items-center.mt-4', {style: {marginTop: '-32px'}},
            Dropdown(
                r('.flex.flex-row.items-center.leading-8.cursor-pointer.pl-4.pr-4.bg-gray-100.rounded-full.relative.bg-gray-100.border-2.hover:bg-gray-200',
                    `Заряд не менее ${filters.charge}%`,
                    /*Icon('close', 'black', 2, {
                        class: '.absolute.right-0.top-0',
                        style: { transform: 'translateX(100%)' }
                    }),*/
                ),
                close => [0, 10, 25, 50, 75, 100].map(charge =>
                    r('.pl-4.pr-4.pt-1.pb-1.hover:bg-gray-100.cursor-pointer.w-60',
                        {onClick: () => (filters.charge = charge, close(), redraw())},
                        `Заряд не менее ${charge}%`
                    )
                )
            ),
            r('.ml-8'),
            r('.flex.flex-row.items-center.bg-gray-100.border-2.border-gray-300.rounded-full.relative',
                r('.mr-4.pl-4', 'Количество'),
                [10, 20, 50, 100, 200, 500].map(count =>
                    r('.pl-2.pr-2.ml-1.leading-8.rounded-full.cursor-pointer' +
                        (_count() == count ? '.bg-gray-300' : '.hover:bg-gray-300'),
                        {
                            onClick: () => {
                                _count(count);
                                redraw();
                            }
                        },
                        count
                    )
                )
            ),
            r('.ml-auto'),
            r('.flex.flex-row.text-md',
                'Результатов: ',
                r('.ml-1.text-green-600', rows().length)
            )
        )
    }


    return {filter, render};
}

export default function StaffTable(arg = {}) {
    let {
        editEmployee,
        openEmployee,
        deleteEmployee,
        mode = 0,
        data = () => [],
    } = arg;


    const filters = {charge: 0, workProblems: 1, count: 10, page: 0};
    const filter = Filter({filters, rows: () => table.v.rows, mode, count: _ => valueRef(table.v, 'count')});
    let cellText;

    const table = Table({
        rows: () => data(),

        pagination: true,
        columns:(mode==0)?
            ['name', 'deviceId', 'tnumber', 'batteryCharge', 'heartRate','edit_btn', 'delete_btn']
            :['name',"profession" , 'deviceId', 'tnumber', 'brigadier','batteryCharge', 'heartRate','skinTemperature', 'location', 'alarm', 'lastUpdate']
        ,cellText: cellText = (row, column) => ({
            batteryCharge: row.batteryCharge ? row.batteryCharge + '%' : '',
            lastUpdate: row.lastUpdate ? formatDate(row.lastUpdate) : '',
            edit_btn: row.id,
            delete_btn: row.id
        })[column] || row[column],

        cellRender: (row, column) => ({
            deviceId: v => r('.text-lg.font-medium', v),
            // profession: v => r('.text-lg.font-medium', v),
            tnumber: v => r('.text-lg.font-medium', v),
            // brigadier: v => r('.text-lg.font-medium', v),
            batteryCharge: v => r('.text-lg.font-medium', v),
            skinTemperature: v => r('.text-lg.font-medium', v),
            heartRate: v => r('.text-lg.font-medium', v),
            // skinTemperature: v => r('.text-lg.font-medium', v),
            // location: v => r('.text-lg.font-medium', v),
            alarm: v => v.map(v => r('.flex.row.mt-1.text-xs', r('.mr-2', '-'), v)),
            edit_btn: id => r('.flex.flex-row.items-center.font-bold.text-md.text-gray-600.cursor-pointer.uppercase',
                {onClick: () => editEmployee(id)},
                Icon('edit', 'gray-600', 6),
                r('', 'Редактировать')
            ),
            delete_btn: id => r('.flex.flex-row.items-center.font-bold.text-md.text-red-400.cursor-pointer.uppercase',
                {onClick: () => deleteEmployee(id)},
                Icon('delete', 'red-400', 6),
                r('', 'Удалить')
            )
        })[column] || (v => v),
        cellColor: (row, column) => ({
            alarm: !row.in_place || !row.alarm || row.alarm[0] == 'Нет нарушений' ? 'green-600' : 'red-400',
        })[column] || 'gray-800',
        cellSortValue: (row, column) => row[column],
        cellFilterValue: (row, column) => row[column],
        columnName: (column) => ({
            name: 'ФИО сотрудника',
            profession: "Профессия",
            deviceId: 'ID устройства',
            tnumber: 'Табельный номер',
            brigadier: 'ФИО Бригадира',
            batteryCharge: 'Заряд батареи',
            heartRate: 'ЧСС',
            skinTemperature: 'Температура',
            location: 'Местоположение',
            alarm: 'Нарушения в работе',
            lastUpdate: 'Последнее время связи',
        })[column],
        columnAlign: (column) => ({
            name: 'left',
            profession: 'center',
            deviceId: 'center',
            tnumber: 'center',
            brigadier: 'center',
            batteryCharge: 'center',
            heartRate: 'center',
            skinTemperature: 'center',
            location: 'center',
            alarm: 'left',
            lastUpdate: 'left'
        })[column],
        columnWidth: (column) => ({
            name: 40,
            profession: 32,
            deviceId: 25,
            tnumber: 32,
            brigadier: 40,
            batteryCharge: 20,
            heartRate: 20,
            alarm: 40,
            lastUpdate: 50,
            skinTemperature: 20,
            location: 30,
        })[column],
        columnSeparator: (column) => ({
            name: 0,
            profession: 1,
            deviceId: 1,
            tnumber: 1,
            brigadier: 1,
            batteryCharge: 1,
            heartRate: 0,
            skinTemperature: 0,
            location: 1,
            alarm: 1,
            lastUpdate: 1
        })[column],
        additionalFilter: filter.filter,
        columnFilterFunction: (column) => ({
            name: Table.columnFilterFunctions.text,
            profession: Table.columnFilterFunctions.text,
            deviceId: Table.columnFilterFunctions.text,
            tnumber: Table.columnFilterFunctions.text,
            brigadier: Table.columnFilterFunctions.text ,
            batteryCharge: Table.columnFilterFunctions.number,
            heartRate: Table.columnFilterFunctions.number,
            skinTemperature: Table.columnFilterFunctions.number,
            location: Table.columnFilterFunctions.text,
            alarm: Table.columnFilterFunctions.multiselect_or,
            lastUpdate: Table.columnFilterFunctions.date,
        })[column],
        columnFilterInput: (column) => ({
            name: Table.columnFilterInputs.text,
            profession: Table.columnFilterInputs.text,
            deviceId: Table.columnFilterInputs.text,
            tnumber: Table.columnFilterInputs.text,
            brigadier: Table.columnFilterInputs.text,
            batteryCharge: Table.columnFilterInputs.number,
            heartRate: Table.columnFilterInputs.number,
            skinTemperature: Table.columnFilterInputs.number,
            location: Table.columnFilterInputs.text,
            alarm: Table.columnFilterInputs.multiselect([
                'Нет нарушений',
                'Отсутствует на рабочем месте по данным СКУД',
                'Данные с устройства не поступают',
                'Низкий уровень заряда батареи',
                'Устройство снято с сотрудника',
                'Длительное состояние покоя',
                'Отсутствие данных с BLE меткой',
                'Сотрудник перестал двигаться'
            ]),
            lastUpdate: Table.columnFilterInputs.datetime(
                Math.min(data().map(row => row.lastUpdate ? new Date(row.lastUpdate) : Infinity)),
                Math.max(data().map(row => row.lastUpdate ? new Date(row.lastUpdate) : -Infinity))
            )
        })[column],
        // onColumnFilters: (columnFilters) => console.log({columnFilters}),
        //onSort: (sortColumn, sortDir) => console.log({sortColumn, sortDir}),

    });


    function render() {

        table.recalc();
        return r('.flex.flex-col',
            filter.render(),
            r('.mt-8'),
            r('.border-t'),
            table.render(),
            r('.flex.flex-row.mt-8',
                table.pagination.render(),
                r('.flex-1'),
                Button('Сохранить в XLSX', () => exportToXLSX({
                    mode,
                    rows: () => data().filter(row => (mode == 0 || (mode == 1 && row.deviceId))).map(row =>
                        Object.fromEntries(
                            ['name', 'profession', 'deviceId', 'tnumber', 'brigadier', 'batteryCharge', 'heartRate', 'location', 'alarm', 'lastUpdate']
                                .map(column => [column, cellText(row, column)])
                        )
                    ),
                }))
            )
        )
    }

    return {render}
}
