import { r, redraw } from '../../common/vdom/index.js';
import { Icon, Dropdown } from '../index.js';
import { withAttrs } from '../utils.js';

let filter = '';
const lock = new WeakSet();

function Input(label, arg={}) {
    const {
        type = 'text',
        value = () => '',
        list = null,
        required = false,
        readonly = false,
        border = true,
        search = false,
        attrs = {}
    } = arg;
    
    const isDate = type == 'datetime-local';
    return (
        list
        ? r('.flex.flex-col.w-full', attrs,
            label ? r('.text-sm.select-none.whitespace-pre-wrap', label + (required && value() == undefined ? ' *' : '')) : '',
            Dropdown(
                r('.flex.flex-row.relative.select-none.cursor-pointer.items-center',
                    { onClick: () => { filter = ''; redraw(); } },
                    r('.outline-none.w-full.leading-8.text-md.bg-transparent.pointer-events-none.flex' + 
                        (border ? '.border-b-2' : '.hover:shadow.hover:bg-gray-50') +
                        (readonly ? '.border-gray-400.pointer-events-none' : value() != undefined ? '.border-green-600' : '.border-black'),
                        list.find(v => v.value == value())?.label || '',
                        r('.h-8.ml-8'),
                    ),
                    Icon('angle-down', 'gray-800', 6, { class: '.absolute.right-0' })
                ),
                close => [
                    !search ? '' : r('input.outline-none.w-full.leading-8.text-md.bg-transparent.border-b-2.pl-4.pr-4', {
                        placeholder: 'Поиск',
                        value: filter,
                        onInput(e) { filter = e.target.value; redraw(); },
                        onClick(e) { e.stopPropagation(); }
                    }),
                    list
                      .filter(v => String(v.list_label || v.label || v.value).toLowerCase().indexOf(filter.toLowerCase()) != -1)
                      .map(v =>
                            v.value !== undefined ?
                            r('.pl-4.pr-4.pt-2.pb-2.cursor-pointer.select-none.hover:bg-gray-100.max-w-md', {
                                onClick: () => (value(v.value), close(), redraw())
                            }, v.list_label || v.label || v.value)
                            : r('.pl-4.pr-4.pt-2.pb-2.select-none.font-bold.max-w-md', v.list_label || v.label)
                      ),
                ]
            )
        )
        : r('.flex.flex-col.w-full',
            r('.text-sm.select-none.whitespace-pre-wrap', label + (required && value() == undefined ? ' *' : '')),
            r('.flex.flex-row.relative',
                withAttrs(attrs, r('input.outline-none.w-full.leading-8.text-md.bg-transparent' +
                    (border ? '.border-b-2' : '.hover:shadow.hover:bg-gray-50') +
                    (readonly ? '.border-gray-400.pointer-events-none' : value() != undefined ? '.border-green-600' : '.border-black'),
                    {
                      type,
                      readonly: readonly && "true",
                      //placeholder: !value() ? label + (required ? ' *' : '') : '',
                      //value: value(),
                      value_: String(value()),
                      ...(isDate ? { step: 60 } : {}),
                      ref(dom) {
                          if (!dom) return;
                          if (lock.has(dom)) { lock.delete(dom); return; }
                          if (isDate && value()) {
                              dom.valueAsNumber = Math.floor(+new Date(value()) / 60000) * 60000 - new Date().getTimezoneOffset() * 60000;
                          } else dom.value = value() == undefined ? '' : String(value());
                      },
                      onInput(e) {
                          lock.add(e.target);
                          //console.log(e.target.value, new Date(e.target.valueAsNumber));
                          value(isDate ? new Date(e.target.value) : e.target.value);
                          redraw();
                      }
                    }
                ))
            )
        )
    );
}

Input.DateRange = function(arg={}) {
    const {
        type = 'date',
        value = () => [new Date(), new Date()],
        attrs = {},
        max = undefined,
        min = undefined
    } = arg;  
    const dt = (i) => value()?.[i] || (new Date()).toISOString().split('T')[0];
    
    if (new Date(dt(0)) > new Date(dt(1))) {
        value([
            (new Date(dt(1))).toISOString().split('T')[0],
            (new Date(dt(0))).toISOString().split('T')[0]
        ]);
    }
    return r('.flex.flex-col.p-2', attrs,
        Input('Начало', {
            type,
            value: v => !v ? dt(0) : value([
                ( min && new Date(v) < min ) ? (new Date(min)).toISOString().split('T')[0] : v,
                dt(1)
            ])
        }),
        r('.mt-2'),
        Input('Конец', {
            type,
            value: v => !v ? dt(1) : value([
                dt(0),
                ( max && new Date(v) > max ) ? (new Date(max)).toISOString().split('T')[0] : v
            ])
        })
    )
}

Input.TimeRange = function(arg={}) {
    const {
        value = () => ['00:00', '00:00'],
        attrs = {},
    } = arg;  
    const dt = (i) => value()?.[i] || '00:00';
    
    return r('.flex.flex-col.p-2', attrs,
        Input('Начало', {
            type: 'time',
            value: v => !v ? dt(0) : value([ v, dt(1) ])
        }),
        r('.mt-2'),
        Input('Конец', {
            type: 'time',
            value: v => !v ? dt(1) : value([ dt(0), v ])
        })
    )
}

export default Input;
