import { r, redraw } from '../../common/vdom/index.js';

export default function Pagination({ page, count, rows }) {
    function getPage() {
        const length = Math.ceil(rows().length / count())
        let p = page();
        if (p*count() >= rows().length) {
            p = Math.max(0, length - 1);
            page(p);
        }
        return p;
    }
    function slice() {
        return [getPage() * count(), (getPage() + 1) * count()];
    }
    function render() {
        const length = Math.ceil(rows().length / count());
        let p = getPage();
        function btn(i) {
            const cls = (
                (p == i ? '.text-black.font-bold' : '.text-green-600') +
                '.text-md.hover:bg-green-500.hover:bg-opacity-25.ptr.leading-8.w-8.t-center.rounded-full'
            );
            return r(cls, { onClick() { page(i); redraw(); } }, i + 1);
        }
        
        return r('.flex.flex-row.items-center',
            (p - 3 >= 0) ? [btn(0), r('.ml-1.mr-1', '...')] :
            (p - 2 >= 0) ? btn(0) : '',
            (p - 1 >= 0) && btn(p - 1),
            btn(p),
            (p + 1 < length) && btn(p + 1),
            (p + 3 < length) ? [r('.ml-1.mr-1', '...'), btn(length - 1)] :
            (p + 2 < length) ? btn(length - 1) : ''
        );
    }
    return { slice, render };
} 
