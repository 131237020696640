import { r, redraw } from '../../common/vdom/index.js';
import { Icon, Input, Dropdown, Button, Table } from '../index.js';
import { value, valueRef } from '../../utils.js';
import { getLogsReport, getLogsReportXLSX } from '../../api/reports.js';
import { throttle, once } from '../../common/utils/func.js';
import Pagination from '../basic-components/pagination.js';

const formatDate = (date, locale='en-US') => new Date(date).toLocaleTimeString(locale, {
    year: 'numeric', month: 'long', day: 'numeric',
    hour: '2-digit', minute: '2-digit', second: '2-digit'
});

export default function LogTable() {
    const filters = { count: 10, page: 0, loading: true  };
    const columnFilters = {};
    let rows = [], error;
    
    const table = Table({
        sortColumn: value('dt'),
        sortDir: value(-1),
        columnFilters,
        rows: () => rows,
        pagination: valueRef(filters, 'count'),
        columns: ['dt', 'event', 'subject', 'username'],
        cellText: (row, column) => ({
            dt: formatDate(row.dt, 'ru-RU')
        })[column] || row[column],
        columnAlign: (column) => 'left',
        columnWidth: (column) => 80,
        columnName: (column) => ({
            dt: 'Дата и время',
            event: 'Операция',
            subject: 'Объект',
            username: 'Инициатор'
        })[column],
        columnFilterFunction: (column) => ({
            dt: Table.columnFilterFunctions.date,
            event: Table.columnFilterFunctions.text,
            subject: Table.columnFilterFunctions.text,
            username: Table.columnFilterFunctions.text
        })[column],
        columnFilterInput: (column) => ({
            dt: Table.columnFilterInputs.datetime(),
            event: Table.columnFilterInputs.text,
            subject: Table.columnFilterInputs.text,
            username: Table.columnFilterInputs.text
        })[column],
    });
    
    async function getRows() {
        error = undefined;
        redraw();
        try {
            rows = (await getLogsReport({
                begin_date: filters.begin_date || new Date(0),
                end_date: filters.end_date || new Date()
            })).map(i => {
                i.dt = new Date(i.dt);
                if (i.event === 'Устройство привязано') {
                    i.event = `Устройство ${i.body.device_name} привязано к сотруднику ${i.body.personnel_id}`
                } else if (i.event === 'Устройство отвязано') {
                    i.event = `Устройство ${i.body.device_name} отвязано от сотрудника ${i.body.personnel_id}`
                }
                return i;
            });
            /*
            columnFilters.dt = [
                Math.min(...rows.map(row => +row.dt).filter(v => v)),
                Math.max(...rows.map(row => +row.dt).filter(v => v)) + 24 * 60 * 60 * 1000
            ].map(d => new Date(d));
            */
        } catch (e) {
            error = e;
        } finally {
            filters.loading = false;
            redraw();
        }
    }
    const init = once(getRows);
    
    function render() {
        init();
        console.log({columnFilters,rows});
        function Filter() {
            table.render();
            
            return r('.flex.flex-row.items-center.mt-4',
                r('.ml-auto'),
                r('.flex.flex-row.items-center.bg-gray-100.border-2.border-gray-300.rounded-full.relative',
                    r('.mr-4.pl-4', 'Количество'),
                    [10, 20, 50].map(count =>
                        r('.pl-2.pr-2.ml-1.leading-8.rounded-full.cursor-pointer' +
                          (filters.count == count ? '.bg-gray-300' : '.hover:bg-gray-300'),
                            { onClick: () => { filters.count = count; redraw(); } },
                            count
                        )
                    )
                ),
                r('.ml-8'),
                r('.flex.flex-row.text-md',
                    'Результатов: ',
                    r('.ml-1.text-green-600', table.v.rows.length)
                )
            )
        }
        
        return r('.flex.flex-col',
            Filter(),
            r('.mt-4'),
            error ? [
                r('.text-black.font-bold', error + ''),
                r('.mt-2'),
                r('', { style: {width: '300px'}}, Button('Повторить', getRows))
            ] : [
                table.render(),
                r('.mt-8'),
                r('.flex.flex-row.items-center',
                    table.pagination.render(),
                    r('.flex-1'),
                    Button('Скачать XLSX', _ => {
                        getLogsReportXLSX({
                            begin_date: columnFilters?.dt?.[0] || new Date(0),
                            end_date: columnFilters?.dt?.[1] || new Date()
                        }).catch(e => {
                            error = e;
                            redraw();
                        })
                    }, { disabled: filters.loading })
                )
            ]
        )
    }
    return { render }
}
