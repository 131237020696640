import {r, redraw} from '../../common/vdom/index';
import {Alert, Button, Icon, Input, Table} from '../index.js';
import {getStatusBleLabelsReport, getStatusBleLabelsReportXLSX} from '../../api/reports.js';
import {employeesData} from '../../pages/employees/index';
import {formatBleID, formatDate} from '../../utils.js';
import {throttle} from "../../common/utils/func";
import {getToken} from "../../api/login";
import {device} from "../../api";
import {convertStatusBleLabel} from "./helper";

export default function ReportTable() {
    let data = [];
    const filters = {count: 10};
    let devices = [];
    const redraw1 = throttle(50, redraw);

    async function load() {
        if (!getToken()) return;
        devices = await device.get();
        redraw1();
    }

    load();


    const alert = Alert(
        (res, t) => r('.flex.flex-col', {style: {width: '300px'}},
            t === 'filters'
                ? [
                    r('', 'Необходимо Выбрать:'),
                    !filters.begin_date && r('', ' - Дата формирования "с"'),
                    !filters.end_date && r('', ' - Дата формирования "по"'),
                    !filters.devices && r('', ' - Устройство'),
                ]
                : r('', String(t))
        )
    );

    const columnFilters = {};
    const table = Table({
        columnFilters,
        pagination: _ => filters.count,
        rows: () => data,
        columns: ["ble_name", "place_name", "signals_count", "is_active", "last_datetime", "device_name", "personnel_id", "employee_fio"],
        columnWidth: (column) => ({
            ble_name: 32,
            place_name: 32,
            signals_count: 32,
            is_active: 32,
            last_datetime: 40,
            device_name: 32,
            personnel_id: 32,
            employee_fio: 64,
        }[column]),
        columnAlign: () => 'center',
        columnSeparator: (column) => ["place_name", "signals_count", "is_active", "last_datetime", "device_name", "personnel_id", "employee_fio"].includes(column),
        columnName: (column) => ({
            ble_name: "ID BLE метки",
            place_name: "Зона привязки",
            signals_count: "Количество выходов в эфир",
            is_active: "Статус BLE метки",
            last_datetime: "Время последнего получения данных",
            device_name: "Id носимого устройства",
            personnel_id: "Табельный номер",
            employee_fio: "ФИО",
        })[column],
        columnFilterInput: (column) => ({
            ble_name: Table.columnFilterInputs.text,
            place_name: Table.columnFilterInputs.text,
            signals_count: Table.columnFilterInputs.text,
            is_active: Table.columnFilterInputs.text,
            last_datetime: Table.columnFilterInputs.datetime(
                Math.min(data.map(row => row.lastUpdate ? new Date(row.lastUpdate) : Infinity)),
                Math.max(data.map(row => row.lastUpdate ? new Date(row.lastUpdate) : -Infinity))
            ),
            device_name: Table.columnFilterInputs.text,
            personnel_id: Table.columnFilterInputs.text,
            employee_fio: Table.columnFilterInputs.text,
        })[column],
        columnFilterFunction: (column) => ({
            ble_name: Table.columnFilterFunctions.text,
            place_name: Table.columnFilterFunctions.text,
            signals_count: Table.columnFilterFunctions.text,
            is_active: Table.columnFilterFunctions.text,
            last_datetime: Table.columnFilterFunctions.date,
            device_name: Table.columnFilterFunctions.text,
            personnel_id: Table.columnFilterFunctions.text,
            employee_fio: Table.columnFilterFunctions.text,
        })[column],
        cellText: (row, column) => ({
            ble_name: row.ble_name || "",
            place_name: row.place_name || "",
            signals_count: row.signals_count || "",
            is_active: row.is_active,
            last_datetime: row.last_datetime ? formatDate(row.last_datetime + 'Z') : '',
            device_name: row.device_name?.slice(-8) || "",
            personnel_id: row.personnel_id || "",
            employee_fio: row.employee_fio || "",
        })[column]
    })

    function Filter() {
        return r('.flex.flex-row.items-center.mt-4',
            r('.flex.flex-row.items-center.bg-gray-100.border-2.border-gray-300.rounded-full.relative',
                r('.mr-4.pl-4', 'ID_Ble'),
                Input(undefined, {
                    value: v => v !== undefined ? (filters.devices = v) : filters.devices,
                    search: true,
                    border: false,
                    list: [{value: null, label: 'Не выбран'}].concat(
                        devices.filter(elem => (elem.device_type === "ble"))
                            .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
                            .map(v => ({value: v.id, label: formatBleID(v.name)}))
                    )
                }),
                r('.flex.items-center.justify-center.pl-2.pr-2.ml-1.mr-1.leading-8.rounded-full.cursor-pointer.hover:bg-gray-300',
                    {
                        style: {width: '24px', height: '24px'}, onClick() {
                            filters.devices = undefined;
                            redraw();
                        }
                    },
                    Icon('close', 'black', 2),
                )
            ),
            r('.ml-8'),
            r('.flex.flex-row.items-center.bg-gray-100.border-2.border-gray-300.rounded-full.relative',
                r('.mr-4.pl-4', 'Количество'),
                [10, 20, 50].map(count =>
                    r('.pl-2.pr-2.ml-1.leading-8.rounded-full.cursor-pointer' +
                        (filters.count == count ? '.bg-gray-300' : '.hover:bg-gray-300'),
                        {
                            onClick: () => {
                                filters.count = count;
                                redraw();
                            }
                        },
                        count
                    )
                )
            ),
            r('.ml-auto'),
            r('.flex.flex-row.text-md',
                'Результатов: ',
                r('.ml-1.text-green-600', table.v.rows.length)
            )
        )
    }

    function render() {
        employeesData.init();
        table.recalc();


        return r('.flex.flex-col',
            Filter(),
            r('.border-t.mt-8'),
            r('.overflow-hidden.overflow-x-auto',
                filters.loading ? r('.mt-4.flex.justify-center.text-center', 'Загрузка данных\nожидайте...')
                    : table.render(),
            ),
            r('.mt-8'),
            alert.render(),
            r('.flex.flex-row',
                table.pagination.render(),
                r('.flex-1'),
                Button('Сформировать отчет', _ => {
                    filters.page = 0;
                    filters.loading = true;
                    redraw();
                    getStatusBleLabelsReport({
                        ble_id: filters.devices
                    }).catch(alert.show)
                        .then(res => {
                            data = convertStatusBleLabel(res) || [];
                        }).finally(_ => {
                        filters.loading = false;
                        for (const k in columnFilters) delete columnFilters[k];
                        redraw();
                    })

                }, {disabled: filters.loading}),
                r('.ml-2'),
                Button('Скачать XLSX', _ => {
                    getStatusBleLabelsReportXLSX({
                        ble_id: filters.devices
                    }).catch(alert.show)
                }, {disabled: filters.loading})
            )
        )
    }

    return {render}
}
