import { ActionHandler, mergeAttrs } from '../utils.js';

export function Panel(arg) {
    const state = {
        anchor: null,
        opened: false,
        align: 'right',
        offset: { x: 0, y: 0 },
        content: () => '',
        onopen: () => {},
        onclose: () => {},
        focusChild_onopen: false,
        focusAnchor_onclose: true,
        displayed: false,
        vnode: null,
        ...arg
    };
    
    const self = {
        state,
        setAnchor,
        setContent,
        attach, detach,
        render, update,
        open, close,
        anchorElement
    };
    
    attach();
    function attach() { panelsContainer.addPanel(self); }
    function detach() { panelsContainer.removePanel(self); }
    function raise() { panelsContainer.raisePanel(self); restyle(); }
    
    function open() { state.opened = true; panelsContainer.redraw(); forceReflow(); }
    function close() { state.opened = false; panelsContainer.redraw(); }
    
    function forceReflow() {
        // bugfix
        const { dom } = state.vnode;
        if (dom) {
            dom.style.width = 'auto';
            setTimeout(() => dom.style.width = 'fit-content', 0);
        }
    }
    
    function setContent(content) {
        state.content = content;
        panelsContainer.redraw();
    }
    
    function setAnchor(anchor) {
        state.anchor = anchor;
    }
    
    function anchorElement(elem) {
        elem.attrs = mergeAttrs(elem.attrs, {
            ...ActionHandler(onclick),
            onclick(e) {
                raise();
                setAnchor(this);
                (state.opened ? close : open)();
                panelsContainer.redraw();
            }
        });
        return elem;
    }
    
    function focusChild() {
        const { dom } = state.vnode;
        const elem = (
            dom.querySelector(`[data-focusable]`) ||
            dom.querySelector(`[tabIndex="0"]`) ||
            dom.children[0]
        );
        if (elem) elem.focus();
    }

    function focusAnchor() {
        if (state.anchor && state.anchor instanceof HTMLElement) {
            state.anchor.focus();
        }
    }
    
    function restyle() {
        let { vnode, anchor } = state;
        
        if (!vnode || !vnode.dom) return;
        let dom = vnode.dom;
        
        if (!state.displayed) {
            dom.style.display = "none";
            return;
        }
        
        let r = dom.getBoundingClientRect();
        if (anchor instanceof HTMLElement) {
            anchor = anchor.getBoundingClientRect();
        }
        let inner = Math.max(dom.scrollHeight, r.height),
            global_inner = window.innerHeight;
        let x, y, h;
        
        if (state.align == 'left') x = anchor.x - (r.width - (anchor.width || 0));
        if (state.align == 'center') x = anchor.x - (r.width / 2 - (anchor.width || 0) / 2);
        if (state.align == 'right') x = anchor.x;
        
        //x -= 4;
        
        if (x + r.width > window.innerWidth) x = window.innerWidth - r.width - 16;

        if ((inner > (global_inner - anchor.y - 64)) && (anchor.y > global_inner / 2)) {
            h = Math.min(inner, anchor.y);
            y = anchor.y - h;
        } else {
            y = anchor.y + (anchor.height || 0);
            h = Math.min(global_inner - anchor.y - 30, inner);
        }
        
        const offset = { ...state.offset };
        if (typeof offset.x == 'number') { x += offset.x; delete offset.x; }
        if (typeof offset.y == 'number') { y += offset.y; delete offset.y; }
        
        x = Math.min(x, window.outerWidth - Math.max(dom.scrollWidth, r.width) - 30);
        
        x = Math.floor(x);
        y = Math.floor(y);
        
        const tx = 'x' in offset ? `calc(${x}px + ${offset.x})` : `${x}px`;
        const ty = 'y' in offset ? `calc(${y}px + ${offset.y})` : `${y}px`;
        
        dom.style.overflow = inner > r.height ? "auto" : '';
        dom.style.transform = `translate(${tx}, ${ty})`;
        dom.style.zIndex = state.zIndex;
        dom.style.maxHeight = h + "px";
        dom.style.display = "block";
        dom.style.minHeight = 'fit-content';
        dom.style.width = 'fit-content';
        dom.style.maxWidth = 'fit-content';
        dom.style.left = '0px';
        dom.style.top = '0px';
    }
    
    function update() {
        if (!state.vnode || !state.vnode.dom) return;
        if (state.opened && state.anchor) {
            if (!state.displayed) {
                state.onopen();
                if (state.focusChild_onopen) setTimeout(() => focusChild(state), 100);
                raise();
            };
            state.displayed = true;
        } else {
            if (state.displayed) {
                if (state.focusAnchor_onclose) focusAnchor(state);
                state.onclose();
            }
            state.displayed = false;
        }
        restyle();
    }
    
    function render(key) {
        function handler(vnode) {
            state.vnode = vnode;
            const { dom } = state.vnode;
            if (dom && !dom.style.display) dom.style.display = 'none';
        }
        
        let content = state.content;
        while (typeof content == 'function') content = content();
        
        return m('.col.b0.rad2.sh1.abs.widthc.c0.w1', {
            key: key,
            ...state.attrs,
            oncreate: handler,
            onupdate: handler
        }, content);
    }
    
    return self;
}

export const panelsContainer = PanelsContainer();

export function PanelsContainer() {
    let zIndex = 10000;
    const panels = new Set();
    
    function addPanel(panel) { panels.add(panel); }
    function removePanel(panel) { panels.delete(panel); }
    function raisePanel(panel) {
        panel.state.zIndex = zIndex++;
    }
    
    function update() { panels.forEach(panel => panel.update()); }

    const dom = document.createElement('div');
    dom.id = 'panels';
    document.body.appendChild(dom);
    
    function redraw() {
        m.render(dom, [...panels].map((panel, i) => panel.render(i)));
        update();
    };
    
    function processBodyClick(e) {
        const path = event.path || (event.composedPath && event.composedPath());
        const map = new WeakMap();
        
        function init(panel) {
            const { vnode, anchor } = panel.state;
            map.set(panel, {
                clickedOnPanel: (vnode && vnode.dom) ? path.indexOf(vnode.dom) != -1 : true,
                clickedOnAnchor: anchor && !!path.find(v => v == anchor),
                clickedOnSubpanel: false
            })
        };
        
        function processSubpanelClick(panel) {
            const parentPanel = [...panels].find(p =>
                panel.state.anchor instanceof HTMLElement && 
                p.state.vnode.dom.contains(panel.state.anchor)
            );
            if (parentPanel) {
                processSubpanelClick(parentPanel);
                map.get(parentPanel).clickedOnSubpanel = true;
            }
        }
        
        function process(panel) {
            if (!panel.state.opened) return;
            if (map.get(panel).clickedOnPanel) processSubpanelClick(panel);
        };
        
        function finish(panel) {
            const { close, state: { displayed } } = panel;
            const { clickedOnPanel, clickedOnAnchor, clickedOnSubpanel } = map.get(panel);
            if (displayed && !clickedOnPanel && !clickedOnAnchor && !clickedOnSubpanel) close();
        };
        
        panels.forEach(init);
        panels.forEach(process);
        panels.forEach(finish);
    }

    document.body.addEventListener('mousedown', (e) => {
        processBodyClick();
        update();
    });
    
    return { update, redraw, addPanel, removePanel, raisePanel };
}

export default { Panel, panelsContainer, PanelsContainer };

