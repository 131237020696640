import { r, redraw } from '../../common/vdom/index.js';

export default function(content) {
    let visible = false;
    
    let showargs = [];
    let then = () => {};
    let error = () => {};
    function show(...args) {
        showargs = [hide, ...args];
        visible = true;
        redraw();
        return new Promise((res, rej) => (then = res, error=rej) );
    }
    function hide(v=null) {
        if (v instanceof Error) {
            error(v);
        } else {
            then(v);
        }
        visible = false;
        redraw();
    }
    
    function render() {
        const cls = (
            '.fixed.z-10.flex.flex-row.items-center.justify-center' +
            '.right-0.bottom-0.top-0.left-0.bg-black.bg-opacity-50' + (visible ? '' : '.hidden')
        );
        return r(cls, { onClick: () => hide() },
            r('.p-8.bg-white.font-medium.text-xl.mb-48',
                { onClick: e => e.stopPropagation() },
                typeof content == 'function' ? content(...showargs) : content
            )
        );
    }
    return { show, hide, render }
}
